import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getSortedEquipments } from "../../../../components/reservation/Shop/New/service.js"
import { ShopStep } from "../../../../components/reservation/Shop/New/ShopStep/ShopStep.jsx"
import useAddSearchParam from "../../../../hooks/useAddSearchParams.js"
import resetScroll from "../../../../services/resetScroll.js"
import { removeProductFromCart } from "../../../../store/cart/actions.js"
import { setFormEntry } from "../../../../store/forms/actions.js"
import { nextStep, skipStep } from "../../../../store/stepsManagement/actions.js"
import { addCupProduct, removeCupProduct } from "../../urbanCup/api.js"

const tunnelType = "CUP"

export const CupProducts = (props) => {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ advertising, setAdvertising ] = useState(null)
    const [ catalogDiscount, setCatalogDiscount ] = useState(null)

    const { forms, availableEquipment } = useSelector(state => ({
        availableEquipment: state.forms[ShopStep.formDataName]?.availableEquipment,
        checkSkipStepEDF: state.checkSkipStepEDF,
        forms: state.forms,
        stepManagement: state.stepManagement,
    }))

    const dispatch = useDispatch()
    const addSearchParams = useAddSearchParam()

    const cupSubscription = forms.TournamentRegistration
    const hasCatalogProducts = cupSubscription?.cup?.catalogProducts?.length > 0
    const pickedProducts = cupSubscription?.catalogArticles ?? []

    useEffect(() => {
        resetScroll()

        if (!hasCatalogProducts) {
            dispatch(skipStep())
        }

        setAdvertising(cupSubscription.cup.advertising)
        setCatalogDiscount(cupSubscription.catalogDiscounts)
        setInfoStage(cupSubscription.cup.catalogProducts)
    }, [])

    const setInfoStage = (formatedAvailableEquipment = []) => {
        const products = getSortedEquipments(formatedAvailableEquipment)
        dispatch(setFormEntry(ShopStep.formDataName, {
            ...forms[ShopStep.formDataName],
            availableEquipment: products,
        }))
    }

    const addProduct = (product) => {
        let initialValue = 0
        const { size, count, product : { id } } = product
        const articleInCatalog = pickedProducts.find(
            x => x.sizeValue === parseInt(size) &&
                x.product.id === id,
        )

        if (articleInCatalog) {
            initialValue = articleInCatalog.quantity
        }
        setIsLoading(true)

        const handleCupProductAdd = () => {
            addCupProduct(
                cupSubscription.id,
                id,
                size,
                parseInt(count) + initialValue,
            ).then(
                (res) => {
                    setIsLoading(false)
                    dispatch(setFormEntry("TournamentRegistration", {
                        ...forms["TournamentRegistration"],
                        ...res.data.data,
                    }))
                    dispatch(setFormEntry("avoirFormData", {
                        ...forms.avoirFormData,
                        recalcAvoir: true,
                    }))
                },
            )
        }

        if (initialValue !== 0) {
            removeCupProduct(
                cupSubscription.id,
                id,
                size,
            ).then(handleCupProductAdd)
        } else {
            handleCupProductAdd()
        }
    }

    const removeProduct = (product) => {
        dispatch(removeProductFromCart(product.product.id, product.sizeValue + ""))

        setIsLoading(true)
        removeCupProduct(cupSubscription.id, product.product.id, product.sizeValue).then(
            (res) => {
                setIsLoading(false)
                dispatch(setFormEntry("TournamentRegistration",{
                    ...res.data.data,
                }))
                dispatch(setFormEntry("avoirFormData", {
                    ...forms.avoirFormData,
                    recalcAvoir: true,
                }))
            },
        ).catch(() => setIsLoading(false))
    }
    const subTotal = pickedProducts.reduce((acc, product) => acc + product.product.price * product.quantity, 0)

    return (
        <ShopStep
            {...props}
            tunnelType={tunnelType}
            addProduct={addProduct}
            advertising={advertising}
            availableProducts={availableEquipment}
            isLoading={isLoading}
            products={pickedProducts}
            discount={catalogDiscount}
            deleteProduct={removeProduct}
            checkBeforeNextStep={() => {
                addSearchParams(
                    "equipmentId",
                    forms?.TournamentRegistration?.catalogArticles?.length ?
                        forms?.TournamentRegistration?.catalogArticles[0]?.id : "none",
                )
                dispatch(nextStep())
            }}
            pricingInfos={{
                discountOnSubTotal: cupSubscription.catalogDiscountAmount,
                subtotal: subTotal,
            }}
        />
    )
}
