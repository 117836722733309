/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */

import { LeaguePath } from "../../routes/ApplicationPaths/LeaguePaths.js"
import disconnectUser from "../disconnectUser"

function initializeMenuBusiness({ nextBooking = false, userLoaded = {} }) {
    const blTypeId = parseInt(import.meta.env.VITE_US_BUSINESS_LEAGUE_TYPEID)
    const userHasPalmares = userLoaded?.championshipPlayers?.find(
        x => x.league?.status === "Terminé" 
        && x.league?.typeId === blTypeId,
    ) || false
    const userHasOnGoingLeague = userLoaded?.championshipPlayers?.find(x => x.league?.status === "En cours"
        && x?.isActive === true && x.league?.typeId ===  blTypeId) || false

    const menuslevel1 = {
        home: {
            slug: "accueil",
            label: "Accueil",
            img: "/assets/images/business/logo-business.png",
            desktopSection: "logo-part",
            appRoute: "/",
        },
        league: {
            slug: "ma-league",
            desktopSection: "main-part",
            mobileSection: "menu",
            appRoute: LeaguePath.leagueInfosBase,
            label: "Ma league",
            hasNotif: nextBooking,
        },
        listingLeague: {
            slug: "inscriptions",
            appRoute: LeaguePath.listingLeague,
            label: "Inscriptions",
            desktopSection: "main-part",
            mobileSection: "menu",
        },
        palmares: {
            slug: "palmares",
            appRoute: LeaguePath.leaguePalmares,
            label: "Palmarès",
            desktopSection: "main-part",
            mobileSection: "menu",
            hidden: !userHasPalmares,
        },
        leagueRanking: {
            slug: "autre-leagues",
            appRoute: LeaguePath.leagueRanking,
            label: "Autres leagues",
            desktopSection: "main-part",
            mobileSection: "menu",
        },
        store: {
            slug: "store",
            label: "Store",
            desktopSection: "main-part",
            mobileSection: "menu",
            externalLink: "https://www.urban-store.fr/urbanpacks/packs-business-league.html",
        },
        profil: {
            slug: "profil",
            label: "Mon profil",
            desktopSection: "other-part",
            mobileSection: "main",
            icon: "userProfile.svg",
        },
        reserver: {
            slug: "reserver",
            label: "Pack de com",
            desktopSection: "other-part",
            mobileSection: "main",
            appRoute: LeaguePath.communicationPackage,
            icon: "/assets/icons/business/pack-com.svg",
            customCls: "reserver-menu-item",
            hidden: !userHasOnGoingLeague,
        },
        informationsLegales: {
            slug: "informationsLegales",
            label: "Informations légales",
            mobileSection: "menu",
            customCls: "specific-mobile-menu-item",
        },
        contact: {
            slug: "contact",
            label: "Contact",
            mobileSection: "menu",
            externalLink: "https://www.businessleague.fr/inscrire-equipe/",
            customCls: "specific-mobile-menu-item",
        },
        cookies: {
            slug: "cookies",
            label: "Cookies",
            mobileSection: "menu",
            externalLink: "javascript:Didomi.preferences.show()",
            customCls: "specific-mobile-menu-item",
        },
    }
    const menuslevel2 = {
        profil: [
            {
                label: "Profil",
                appRoute: "/profil/coordonnees",
                icon: "/assets/icons/icons-graphics-coordonn-es.svg",
                isVisible: true,
            },
            {
                label: "Déconnexion",
                action: () => disconnectUser("/login"),
                parentClass: "disconnectButton",
                icon: "/assets/icons/icons-graphics-deconnexion.svg",
                isVisible: true,
                customCls: "logoutCheckblock",
            },
        ],
        informationsLegales: [
            {
                label: "Mentions légales",
                externalLink: "https://www.businessleague.fr/mentions-legales-business-league/",
            },
            {
                label: "Politique de données personnelles",
                externalLink: "https://www.businessleague.fr/politique-de-confidentialite/",
            },
        ],
    }

    return {
        menuslevel1,
        menuslevel2,
    }
}

export default initializeMenuBusiness
