import dayjs from "dayjs"
import { useEffect, useState } from "react"
import Countdown, { zeroPad } from "react-countdown"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import ConfirmPopin from "../../../../components/ConfirmPopin/ConfirmPopin.jsx"
import playersNumber from "../../../../components/reservation/LocationBlock/playersNumber.js"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext.jsx"
import useGoToCreateSuperSub from "../../../../hooks/supersub/useGoToCreateSuperSub.js"
import useMediaPath from "../../../../hooks/useMediaPath.js"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import { LocationPaths } from "../../../../routes/ApplicationPaths/locationPaths.js"
import usePath from "../../../../routes/services/usePath.js"
import { useLocationStore } from "../../store/store.js"
import BookingInvitation from "../components/BookingInvitation/BookingInvitation.jsx"
import { ArbitrageButton } from "../LocationArbitrage/components/ArbitrageButton.jsx"

export default function getAppropriateButton(location) {
    const mediaPath = useMediaPath()
    const theme = useTheme()
    const path = usePath()
    const navigate = useNavigate()
    const { actionOpenPaymentModal } = useLocationStore()

    const routerLocation = useLocation()
    const { scoreTeam1, supersubPlayerPrice, scoreTeam2, isOwner, isMVPVoteOpened, isSupersubRequestAvailable, supersubRemainingPlayers, supersubAskedPlayers, invites, resourceType, acceptPrePayment, amountDue, organizer, start, end, supersubConfirmedPlayers, id } = location
    const matchDidNotStartYet = new Date(start) >= new Date()
    const matchEnded = new Date() >= new Date(end)
    const currentTab = routerLocation.pathname.split("/").pop()

    const goToSupersub = useGoToCreateSuperSub(location, amountDue, supersubPlayerPrice)
    const [ isBefore1Hour, setIsBefore1Hour ] =  useState(false)
    const user = useSelector(state => state.userLoaded)

    const haveToPayHisPart = (amountDue > 0 && acceptPrePayment) && location.prepayments?.reduce(
        (carry, payment) => payment.contact.id === user.contactId ? payment.amount + carry : carry,
        0,
    ) < location.pricePerPart

    useEffect(() => {
        if (start) {
            const interval = setInterval(() => {
                if (new Date(start) < new Date()) {
                    setIsBefore1Hour(true)
                }
            }, 2000)
            return () => clearInterval(interval)
        }
    }, [ start ])

    const isCurrentStepNot = step => {
        return currentTab !== step
    }

    const GoToMVPVoteButton = (
        <ButtonCta
            icon={mediaPath([ "", "/assets/images/padel/icons/mvp.svg" ])}
            text="Élire le joueur du match"
            onClick={() => navigate(path(LocationPaths.toReservationPlayers(id)))}
        />
    )

    const { show } = ModalHandler.useReactiveModal(BookingInvitation, {
        location: location,
    })

    const checkSupersubPossibility = type => [ 0, 1, 13, 2, 3, 4, 8 ].includes(type)
    const maxSupersubCount = 3

    const posistionnedPlayersNumber = (location?.composition?.filter(player => !!player.teamId)?.length ?? 0)

    const isCompoDone = posistionnedPlayersNumber >= playersNumber.find(type => type.types.includes(location.resourceType))?.players
    
    if (
        theme === "theme-soccer"
        && new Date(end) > new Date()
        && (isBefore1Hour || (isOwner ? isCompoDone : !haveToPayHisPart))
    ) {
        return (
            <Countdown
                date={new Date(location.start)}
                renderer={({ hours, minutes, seconds, completed }) => {
                    const totalDays = dayjs(location.start).diff(dayjs(new Date).startOf("day"), "days")
                    const totalHours = dayjs(location.start).diff(new Date, "hours")
                    const totalMinutes = dayjs(location.start).diff(new Date, "minutes")

                    return (
                        <div className={"arbitrage-access"}>
                            {!completed && (
                                <div className={"arbitrage-access-countdown"}>
                                    Disponible dans{" "}

                                    {totalHours >= 24 && `${totalDays} jour${totalDays > 1 ? "s" : ""}`}

                                    {totalHours < 24 && (
                                        <>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</>
                                    )}
                                </div>
                            )}

                            <ArbitrageButton
                                disabled={!completed && totalMinutes >= 15}
                                onClick={() => {
                                    if (isCompoDone && location?.composition.length === posistionnedPlayersNumber) {
                                        navigate(path(LocationPaths.toArbitrage(location.id)))
                                    } else {
                                        ModalHandler.show(ConfirmPopin, {
                                            actions: {
                                                confirm: () => {
                                                    navigate(path(LocationPaths.toArbitrage(location.id)))

                                                    ModalHandler.hide(ConfirmPopin)
                                                },
                                            },
                                            description: "Tous les joueurs ne sont pas dans la compo. Souhaites-tu continuer ?",
                                            title: "Ta compo est incomplète",
                                        })
                                    }
                                }}
                            />
                        </div>
                    )
                }}
            />
        )
    }

    if (isOwner) {
        if (matchDidNotStartYet) {
            if ((!isSupersubRequestAvailable && isCurrentStepNot(path(LocationPaths.reservationPlayers))) || (invites.length <= 1 && isCurrentStepNot(path(LocationPaths.reservationPlayers)))) {
                return (<ButtonCta
                    icon={mediaPath([ "/assets/images/picto-add-user--white.svg", "/assets/images/padel/icons/icons-player-plus.svg" ])}
                    text="Inviter mes joueurs"
                    onClick={show}
                    hoverChangeIcon={mediaPath([ "/assets/images/picto-add-user--white.svg", "/assets/images/padel/icons/icons-player-plus-green.svg" ])}
                />)
            } else if (
                isSupersubRequestAvailable &&
                supersubRemainingPlayers === 0 &&
                supersubAskedPlayers < maxSupersubCount &&
                checkSupersubPossibility(resourceType) &&
                theme !== "theme-padel"
            ) {
                return (<ButtonCta
                    icon={mediaPath([ "/assets/images/supersub--white.svg", "/assets/images/padel/icons/icon-ultimo.svg" ])}
                    text="Trouver un supersub"
                    onClick={goToSupersub}
                />)
            } else if (supersubAskedPlayers > 0 && theme !== "theme-padel") {
                return (<ButtonCta
                    icon="/assets/images/supersub--white.svg"
                    text="Choisir un supersub"
                    onClick={() => navigate(path(LocationPaths.toCandidatureSuperSub(id)))}
                />)
            }
        } else if (matchEnded) {
            if ((!scoreTeam1 && scoreTeam1 !== 0 || !scoreTeam2 && scoreTeam2 !== 0) && isCurrentStepNot(path(LocationPaths.reservationInfos))) {
                return (<ButtonCta
                    text="Renseigner le&nbsp;score"
                    onClick={() => navigate(path(LocationPaths.toReservationInfos(id)))}
                />)
            } else if (
                theme !== "theme-padel" && supersubConfirmedPlayers && isOwner &&
                invites.some(invite => invite.status === 1 && invite.supersubResponse === null && invite.playerId !== organizer.id)
            ) {
                return (<ButtonCta
                    text="Noter le supersub"
                    onClick={() => navigate(path(`/reservations/locations/${id}/supersubRating`))}
                />)
            } else if (isMVPVoteOpened && isCurrentStepNot(path(LocationPaths.reservationPlayers))) {
                return GoToMVPVoteButton
            }
        }
    } else {
        if (matchDidNotStartYet && amountDue > 0 && isCurrentStepNot(path(LocationPaths.reservationPayments)) && acceptPrePayment) {
            return (<ButtonCta
                text="Payer ma part"
                onClick={actionOpenPaymentModal}
            />)
        } else if (isMVPVoteOpened && isCurrentStepNot(path(LocationPaths.reservationPlayers))) {
            return GoToMVPVoteButton
        }
    }
    return null
}
