import { Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import ModalHandler from "../../../../../providers/Modal/ModalHandler.jsx"
import Modal from "../../../../Modal/Modal.jsx"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

export const ProductPreviewModal = ModalHandler.create(({ name, images, visible, hide }) => {
    return (
        <Modal isOpen={visible} onClose={hide} className="product-preview-modal">
            <Swiper
                navigation={true}
                modules={[ Navigation, Pagination ]}
                pagination={{ clickable: true }}
                slidesPerView={1}
                key={"preview-" + name}
                wrapperTag="ul"
            >
                {images.map(
                    (el, idx) => (
                        <SwiperSlide key={"sky-" + idx} tag="li">
                            <img src={el} alt={name + idx}/>
                        </SwiperSlide>
                    ),
                )}
            </Swiper>
        </Modal>
    )
})
