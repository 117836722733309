import { bool, func, object, oneOfType, string } from "prop-types"
import { useState } from "react"
import { IMaskInput } from "react-imask"

import ButtonCta from "../../../components/Buttons/button/button-cta"
import Preloader from "../../../components/loaders/preloader/preloader"
import Modal from "../../../components/Modal/Modal"
import { useTheme } from "../../../components/ThemeContext/ThemeContext"
import { generatePayzenSplitPaymentTokenLink, printBill, printCommand } from "../../../globalAPI/api"
import useProceedCheckout from "../../../hooks/reservation/useProceedCheckout"
import { useCanDownload } from "../../../hooks/useCanDownload.jsx"
import useMediaPath from "../../../hooks/useMediaPath"
import usePath from "../../../routes/services/usePath.js"
import UserAgentHandler from "../../../services/UserAgentHandler"

import "./DebtBillDisplay.scss"

const DebtBillDisplay = ({ item, centerName, tunnelType, setBilltoPay, setPaymentPopInOpen, freeAmount }) => {
    const mediaPath = useMediaPath()
    const theme = useTheme()
    const path = usePath()
    const { check, message } = useCanDownload()
    const [ splitAmount, setSplitAmount ] = useState("")
    const [ shareLink, setShareLink ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isSharePaymentLinkOpen, setIsSharePaymentLinkOpen ] = useState(false)

    const amountDue = parseFloat((item.amountTTC - item.paidAmount)?.toFixed(2))

    const amount = tunnelType === "DEBT" ? item.lossAmount : freeAmount === "" ? amountDue : freeAmount
    const paymentUrl = tunnelType === "DEBT" ? path(`/profil/payment/debt/${item.id}`) : path(`/profil/payment/bill/${item.id}?amount=${amount}`)

    const {
        proceedCheckout,
        payzenForm,
    } = useProceedCheckout(
        {
            customData: {
                amount,
                resourceId: item.id,
                serviceType: tunnelType.toUpperCase(),
                vads_order_info: (tunnelType === "DEBT" ? "Ardoise #" : "Facture #") + item.id,
                vads_return_url: import.meta.env.VITE_URL_BASE
                    + "/?action=payzenPayment&type="
                    + (tunnelType === "DEBT" ? "DEBT" : "BILL") + `&theme=${theme}`,
            },
            lyraPaymentUrl: paymentUrl,
            redirectToListing: false,
            tunnelType: tunnelType,
        },
    )
    
    const onChangeAmount = (e) => {
        let value = e.target.value
        if (isNaN(value)) {
            return
        }
        setSplitAmount(value)
    }

    const downloadBill = async (id, isCommand, filename) => {
        isCommand ? await printCommand(id, filename + ".pdf") : await printBill(id, filename + ".pdf")
    }

    const payDebt = (item) => {
        setIsLoading(true)
        proceedCheckout(undefined, item.lossAmount)
    }

    const copyToClipboard = () => {
        var el = document.createElement("input")

        document.body.appendChild(el)
        el.value = shareLink.url

        if (UserAgentHandler.isIOS()) {
            el.contentEditable = true
            el.readOnly = false
            var range = document.createRange()
            range.selectNodeContents(el)
            var s = window.getSelection()
            s.removeAllRanges()
            s.addRange(range)
            el.setSelectionRange(0, 999999)
        } else {
            el.select()
        }

        try {
            document.execCommand("copy", false)
        } catch (err) {
            alert("unable to copy")
        }

        document.body.removeChild(el)
        let tokencopyconfirmationElem = document.querySelector(".token-copy-confirmation")
        tokencopyconfirmationElem.style.visibility = "visible"
        tokencopyconfirmationElem.style.opacity = 1
        setTimeout(
            () => {
                tokencopyconfirmationElem.style.visibility = "hidden"
                tokencopyconfirmationElem.style.opacity = 0
            },
            2500,
        )
    }

    const generateplitPaymentTokenLink = () => {
        setIsLoading(true)
        let data = {
            amount: splitAmount,
            resourceId: item.id,
            resourceType: tunnelType.toUpperCase(),
            tokenBaseUrl: import.meta.env.VITE_URL_BASE + "/", // add / for mobile app purpose
        }
        generatePayzenSplitPaymentTokenLink(data).then((result) => {
            setIsLoading(false)
            setShareLink(
                {
                    url: result.url + `&theme=${theme}`,
                    value: result.value,
                },
            )
            setSplitAmount("")
        })
    }
    const openSplitLinkGenerationPopin = () => {
        setIsSharePaymentLinkOpen(true)
    }

    return (
        <div className="debtBill">
            <div className="details">
                {tunnelType && tunnelType === "DEBT" && (
                    <div className="infosDebt">
                        <div className="center">{centerName}</div>
                        <div className="date">{item.date}</div>
                    </div>
                )}
                {tunnelType && tunnelType === "BILL" && (
                    <div className="infosBill">
                        <div className="type">{item.serviceTypeDisplay}</div>
                        <div className="date">{item.startDate}</div>
                        <div className="center">{centerName}</div>
                    </div>
                )}
                <div className="priceDetails">
                    <div className="price">{(tunnelType === "BILL"  ? item.amountTTC : item.lossAmount) + " €"}</div>
                    {tunnelType === "BILL" && item.status === 0 && (
                        <div className="amountDue">{amountDue} € restant{amountDue > 1 ? "s" : ""}</div>
                    )}
                    {tunnelType === "BILL" && item.status !== 0 && (
                        <div
                            className="download"
                            onClick={() => {
                                check(() => downloadBill(item.id, item?.isCommand, item?.code))
                            }}
                        >
                            <img alt="" src={mediaPath([ "/assets/icons/download.svg", "/assets/images/padel/icons/download.svg" ])} />
                        </div>
                    )}
                </div>
            </div>
            {tunnelType && tunnelType === "DEBT" && (
                <div className="actions">
                    <ButtonCta
                        isAlt={true}
                        text="RÉGLER"
                        onClick={() => payDebt(item)}
                    />
                </div>
            )}
            {tunnelType && tunnelType === "BILL" && item.status !== 20 && (
                <div className={item.splitable ? "actions splitable" : "actions"}>
                    <ButtonCta
                        isAlt={true}
                        text="RÉGLER"
                        onClick={async () => {
                            await setBilltoPay({
                                ...item,
                                centerName: centerName,
                            })
                            if (item.serviceType === 3 || item.serviceType === 1 || item.serviceType === 6 || item.serviceType === 50 || item.splitable) {
                                setPaymentPopInOpen?.(true)
                            } else {
                                setIsLoading(true)
                                proceedCheckout(undefined, amountDue)
                            }
                        }}
                    />
                    {item.splitable && (
                        <ButtonCta
                            isCta2Alt={true}
                            text="Partager un lien de paiement"
                            onClick={() => openSplitLinkGenerationPopin()}
                        />
                    )}
                </div>
            )}
            {item.splitable && (
                <Modal
                    isOpen={isSharePaymentLinkOpen}
                    onClose={() => setIsSharePaymentLinkOpen(false)}
                    className="sharePaymentLink"
                    beforeClose={() => setShareLink(null)}
                >
                    <header>Lien de partage de paiement</header>
                    <section className="popin--content">
                        {isLoading && <Preloader fixed={true} />}
                        <div className="message">Indique ci-dessous le montant du lien de partage à générer.</div>
                        <div className="linkAmount">
                            <div className="text">(Restant dû :  <span className="weight-bold orange">{amountDue}€</span>)</div>
                            <div className="input-price-holder">
                                <IMaskInput
                                    className="amount input-price-holder"
                                    mask={Number}
                                    inputMode="decimal"
                                    scale={2}
                                    value={splitAmount}
                                    onKeyDown={(evt) => [ "e", "E", "+", "-" ].includes(evt.key) && evt.preventDefault()}
                                    onChange={onChangeAmount}
                                    onBlur={
                                        () => {
                                            if (splitAmount) {
                                                setSplitAmount(parseFloat(splitAmount)?.toFixed(2))
                                            }
                                        }
                                    }
                                    placeholder={amountDue}
                                    normalizeZeros={false}
                                    radix={"."}
                                    mapToRadix={[ "," ]}
                                    type="number"
                                    pattern="\\d*"
                                />
                            </div>
                        </div>
                        {!!splitAmount && parseFloat(splitAmount) > amountDue && (
                            <div className="errorMessage">
                                <img alt="" src={mediaPath([ "/assets/icons/errorNotif.svg", "/assets/images/padel/icons/errorNotif.svg" ])}/>
                                <p>Le montant ne peut pas être supérieur au montant restant à payer</p>
                            </div>
                        )}
                        <ButtonCta
                            isCta2Alt
                            disabled={splitAmount === "" || splitAmount === "0" || parseFloat(splitAmount) > amountDue}
                            onClick={generateplitPaymentTokenLink}
                            text="Générer"
                            className="generateButton"
                        />

                        {shareLink && (
                            <ButtonCta
                                isCta2Alt={true}
                                onClick={copyToClipboard}
                                text="Copier le lien de partage"
                            />
                        )}
                        {shareLink && (
                            <div className="link-area">{"Lien de partage d'une valeur de " + shareLink.value + "€"}</div>
                        )}
                        <div className="token-copy-confirmation width100pct text-center">
                            Lien copié.
                        </div>
                    </section>
                </Modal>
            )}
            {isLoading && <Preloader fixed={true} />}
            {payzenForm && payzenForm}
            {tunnelType === "BILL" && item.status !== 0 && (
                <>
                    { message }
                </>
            )}
        </div>
    )
}

DebtBillDisplay.propTypes = {
    centerName: oneOfType([ string, bool ]),
    freeAmount: oneOfType([ bool, string ]),
    item: object,
    setBilltoPay: oneOfType([ bool, func ]),
    setPaymentPopInOpen: func,
    tunnelType: oneOfType([ string, bool ]),
}

export default DebtBillDisplay
