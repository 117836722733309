/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */

import disconnectUser from "../disconnectUser"

function initializeMenuPadel({ hasArdoises = false, nextBooking = false, hasUnpaidBills = false }) {
    const menuslevel1 = {
        home: {
            slug: "accueil",
            label: "Accueil",
            img: "/assets/images/logo-white.svg",
            desktopSection: "logo-part",
            appRoute: "/",
        },
        reservations: {
            slug: "reservations",
            label: "locations",
            desktopSection: "main-part",
            mobileSection: "menu",
            appRoute: "/padel/reservations/locations",
            hasNotif: nextBooking,
            customCls: "locationMenu",
        },
        urbanCup: {
            slug: "urbanCup",
            label: "tournois",
            desktopSection: "main-part",
            mobileSection: "menu",
            appRoute: "/padel/tournois",
        },
        cours_stage: {
            slug: "cours_et_stage",
            label: "cours et stages",
            desktopSection: "main-part",
            mobileSection: "menu",
        },
        birthdays: {
            appRoute: "/padel/kids/anniversaire",
            desktopSection: "main-part",
            label: "Anniversaire",
            mobileSection: "menu",
            slug: "birthday",
        },
        profil: {
            slug: "profil",
            label: "Mon profil",
            desktopSection: "other-part",
            mobileSection: "main",
            icon: "userProfile.svg",
        },
        reserver: {
            slug: "reserver",
            label: "Réserver une piste",
            desktopSection: "other-part",
            mobileSection: "main",
            appRoute: "/padel/reserver",
            icon: "/assets/images/padel/icons/icon-calendar.svg",
            customCls: "reserver-menu-item",
        },
        informationsLegales: {
            slug: "informationsLegales",
            label: "Informations légales",
            mobileSection: "menu",
            customCls: "specific-mobile-menu-item",
        },
        contact: {
            slug: "contact",
            label: "Contact",
            mobileSection: "menu",
            externalLink: "https://www.urbanpadel.fr/contact/",
            customCls: "specific-mobile-menu-item",
        },
        cookies: {
            slug: "cookies",
            label: "Cookies",
            mobileSection: "menu",
            externalLink: "javascript:Didomi.preferences.show()",
            customCls: "specific-mobile-menu-item",
        },
    }
    const menuslevel2 = {
        cours_stage: [
            {
                label: "Cours",
                appRoute: "/padel/cours",
            },
            {
                label: "Stages",
                appRoute: "/padel/stages",
            },
        ],
        profil: [
            {
                label: "Profil",
                appRoute: "/padel/profil/coordonnees",
                icon: "/assets/images/padel/icons/icons-graphics-coordonnees.svg",
                isVisible: true,
            },
            {
                label: "Joueurs",
                appRoute: "/padel/profil/coequipiers",
                icon: "/assets/images/padel/icons/icons-graphics-coequipiers.svg",
                isVisible: true,
            },
            {
                label: "Abonnements",
                appRoute: "/padel/profil/abonnements",
                icon: "/assets/images/padel/icons/icons-graphics-abonnement.svg",
                isVisible: true,
            },
            {
                label: "Factures",
                appRoute: "/padel/profil/factures",
                icon: "/assets/images/padel/icons/icons-graphics-facture.svg",
                isVisible: true,
                hasNotification: hasUnpaidBills,
            },
            {
                label: "Créneaux Favoris",
                appRoute: "/padel/profil/creneauxFavoris",
                icon: "/assets/images/padel/icons/icons-graphics-alertes-terrain.svg",
                isVisible: true,
            },
            {
                label: "Ardoises",
                appRoute: "/padel/profil/ardoises",
                icon: "/assets/images/padel/icons/icons-graphics-ardoise.svg",
                isVisible: hasArdoises,
                hasNotification: hasArdoises,
            },
            {
                label: "Moyens de paiement",
                appRoute: "/padel/profil/moyensDePaiement",
                icon: "/assets/images/padel/icons/icons-graphics-moyen-paiment.svg",
                isVisible: true,
            },
            {
                label: "Déconnexion",
                action: () => disconnectUser("/padel/login"),
                parentClass: "disconnectButton",
                icon: "/assets/images/padel/icons/icons-graphics-deconnexion.svg",
                isVisible: true,
                customCls: "logoutCheckblock",
            },
        ],
        informationsLegales: [
            {
                label: "Mentions légales",
                externalLink: "https://www.urbanpadel.fr/mentions-legales/",
            },
            {
                label: "Politique de données personnelles",
                externalLink: "https://www.urbanpadel.fr/politique-de-confidentialite/",
            },
        ],
    }

    return {
        menuslevel1,
        menuslevel2,
    }
}

export default initializeMenuPadel
