
import RoutesPayments from "./payments/RoutesPayments.jsx"
import generateRoutesBirthday from "./RoutesBirthday.jsx"
import generateRoutesReservations from "./RoutesReservations.jsx"
import RoutesCup from "./soccer/RoutesCup"
import RoutesKids from "./soccer/RoutesKids"
import RoutesLeagues from "./soccer/RoutesLeagues"
import RoutesProfile from "./soccer/RoutesProfile"
import RoutesSuperSub from "./soccer/RoutesSuperSub"

const RoutesSoccer = [
    // PROFILE
    ...RoutesProfile,
    // RESERVATIONS
    ...generateRoutesReservations(),
    ...generateRoutesBirthday(),
    // KIDS
    ...RoutesKids,
    // LEAGUE
    ...RoutesLeagues,
    // CUP
    ...RoutesCup,
    // SUPERSUB
    ...RoutesSuperSub,
    // PAYMENTS
    ...RoutesPayments,
]

export default RoutesSoccer
