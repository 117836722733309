export function getSortedEquipments(equipments) {
    const categories = equipments.reduce((acc, equipment) => {
        if (!acc[equipment.category]) {
            acc[equipment.category] = []
        }
        acc[equipment.category].push({
            ...equipment,
            sizes: equipment.sizes.map(size => ({
                label: size.Value,
                value: size.Key,
            })),
        })
        return acc
    }, {})

    // Create an array of equipment arrays, each sorted by name,
    // then flatten the array of arrays into a single array
    return Object.keys(categories).sort().flatMap(key =>
        categories[key].sort((a, b) => a.name.localeCompare(b.name)),
    )
}
