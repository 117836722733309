import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"

import { CupProducts } from "./components/CupProducts.jsx"
import CupChoice from "./Steps/CupChoice"
import PartnerChoice from "./Steps/PartnerChoice"
import Preloader from "../../../components/loaders/preloader/preloader"
import StepManager from "../../../components/stepsManagement/StepManager"
import StepsContainer from "../../../components/stepsManagement/StepsContainer"
import useAddSearchParam from "../../../hooks/useAddSearchParams"
import { setFormEntry } from "../../../store/forms/actions"
import { goToStep } from "../../../store/stepsManagement/actions"
import OrderSummary from "../../payments/OrderSummary/OrderSummary"
import useRestoreTeamTunnel from "../hooks/useRestoreTeamTunnel"
import PrestationSupp from "../urbanCup/steps/PrestationSupp"

import "./PadelCup.scss"

export default function PadelCup() {
    const [ searchParams ] = useSearchParams()
    const { id } = useParams()
    const dispatch = useDispatch()
    const addSearchParams = useAddSearchParam()

    const tunnelType = "CUP"
    
    const restoreStep = () => {
        if (searchParams?.get("equipmentId")) {
            dispatch(goToStep(4))
        } else if (searchParams?.get("optionId")) {
            dispatch(goToStep(3))
        } else if (searchParams?.get("partnerId")) {
            dispatch(goToStep(2))
        } else if (searchParams?.get("cupId")) {
            dispatch(goToStep(1))
        }
    }

    const registrationId = searchParams.get("registrationId")
    const ajaxLoading = useRestoreTeamTunnel(tunnelType, undefined, id || registrationId, null, restoreStep)
    const tournamentRegistration = useSelector(state => state.forms.TournamentRegistration)
    const selectedPadelCup = useSelector(state => state.forms.selectedPadelCup)
    const skipPartner = !selectedPadelCup?.player2Required
    const prestaSuppStepNumber = skipPartner ? 2 : 3
    const prestaSuppPrevious = skipPartner ? 0 : 1
    const cupHasPresta = tournamentRegistration?.cup?.optionProducts?.filter(option => !option.showAtPayment)?.length > 0
    const equipmentStepNumber = (skipPartner ? 3 : 4) - (cupHasPresta ? 0 : 1)
    const equipmentPrevious = cupHasPresta ? 2 : 1 - (skipPartner ? 1 : 0) 
    const cupHasEquipment = tournamentRegistration?.cup?.catalogProducts?.length > 0
    const summaryPrevious = cupHasEquipment ? 3 : 2 - (cupHasPresta ? 0 : skipPartner ? 2 : 1) 

    useEffect(() => {
        if (tournamentRegistration?.id) {
            addSearchParams("registrationId", tournamentRegistration.id)
        }
    }, [ tournamentRegistration ])

    useEffect(() => {
        if (selectedPadelCup?.player2Required) {
            dispatch(setFormEntry(
                "skipPartner",
                false,
            ))
        } else {
            dispatch(setFormEntry(
                "skipPartner",
                true,
            ))
        }
    }, [ selectedPadelCup ])

    return (
        <React.Fragment>
            {ajaxLoading &&
                <Preloader fixed={true}/>
            }
            <StepManager customCls="padelCup">
                <StepsContainer stepsToShow='2'>
                    <CupChoice tunnelType={tunnelType}/>
                    <PartnerChoice tunnelType={tunnelType}/>
                </StepsContainer>
                <PrestationSupp
                    tunnelType={tunnelType}
                    numero={prestaSuppStepNumber + "/"}
                    goToPreviousStep={prestaSuppPrevious}
                    updateSearchParams
                />
                <CupProducts
                    numero={equipmentStepNumber + "/"}
                    customPrevious={equipmentPrevious}
                />
                <OrderSummary
                    tunnelType={tunnelType}
                    goToPreviousStep={summaryPrevious}
                />
            </StepManager>
        </React.Fragment>
    )
}
