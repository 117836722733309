import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import CustomSelect from "../../../components/Forms/customSelect/customSelect"
import useMediaPath from "../../../hooks/useMediaPath"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import { padelProducts } from "../../../model/data/padelProducts"
import products from "../../../model/data/products"
import { clearAllForms } from "../../../store/forms/actions"

import "./HomeBooking.scss"

export default function HomeBooking() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const themeFeature = useThemeFeatures()
    const mediaPath = useMediaPath()

    function openProductLink(product) {
        const selectedProduct = product
        if (selectedProduct !== "") {
            if (selectedProduct.isMyUrban) {
                dispatch(
                    clearAllForms(),
                )

                navigate(selectedProduct.link, {
                    state: {
                        ...selectedProduct.props,
                    },
                })
            } else {
                window.open(selectedProduct.link, "_blank")
            }
        }
    }

    return (
        <div className="home-booking">
            <img src={mediaPath([ "/assets/images/bloc-centre-photo.png", "/assets/images/padel/padel-booking.png" ])} />
            <div className="label-reserver">Réserver</div>
            <div className="select-wrapper">
                <CustomSelect
                    items={themeFeature([ products(), padelProducts ])}
                    defaultOption="Sélectionner un produit"
                    clickCallBack={openProductLink}
                    selectId="productDashboardSelect"
                />
            </div>
        </div>
    )
}
