import dayjs from "dayjs"
import { bool, number, object, string } from "prop-types"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"

import { getAllKidsStageSubscriptions, getKidsStageSubscription } from "./api"
import { StageDeFootProducts } from "./components/StageDeFootProducts/StageDeFootProducts.jsx"
import Preloader from "../../../components/loaders/preloader/preloader"
import StepManager from "../../../components/stepsManagement/StepManager"
import StepsContainer from "../../../components/stepsManagement/StepsContainer"
import useQueryParametersObject from "../../../hooks/routing/useQueryParametersObject"
import useThemeHeader from "../../../hooks/useThemeHeader"
import { setFormEntry } from "../../../store/forms/actions"
import { goToStep } from "../../../store/stepsManagement/actions"
import { resetNativeHistoryToCurrentLocation } from "../../../utilities/helpers"
import OrderSummary from "../../payments/OrderSummary/OrderSummary"
import PrestationSupp from "../../teams/urbanCup/steps/PrestationSupp"
import CreneauDispo from "../ecoleDeFoot/steps/CreneauxDispo"
import EmergencyContact from "../ecoleDeFoot/steps/EmergencyContact"
import ChooseCenterAndType from "../steps/ChooseCenterAndType"
import MesEnfants from "../steps/MesEnfants"

function StageDeFootTunnel({ params, denyReturn, stageSubId, initialStep, centerIdProps, stageTypeProps }) {
    const dispatch = useDispatch()
    const { state } = useLocation()
    const navigate = useNavigate()
    const themeHeader = useThemeHeader()
    const routerParams = useParams()
    const [ searchParams, setSearchParams ] = useSearchParams()
    const searchParamsObject = useQueryParametersObject()

    if (state) {
        params = state.params
        denyReturn = state.denyReturn
        stageSubId = state.stageSubId
        initialStep = state.initialStep
        centerIdProps = state.centerIdProps
        stageTypeProps = state.stageTypeProps
    }

    // let stepIndex = 6;
    const [ noReturn, setNoReturn ] = useState(denyReturn)
    const tunnelType = "TRAINING"
    const [ ajaxLoading, setAjaxLoading ] = useState(false)

    const location = useLocation()
    if (location.state) {
        stageSubId = location.state.stageSubId
        initialStep = location.state.initialStep
    }

    let { centerId, typeId } = useQueryParametersObject()

    if (centerId === undefined && typeId === undefined) {
        if (centerIdProps && stageTypeProps) {
            centerId = centerIdProps
            typeId = stageTypeProps
        }
    }

    useEffect(
        () => {
            resetNativeHistoryToCurrentLocation(window.location.href)

            const periodStart = dayjs(new Date()).subtract(3, "year").format("YYYY-MM-DD")
            getAllKidsStageSubscriptions({
                offset: 0,
                orderByAsc: false,
                periodStart: periodStart,
                status: "[2,1,3]",
            }).then(
                (res) => {
                    if (res) {
                        if (res.count === 0) {setNoReturn(true)}
                        else {setNoReturn(false)}
                    }
                },
            )

            if (initialStep) {
                dispatch(goToStep(initialStep))
            }

            if ((params && params.id) || stageSubId) {
                setAjaxLoading(true)
                getKidsStageSubscription(
                    (params && params.id) || stageSubId,
                ).then(
                    (res) => {
                        dispatch(
                            setFormEntry(
                                "ozmoSubscription",
                                {
                                    firstSubscription: res.data.data,
                                },
                            ),
                        )
                        setAjaxLoading(false)
                    },
                ).catch(() => {
                    setAjaxLoading(false)
                })
            }
        }, [],
    )

    return (
        <>
            {ajaxLoading && <Preloader fixed={true}/>}
            <StepManager customCls="stageSubscription" >
                <StepsContainer stepsToShow='3'>
                    <ChooseCenterAndType
                        initialCenterId={centerId}
                        initialTypeId={typeId}
                        tunnelType={tunnelType}
                        noReturn={noReturn}
                    />
                    <MesEnfants tunnelType={tunnelType}/>
                    <CreneauDispo
                        tunnelType={tunnelType}
                        navigate={navigate}
                        themeHeader={themeHeader}
                        router={{ location, navigate, routerParams, searchParams, searchParamsObject, setSearchParams }}
                    />
                </StepsContainer>
                <PrestationSupp tunnelType={tunnelType}/>
                <StageDeFootProducts/>
                <EmergencyContact tunnelType={tunnelType}/>
                <OrderSummary tunnelType={tunnelType}/>
            </StepManager>
        </>
    )
}

StageDeFootTunnel.propTypes = {
    centerIdProps: number,
    denyReturn: bool,
    initialStep: number,
    params: object,
    stageSubId: string,
    stageTypeProps: string,
}

export default StageDeFootTunnel
