import { object } from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useStore } from "react-redux"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import ListingRegistrationHolder from "../../../../../components/listing/ListingRegistrationHolder"
import StatusDisplay from "../../../../../components/listing/StatusDisplay"
import Modal from "../../../../../components/Modal/Modal"
import MessagePopin from "../../../../../components/popin/MessagePopin/MessagePopin"
import HourDisplay from "../../../../../components/Time/HourDisplay"
import { displayBill } from "../../../../../globalAPI/api"
import { useCanDownload } from "../../../../../hooks/useCanDownload.jsx"
import dayValues from "../../../../../model/enums/dayValues"
import ModalHandler from "../../../../../providers/Modal/ModalHandler"
import usePath from "../../../../../routes/services/usePath"
import { openPopin, popUrl } from "../../../../../services/routingManagment"
import { setFormEntry } from "../../../../../store/forms/actions"
import MesEnfants from "../../../steps/MesEnfants"
import { cancelBirthday, getBirthdayRegistration } from "../../api"
import CreneauDispo from "../../steps/CreneauxDispo"

import "./BirthdayListingElement.scss"

BirthdayListingElement.propTypes = {
    birthdayRegistration: object,
    displayDetail: object,
}
function BirthdayListingElement({ birthdayRegistration, displayDetail }) {
    const { check, message } = useCanDownload()
    const path = usePath()
    const navigate = useNavigate()
    const {
        id, birthdayConfig, centerName, status, statusValue, kid,
        // start, duration, gifts, drinks, cakes
    } = birthdayRegistration
    const [ details, setDetails ] = useState(false)
    const preRegistrationLimitDate = birthdayRegistration.preRegistrationLimitDate || details.preRegistrationLimitDate || null

    const { type } = birthdayConfig
    const date = new Date(birthdayRegistration.start)

    const dateFin = new Date(date.getTime() + birthdayConfig.birthdayDuration * 60000)

    let birthdayRegistrationDetail = {}

    /* STATE */
    const [ isLoading, setIsLoading ] = useState(false)
    const [ , updateState ] = React.useState()

    const forceUpdate = React.useCallback(() => updateState({}), [])

    /* STORE */
    const store = useStore()

    /* VARIABLES */
    const dispatch = useDispatch()

    /* ACTIONS */
    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }

    /* FORMULAIRES */
    const formCreneauDispo = store.getState().forms[CreneauDispo.formName] !== undefined ?
        store.getState().forms[CreneauDispo.formName]
        : {}
    const formEnfant = store.getState().forms[MesEnfants.formName] !== undefined ? 
        store.getState().forms[MesEnfants.formName] : {}

    useEffect(() => {
        if (displayDetail === true) {
            detail()
        } else if (displayDetail === false) {
            ModalHandler.hide(DetailsPopIn)
        }
    }, [])

    useEffect(() => {
        if (birthdayRegistration?.statusValue === 2) {
            getBirthdayRegistration(birthdayRegistration.id).then((res) => {
                setDetails(res.data.data)
            })
        }
    }, [ birthdayRegistration?.id ])

    function finaliser() {

        setIsLoading(true)

        getBirthdayRegistration(birthdayRegistration.id).then(
            (res) => {
                var birthdayRegistrationDetail = res.data.data

                setIsLoading(false)

                setFormInStore(MesEnfants.formName, {
                    ...formEnfant,
                    selectedKid: birthdayRegistrationDetail.kid,
                })
        
                setFormInStore(CreneauDispo.formName, {
                    ...formCreneauDispo,
                    birthdaySubscription: birthdayRegistrationDetail,
                })
                navigate(path("/kids/anniversaire/new"), {
                    state: {
                        isBirthdayRequestSlotFinalization: true,
                    },
                })
            },
        )

    }

    function annuler() {

        setIsLoading(true)

        getBirthdayRegistration(id).then((res) => {
            birthdayRegistrationDetail = res.data.data

            if (statusValue === 2) {
                openPopin("listing", "annuler", birthdayRegistration.id)
    
                setIsLoading(false)
    
                setFormInStore(CreneauDispo.formName, {
                    ...formCreneauDispo,
                    birthdaySubscription: birthdayRegistrationDetail,
                })
                navigate(path("/kids/anniversaire/new"), {
                    state: {
                        isPreRegistrationBirthday: true,
                    },
                })
        
            } else if (
                statusValue === 0
                || statusValue === 1
            ) {
                cancelBirthday(id).then(
                    (res) => {
                        setIsLoading(false)
                        navigate(path("/confirmcommand"), {
                            state: {
                                confirmedSubscription: res.data.data,
                                isCanceled: true,
                                message: "Nous vous confirmons avoir annulé votre réservation d'anniversaire. ",
                                success: true,
                                title: "Réservation d'anniversaire annulée",
                                tunnelType: "BIRTHDAY",
                            },
                        })
                    },
                )
            }
        })

    }

    function payer() {

        setIsLoading(true)

        getBirthdayRegistration(birthdayRegistration.id).then((res) => {
            birthdayRegistrationDetail = res.data.data

            setIsLoading(false)

            setFormInStore(CreneauDispo.formName, {
                ...formCreneauDispo,
                birthdaySubscription: birthdayRegistrationDetail,
            })
            navigate(path("/kids/anniversaire/new"), {
                state: {
                    isPreRegistrationBirthday: true,
                },
            })
        })

    }

    function detail() {
        setIsLoading(true)

        getBirthdayRegistration(id).then((res) => {
            birthdayRegistrationDetail = res.data.data

            let catalogArticles = []
            if (birthdayRegistrationDetail.catalogArticles) {
                birthdayRegistrationDetail.catalogArticles.forEach((catalogArticle) => {
                    catalogArticles.push(
                        <div className="catalog-article">
                            <img src={catalogArticle.product.pictureUrl} />
                            <div>
                                Qté : <span>{catalogArticle.quantity}</span>
                            </div>
                        </div>,
                    )
                })
            }

            const giftSize = birthdayRegistrationDetail.gifts[0] &&
            birthdayRegistrationDetail.gifts[0].product.name.toLowerCase().includes("maillot") ? 
                birthdayRegistrationDetail.gifts[0].size : null

            let optionProducts = []
            if (birthdayRegistrationDetail.optionProducts) {
                birthdayRegistrationDetail.optionProducts.forEach((option) => {
                    optionProducts.push(
                        <li>- {option.name}</li>,
                    )
                })
            }

            // build details popin
            const popin = (
                <>
                    <header> Détail de la réservation </header>
                    <article>
                        <section className="details">
                            <div>
                                <div className="header">Anniversaire</div>
                                <strong>{type.name}</strong>
                                <div>{centerName}</div>
                                <div>
                                    <strong>Date : </strong>
                                    {
                                        date.toLocaleDateString()
                                    }
                                </div>
                                <div>
                                    <strong>Créneau : </strong>
                                    <HourDisplay date={date}/> <span> - </span> <HourDisplay date={dateFin}/>
                                </div>
                                <div>
                                    <strong>Enfant : </strong>
                                    {kid.firstName + " " + kid.lastName}</div>
                            </div>
                            <div>
                                <strong>Inclus dans la formule :</strong>
                                <ul>
                                    {
                                        birthdayRegistrationDetail.gifts && birthdayRegistrationDetail.gifts[0] &&
                                        (<li>
                                            - {birthdayRegistrationDetail.gifts[0].product.name}
                                            <span> {giftSize ? giftSize : ""} </span>
                                        </li>)
                                    }
                                    {
                                        birthdayRegistrationDetail.drinks && birthdayRegistrationDetail.drinks[0] &&
                                            <li>- {birthdayRegistrationDetail.drinks[0].name}</li>
                                    }
                                    {
                                        birthdayRegistrationDetail.cakes && birthdayRegistrationDetail.cakes[0] &&
                                            <li>- {birthdayRegistrationDetail.cakes[0].name}</li>
                                    }
                                </ul>

                                {
                                    optionProducts && optionProducts.length > 0 &&
                                    (<div>
                                        <strong>Options facultatives :</strong>
                                        <ul>
                                            {optionProducts}
                                        </ul>
                                    </div>)
                                }
                            </div>
                        </section>

                        {
                            catalogArticles && catalogArticles.length > 0 &&
                            (<section>
                                <div className="header">Cadeaux supplémentaires</div>
                                <div className="catalogArticlesDiv">
                                    {catalogArticles}
                                </div>
                            </section>)
                        }

                        <section className="birthdayComment">
                            <div className="header">Commentaires</div>
                            {
                                birthdayRegistrationDetail.comments && birthdayRegistrationDetail.comments.length > 0 &&
                                (<div className="comment">
                                    {birthdayRegistrationDetail.comments}
                                </div>)
                            }
                            {
                                (!birthdayRegistrationDetail.comments ||
                                    birthdayRegistrationDetail.comments && birthdayRegistrationDetail.comments.length === 0) &&
                                (<div>
                                    Aucun commentaire pour cet anniversaire
                                </div>)
                            }

                        </section>
                    </article>
                </>
            )

            setIsLoading(false)
            showPopin(popin)
        }).catch(() => {
            setIsLoading(false)
        })
    }

    const showPopin = (popin) => {
        ModalHandler.show(DetailsPopIn, {
            details: popin,
        })
    }

    return (
        <ListingRegistrationHolder
            isLoading={isLoading}
            logo={type.pictureUrl}
            status={
                (<StatusDisplay
                    id={id}
                    status={
                        status === "Pré-Inscription" ?
                            "Pré-Réservation"
                            :
                            status
                    }
                    statusValue={statusValue}
                    preRegistrationLimitDate={preRegistrationLimitDate}
                />)
            }
            name={type.name}
            details={
                (<React.Fragment>
                    <div>UrbanSoccer {centerName}</div>
                    <div>
                        {dayValues[date.getDay()] } {/* Jour de la semaine */} 
                        {
                            date.toLocaleDateString()
                        }&nbsp;à <HourDisplay date={date}/>
                    </div>
                    <div>{kid.firstName + " " + kid.lastName}</div>
                    <div 
                        className="detail-btn"
                        onClick={() => {
                            openPopin("listing", "details", birthdayRegistration.id)
                            detail()
                        }}>
                        Voir le détail
                    </div>
                </React.Fragment>)
            }
            actions={
                (<React.Fragment>
                    {
                        // status confirmé
                        birthdayRegistration.statusValue === 3 &&
                            (<>
                                <ButtonCta
                                    isCta2Alt={true}
                                    text="Télécharger la facture"
                                    onClick={() => {
                                        check(() => displayBill(birthdayRegistration.billId, setIsLoading).then((res) => {
                                            if (res) {
                                                ModalHandler.show(MessagePopin, {
                                                    message: res,
                                                    title: "Erreur",
                                                })
                                            }
                                        }))
                                    }}
                                />
                            </>)
                    }

                    {
                        // status pré-inscription
                        birthdayRegistration.statusValue === 2 &&
                            (<ButtonCta
                                className="buttonCta--mb"
                                isAlt={true}
                                text="Payer en ligne"
                                onClick={() => {
                                    openPopin("listing", "payer", birthdayRegistration.id)
                                    payer()
                                }}
                            />)
                    }

                    {
                        // status A Pourvoir ou En Cours
                        (birthdayRegistration.statusValue === 0
                            || birthdayRegistration.statusValue === 1) &&
                                (
                                    <ButtonCta 
                                        className="buttonCta--mb"
                                        isAlt = {true}
                                        text = "Finaliser la réservation"
                                        onClick={() => {
                                            openPopin("listing", "finaliser", birthdayRegistration.id)
                                            finaliser()
                                        }}
                                    />
                                )
                    }

                    {
                        // status pré-inscription ou A Pourvoir ou En Cours
                        (birthdayRegistration.statusValue === 2 || birthdayRegistration.statusValue === 0
                            || birthdayRegistration.statusValue === 1) &&
                                (
                                    <ButtonCta 
                                        isCta2Alt = {true}
                                        text = "Annuler la réservation"
                                        onClick={() => {
                                            annuler()
                                        }}
                                    />
                                )
                    }
                    {message}
                </React.Fragment>)
            }
        />
    )
}

export const DetailsPopIn = ModalHandler.create(({ visible, hide, details }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            beforeClose={() => {
                popUrl()
            }}
        >
            {details}
        </Modal>
    )
})

export default BirthdayListingElement
