import PropTypes from "prop-types"

import { DidomiDataLayerProvider } from "../../contexts/DidomiDataLayerContext"
import DidomiDataLayerButton from "../DidomiDataLayerButton/DidomiDataLayerButton"
import DidomiDataLayerModal from "../DidomiDataLayerModal/DidomiDataLayerModal"

export const DidomiDataLayerWrapper = ({ children }) => {
    const enableDatalayerDebug = import.meta.env.VITE_ENABLE_DATALAYER_DEBUG === "true"

    return (
        <DidomiDataLayerProvider>
            {children}
            {enableDatalayerDebug && (
                <>
                    <DidomiDataLayerButton />
                    <DidomiDataLayerModal />
                </>
            )}
        </DidomiDataLayerProvider>
    )
}

DidomiDataLayerWrapper.propTypes = {
    children: PropTypes.node.isRequired,
}

export default DidomiDataLayerWrapper 
