import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { getSortedEquipments } from "../../../../../components/reservation/Shop/New/service.js"
import { ShopStep } from "../../../../../components/reservation/Shop/New/ShopStep/ShopStep.jsx"
import useAddSearchParam from "../../../../../hooks/useAddSearchParams.js"
import resetScroll from "../../../../../services/resetScroll.js"
import { addProductToCart, removeProductFromCart } from "../../../../../store/cart/actions.js"
import { setFormEntry } from "../../../../../store/forms/actions.js"
import { nextStep } from "../../../../../store/stepsManagement/actions.js"
import { addBirthdayCatalogArticle, getBirthdayCatalogDiscount, removeBirthdayCatalogArticle } from "../../api.js"
import CreneauDispo from "../../steps/CreneauxDispo.jsx"

const tunnelType = "BIRTHDAY"

export const BirthdayProducts = () => {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ advertising, setAdvertising ] = useState(null)
    const [ catalogDiscount, setCatalogDiscount ] = useState(null)

    const { forms, cart, availableEquipment, checkSkipStepEDF, stepManagement } = useSelector(state => ({
        availableEquipment: state.forms[ShopStep.formDataName]?.availableEquipment,
        cart: state.cart,
        checkSkipStepEDF: state.checkSkipStepEDF,
        forms: state.forms,
        stepManagement: state.stepManagement,
    }))

    const dispatch = useDispatch()
    const addSearchParams = useAddSearchParam()
    const location = useLocation()
    const birthdaySubscription = forms[CreneauDispo.formName].birthdaySubscription || {}
    const pickedProducts = birthdaySubscription.catalogArticles ?? []

    useEffect(() => {
        resetScroll()
        const fetchKidsSchoolDetail = async () => {
            setIsLoading(true)
            try {
                const res = await getBirthdayCatalogDiscount(birthdaySubscription.birthdayConfig.id)
                setAdvertising(birthdaySubscription.birthdayConfig.advertising)
                setCatalogDiscount(res.data.data[0])
                setInfoSchool(birthdaySubscription.birthdayConfig.catalogProducts)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error("Failed to fetch kids shool detail:", error)
            } finally {
                setIsLoading(false)
            }
        }

        if (birthdaySubscription?.id) {
            fetchKidsSchoolDetail()
        } else {
            dispatch(setFormEntry(ShopStep.formDataName, {
                ...forms[ShopStep.formDataName],
                availableEquipment: {},
            }))
        }
    }, [])

    const setInfoSchool = (formatedAvailableEquipment = []) => {
        const products = getSortedEquipments(formatedAvailableEquipment)
        dispatch(setFormEntry(ShopStep.formDataName, {
            ...forms[ShopStep.formDataName],
            availableEquipment: products,
        }))

        birthdaySubscription.catalogArticles.forEach((element) => {
            if (!cart.equipments[element.product.id]) {
                const equipment = formatedAvailableEquipment.find((equipment) => equipment.id === element.product.id)
                let formattedSizes = {}
                equipment.sizes.forEach((size) => {
                    formattedSizes[size.Value] = size.Key
                })
                dispatch(addProductToCart(element.product.id, formattedSizes[element.size], element.quantity, element.product.price))
            }
        })
    }

    const addProduct = (product) => {
        let initialValue = 0
        const { size, count, product : { id } } = product
        const articleInCatalog = pickedProducts.find(
            x => x.sizeValue === parseInt(size) &&
                x.product.id === id,
        )

        if (articleInCatalog) {
            initialValue = articleInCatalog.quantity
        }
        setIsLoading(true)

        product = {
            productId: parseInt(id),
            quantity: parseInt(count) + initialValue,
            size: parseInt(size),
        }

        const handleProductAdd = () => {
            addBirthdayCatalogArticle(
                birthdaySubscription.id,
                product,
            ).then(
                (res) => {
                    setIsLoading(false)
                    dispatch(setFormEntry(CreneauDispo.formName, {
                        ...forms[CreneauDispo.formName],
                        birthdaySubscription: res.data.data,
                    }))
                    dispatch(setFormEntry("avoirFormData", {
                        ...forms.avoirFormData,
                        recalcAvoir: true,
                    }))
                },
            )
        }

        if (initialValue !== 0) {
            removeBirthdayCatalogArticle(
                birthdaySubscription.id,
                articleInCatalog.id,
            ).then(handleProductAdd)
        } else {
            handleProductAdd()
        }
    }

    const removeProduct = (product) => {
        dispatch(removeProductFromCart(product.product.id, product.sizeValue + ""))

        setIsLoading(true)
        removeBirthdayCatalogArticle(birthdaySubscription.id, product.id).then(
            (res) => {
                setIsLoading(false)
                dispatch(setFormEntry(CreneauDispo.formName, {
                    ...forms[CreneauDispo.formName],
                    birthdaySubscription: res.data.data,
                }))
                dispatch(setFormEntry("avoirFormData", {
                    ...forms.avoirFormData,
                    recalcAvoir: true,
                }))
            },
        ).catch(() => setIsLoading(false))
    }
    const subTotal = pickedProducts.reduce((acc, product) => acc + product.product.price * product.quantity, 0)
    return (
        <ShopStep
            title="Cadeaux Supplémentaires"
            tunnelType={tunnelType}
            subtitle="Faites plaisir à votre enfant et ses copains avec encore plus de cadeaux !"
            addProduct={addProduct}
            advertising={advertising}
            availableProducts={availableEquipment}
            isLoading={isLoading}
            products={pickedProducts}
            discount={catalogDiscount}
            deleteProduct={removeProduct}
            checkBeforeNextStep={!location?.state?.isBirthdayRequestSlotFinalization ? () => {
                addSearchParams(
                    "equipmentId",
                    forms?.TournamentRegistration?.catalogArticles?.length ?
                        forms?.TournamentRegistration?.catalogArticles[0]?.id : "none",
                )
                dispatch(nextStep())
            } : null}
            pricingInfos={{
                discountOnSubTotal: birthdaySubscription.catalogDiscountAmount,
                subtotal: subTotal,
            }}
            goToPreviousStep={checkSkipStepEDF?.skipPrestaSupp ? stepManagement.currentStep - 2 : null}
        />
    )
}
