import PropTypes from "prop-types"

import Footer from "../../components/layout/footer/footer.jsx"
import MenuBuilder from "../../components/menu/menuBuilder.jsx"
import Header from "../../entries/main/componentsContainers/header.jsx"
import useCookies from "../../hooks/useCookies.js"

import "./BusinessLeagueFormLayout.scss"

export const BusinessLeagueTunnelBaseLayout = ({ children }) => {
    const { isWebview, isAppV2 } = useCookies()

    return (
        <>
            {!isAppV2 && (<div className="mobileMenu show-st">
                <Header visitor/>
            </div>)}
            <div className="desktopMenu hide-st">
                <MenuBuilder forceVisitorHeader/>
            </div>
            <div id="content" className="content">
                {children}
            </div>
            {!isWebview && !isAppV2 && <Footer forceVisitorFooter/>}
        </>

    )
}

BusinessLeagueTunnelBaseLayout.propTypes = {
    children: PropTypes.node,
}
