import "./services/errorHandler.js"
import * as ReactTooltip from "@radix-ui/react-tooltip"
import dayjs from "dayjs"
import { createRoot } from "react-dom/client"
import { SkeletonTheme } from "react-loading-skeleton"
import { Provider } from "react-redux"

import "dayjs/locale/fr"
import DidomiDataLayerWrapper from "./components/DidomiDataLayerWrapper/DidomiDataLayerWrapper.jsx"
import { ThemeProvider } from "./components/ThemeContext/ThemeContext"
import { AssetProvider } from "./providers/AssetsProvider.jsx"
import CookieProvider from "./providers/CookieProvider.jsx"
import { IntercomProvider } from "./providers/IntercomIntegration.jsx"
import { TrackerProvider } from "./providers/TrackerProvider"
import { Router } from "./routes/RoutesMapping.jsx"
import handleRedirections from "./services/handleRedirections"
import { loadUrlQueryParameters } from "./store/actions/scaffold"
import store, { history } from "./store/store"
import DynamicStrictMode from "./utilities/DynamicStrictMode.jsx"

import "./style/app.scss"
import "react-loading-skeleton/dist/skeleton.css"

dayjs.locale("fr")

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
    <DynamicStrictMode>
        <DidomiDataLayerWrapper>
            <SkeletonTheme borderRadius={0}>
                <ReactTooltip.Provider
                    delayDuration={50}>
                    <Provider store={store}>
                        <CookieProvider>
                            <ThemeProvider>
                                <IntercomProvider>
                                    <AssetProvider>
                                        <TrackerProvider>
                                            <Router/>
                                        </TrackerProvider>
                                    </AssetProvider>
                                </IntercomProvider>
                            </ThemeProvider>
                        </CookieProvider>
                    </Provider>
                </ReactTooltip.Provider>
            </SkeletonTheme>
        </DidomiDataLayerWrapper>
    </DynamicStrictMode>,
)

store.dispatch(loadUrlQueryParameters(history.location.search))
handleRedirections(store, history)
