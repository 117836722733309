import { bool, number, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useParams } from "react-router-dom"

import { getBirthdayRegistration } from "./api"
import StepsManager from "../../../components/stepsManagement/StepManager"
import StepsContainer from "../../../components/stepsManagement/StepsContainer"
import useQueryParametersObject from "../../../hooks/routing/useQueryParametersObject.js"
import { setFormEntry, updateBirthdayFormEntry } from "../../../store/forms/actions"
import { goToStep } from "../../../store/stepsManagement/actions"
import { resetNativeHistoryToCurrentLocation } from "../../../utilities/helpers"
import ChooseCenterAndType from "../steps/ChooseCenterAndType"
import MesEnfants from "../steps/MesEnfants"
import { BirthdayProducts } from "./components/BirthdayProduct/BirthdayProducts.jsx"
import Commentaires from "./steps/Commentaires"
import ChoixCreneau from "./steps/CreneauxDispo"
import OrderSummary from "./steps/OrderSummary"
import PersonnaliserAnniv from "./steps/PersonnaliserAnniv"

import "./BirthdayReservation.scss"

function BirthdayReservation({
    noReturn,
    isBirthdayRequestSlotFinalization,
    isPreRegistrationBirthday,
    birthdaySubId,
}) {
    const { initialCenterId, initialTypeId, daywp, monthwp, yearwp } = useParams()
    const { birthdayId } = useQueryParametersObject()
    const [ persistNoReturn, setPersistNoReturn ] = useState(false)
    
    const type = "BIRTHDAY"
    const dispatch = useDispatch()
    
    const location = useLocation()

    if (location.state) {
        birthdaySubId = location.state.birthdaySubId
        isPreRegistrationBirthday = location.state.isPreRegistrationBirthday
        isBirthdayRequestSlotFinalization = location.state.isBirthdayRequestSlotFinalization
        noReturn = location.state.noReturn
    }

    useEffect(() => {
        if (noReturn) {
            setPersistNoReturn(noReturn)
        }
    }, [ noReturn ])

    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
    }

    useEffect(
        () => {
            resetNativeHistoryToCurrentLocation(window.location.href)
            
            if (initialCenterId && initialTypeId) {
                setFormInStore(
                    ChooseCenterAndType.formName, {
                        centerId: initialCenterId,
                        configId: initialTypeId,
                        selectedCenterId: initialCenterId,
                        typeId: initialTypeId,
                    },
                )
                dispatch(
                    goToStep(1),
                )
            } else {
                dispatch(
                    goToStep(0),
                )
            }

            if (birthdayId) {
                birthdaySubId = birthdayId
            }

            console.log(birthdaySubId)

            if (birthdaySubId) {

                getBirthdayRegistration(birthdaySubId).then(
                    (res) => {
                        let sub = res.data.data

                        dispatch(
                            updateBirthdayFormEntry({
                                creneau : {
                                    birthdaySubscription: sub,
                                },
                                ecole: {
                                    centerId: sub.centerId,
                                    configId: sub.birthdayConfig.id,
                                    typeId: sub.birthdayConfig.type.id,
                                },
                                enfant: {
                                    selectedKid: sub.kid,
                                    selectedKidId: sub.kid.id,
                                },
                                [ChoixCreneau.formName]: {
                                    birthdaySubscription: sub,
                                },
                            }),
                        )
                        
                        let selectedOptionWithCategory = []
                        let selectedOptions = []
                        if (sub.optionProducts) {
                            sub.optionProducts.forEach(
                                (el) => {
                                    if (el.category === null) {
                                        selectedOptions.push(el)
                                    }
                                    else {
                                        selectedOptionWithCategory.push(el)
                                    }
                                },
                            )
                        }

                        const data = {
                            selectedOptionWithCategory: selectedOptionWithCategory,
                            selectedOptions: selectedOptions,
                        }

                        if (sub.gifts[0]) {
                            data.selectedGift = sub.gifts[0].product
                            data.selectedGiftSize = sub.gifts[0].sizeValue
                        }

                        if (sub.drinks[0]) {
                            data.selectedDrink = sub.drinks[0]
                        }
                        
                        if (sub.cakes[0]) {
                            data.selectedCake = sub.cakes[0]
                        }

                        dispatch(
                            setFormEntry(
                                PersonnaliserAnniv.formDataName,
                                data,
                            ),
                        )
                        dispatch(
                            goToStep(6),
                        )
                    },
                )
            }
        },[],
    )

    if (isBirthdayRequestSlotFinalization) {
        return (
            <StepsManager customProgressInit={2} customStepCount={6} customCls="birthdaySubscription">
                <PersonnaliserAnniv isBirthdayRequestSlotFinalization={isBirthdayRequestSlotFinalization}/>
                <BirthdayProducts/>
                <Commentaires />
                <OrderSummary isFinalisation={isBirthdayRequestSlotFinalization}/>
            </StepsManager>
        )
    } else if (isPreRegistrationBirthday) {
        return (
            <StepsManager customProgressInit={0} customStepCount={1}>
                <OrderSummary isPreregistration={isPreRegistrationBirthday}/>
            </StepsManager>
        )
    }

    return (
        <StepsManager customCls="birthdaySubscription">
            <StepsContainer stepsToShow='3'>
                <ChooseCenterAndType tunnelType={type} noReturn={persistNoReturn} initialCenterId={initialCenterId} initialTypeId={initialTypeId} />
                <MesEnfants tunnelType={type}/>
                <ChoixCreneau daywp={daywp} monthwp={monthwp} yearwp={yearwp}/>
            </StepsContainer>
            <PersonnaliserAnniv/>
            <BirthdayProducts />
            <Commentaires />
            <OrderSummary />
        </StepsManager>
    )
}

BirthdayReservation.propTypes = {
    birthdaySubId: number,
    daywp: number,
    denyReturn: bool,
    initialCenterId: oneOfType([ number, string ]),
    initialTypeId: oneOfType([ number, string ]),
    isBirthdayRequestSlotFinalization: bool,
    isPreRegistrationBirthday: bool,
    monthwp: number,
    noReturn: bool,
    yearwp: number,
}

export default BirthdayReservation
