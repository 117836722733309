import { _apiCall } from "../../backend/axios"
import { loadUser } from "../../store/actions/scaffold"
import store from "../../store/store"

export function  modifyUserPicture(file) {
    return _apiCall(
        "modify/profil-picture",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        file,
    )
}

export function editUser(user) {
    Object.keys(user).forEach(key => {
        if (Array.isArray(user[key])) {
            user[key] = null
        }
    })

    return _apiCall(
        "modify/user",
        "POST",
        {
            "Content-Type": "application/json",
        },
        user,
    )
}

export function modifyPassword(oldPassword, password, theme) {
    return _apiCall(
        "replace/change-password",
        "PUT",
        {
            "Content-Type" : "application/json",
            activity: theme,
        },
        {
            oldPassword: oldPassword,
            password: password,
        },
    )
}

export function deleteAccount() {
    return _apiCall(
        "delete/user",
        "DELETE",
    )
}

export function modifyFavoriteUniverse(user, universe) {
    // in OMZO
    // favoriteUniverse = 0 -> soccer
    // favoriteUniverse = 1 -> padel

    let favoriteUniverse
    switch (universe) {
    case "theme-soccer":
    case "theme-business":
        favoriteUniverse = 0
        break
    case "theme-padel":
        favoriteUniverse = 1
        break
    default:
        favoriteUniverse = universe
    }

    Object.keys(user).forEach(key => {
        if (Array.isArray(user[key])) {
            user[key] = null
        }
    })

    return _apiCall(
        "modify/user",
        "POST",
        {
            "Content-Type": "application/json",
        },
        {
            ...user,
            favoriteUniverse,
        },
    ).then(
        (response) => {
            if (response?.data?.data.id) {
                store.dispatch(
                    loadUser(response.data),
                )
            }

            switch (response.data.data.favoriteUniverse) {
            case 0:
            default:
                return "theme-soccer"
            case 1:
                return "theme-padel"
            }
        },
    ).catch((err) => {
        return err
    })
}

export function resetFavoriteUniverse(user) {
    Object.keys(user).forEach(key => {
        if (Array.isArray(user[key])) {
            user[key] = null
        }
    })

    return _apiCall(
        "modify/user",
        "POST",
        {
            "Content-Type": "application/json",
        },
        {
            ...user,
            favoriteUniverse: null,
        },
    ).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
