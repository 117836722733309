import dayjs from "dayjs"

export const getActivitiesForRessourcesTypesFavoriteSlot = (resourcesTypesArray) => {
    if (!resourcesTypesArray?.length) {return []}

    const updatedTypes = resourcesTypesArray.map(type => ({
        ...type,
        value: correspondingActivities.find(c => c.id === type.key)?.name ?? type.name,
    }))

    const typesWithValues = updatedTypes.filter(type => type.value)

    return typesWithValues.reduce((acc, type) => {
        if (type.categories?.length && !acc.find(item => item.name === type.value)) {
            acc.push({ id: type.key, name: type.value })
        }
        return acc
    }, [])
}

export const findCorrespondingIds = (id) => {
    const activityName = correspondingActivities.find(activity => activity.id === id)?.name
    return correspondingActivities
        .filter(activity => activity.name === activityName)
        .map(activity => activity.id)
}

export function filterSlotsByActivity(slots, activity) {
    return slots?.filter(slot => slot.activityId === activity)
}

export function hydrateSlots(slots, centers) {
    return slots.map(slot => {
        const center = centers.find(c => c[0] === slot.centerId)

        const activityName = correspondingActivities.find(activity => activity.id === slot.resourceTypes[0])?.name ?? "N/A"
        return {
            ...slot,
            center: center,
            fieldName: activityName,
        }
    })
}

export function getTypeIdsFromCategoryId(categoryId, centers) {
    let typeIds = []
    centers.forEach(center => {
        center.types.forEach(type => {
            if (type.categories.some(category => category.id === categoryId)) {
                typeIds.push(type.key)
            }
        })
    })
    return typeIds
}

export function getCategoryIdFromTypesIds(center, typesIds) {
    const category = center?.types?.find(type => typesIds.includes(type.key))?.categories[0]
    return { id : category?.id, name: category?.name }
}

export function handleNextSlotDate(slot) {
    const today = dayjs()
    let dayOfWeek = today.day()
    dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek
    const currentHour = today.hour()

    const slotDay = slot.day

    // Check if the current time is less than one hour before the slot ends
    if (dayOfWeek === slotDay && currentHour < (slot.end < slot.start ? (slot.start + 2)  : slot.end) - 1) {
        return today.toDate()
    }

    let daysToAdd = slotDay - dayOfWeek
    if (daysToAdd <= 0) {
        daysToAdd += 7
    }

    return today.add(daysToAdd, "day").toDate()
}

export function getSlotSearchUrl(path, slot) {
    const { centerId, start, resourceTypes, id } = slot
    const activity = getCategoryIdFromTypesIds(slot.center, slot.resourceTypes) ?? null
    const date = dayjs(handleNextSlotDate(slot)).format("YYYY-MM-DD")
    const types = resourceTypes.join(",")

    return `${path}?date=${date}&centerId=${centerId}&activity=${activity.id}&time=${start}&slot=${id}&typeresource=${types}`
}

export function getValues(data, user) {
    const checkedValues = Object.entries(data.notifications)
        .filter(([ , value ]) => value === true)
        .map(([ key ]) => parseInt(key))

    const shouldEnableSlot = checkedValues.length > 0

    return {
        centerId: parseInt(data.center),
        contactId: user?.contactId,
        day: parseInt(data.day),
        notificationSource: JSON.stringify(checkedValues),
        resourceTypes: JSON.stringify(findCorrespondingIds(parseInt(data.type)) ?? []),
        start: parseInt(data.hour?.value),
        status: shouldEnableSlot ? 0 : data.status,
    }
}

export const correspondingActivities = [
    {
        id: 0,
        name: "Foot à 5 - Intérieur",
    },
    {
        id: 1,
        name: "Foot à 5 - Extérieur",
    },
    {
        id: 10,
        name: "Foot à 5 - Extérieur",
    },
    {
        id: 11,
        name: "Squash",
    },
    {
        id: 12,
        name: "Padel - Intérieur",
    },
    {
        id: 13,
        name: "Foot à 7 - Extérieur",
    },
    {
        id: 14,
        name: "Padel - Intérieur",
    },
    {
        id: 15,
        name: "Padel - Extérieur",
    },
    {
        id: 5,
        name: "Tennis",
    },
    {
        id: 8,
        name: "Foot à 5 - Intérieur",
    },
    {
        id: 9,
        name: "Badminton",
    },
    {
        id: 16,
        name: "Pickleball - Intérieur",
    },
    {
        id: 17,
        name: "Pickleball - Intérieur - Filmé",
    },
    {
        id:18,
        name: "Pickleball - Extérieur",
    },
    {
        id: 19,
        name: "Padel Single - Intérieur",
    },
    {
        id: 20,
        name: "Padel Single - Intérieur - Filmé",
    },
    {
        id: 21,
        name: "Padel Single - Extérieur",
    },
    {
        id: 24,
        name: "Padbol - Intérieur",
    },
]
