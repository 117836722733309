import classNames from "classnames"
import dayjs from "dayjs"
import { number, object, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector, useStore } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import useIsPaymentEnabled from "./hooks/useIsPaymentEnabled"
import useTunnelTypeFormName from "./hooks/useTunnelTypeFormName"
import cancelPreRegistration from "./services/cancelPreregistration"
import getListingUrl from "./services/getListingUrl"
import getReturnText from "./services/getReturnText"
import preregister from "./services/preregister"
import ButtonCta from "../../../components/Buttons/button/button-cta"
import ActivationTrigger from "../../../components/Buttons/Triggers/ActivationTrigger"
import CurrencyDisplay from "../../../components/currencies/CurrencyDisplay"
import { TextInput } from "../../../components/Inputs/TextInput/TextInput"
import Preloader from "../../../components/loaders/preloader/preloader"
import CbAliasError from "../../../components/popin/CbAliasError/CbAliasError"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import PayFreeAmount from "../../../components/reservation/PayFreeAmount/PayFreeAmount"
import { ShopStep } from "../../../components/reservation/Shop/New/ShopStep/ShopStep.jsx"
import SummaryCartEquimentLine
    from "../../../components/reservation/Shop/SummaryCartEquimentLine/SummaryCartEquimentLine.jsx"
import Step from "../../../components/stepsManagement/Step"
import { useTheme } from "../../../components/ThemeContext/ThemeContext"
import HourDisplay from "../../../components/Time/HourDisplay"
import Tooltip from "../../../components/tooltip/Tooltip"
import AvoirCheckbox from "../../../entries/main/contents/main/helpers/AvoirCheckbox"
import { deleteCreditCard, getCreditCard } from "../../../globalAPI/api"
import useAddDiscountCode from "../../../hooks/reservation/useAddDiscountCode"
import useProceedCheckout from "../../../hooks/reservation/useProceedCheckout"
import useRemoveDiscountCode from "../../../hooks/reservation/useRemoveDiscountCode"
import useUrbanLanguage from "../../../hooks/urbanLanguage/useUrbanLanguage"
import { useDidomiCustomEvent, useDidomiEcommerceEvent } from "../../../hooks/useDidomiDataLayer.js"
import useMediaPath from "../../../hooks/useMediaPath"
import useResetScroll from "../../../hooks/useResetScroll"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import dayValues from "../../../model/enums/dayValues"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import usePath from "../../../routes/services/usePath"
import {
    buildDidomiEcommerceData,
    getDevice,
    getLang, getUserData,
} from "../../../services/didomiService.js"
import formatTwoDigits from "../../../services/formatTwoDigits"
import UserAgentHandler from "../../../services/UserAgentHandler"
import { setFormEntry } from "../../../store/forms/actions"
import CreneauxDispo from "../../kids/ecoleDeFoot/steps/CreneauxDispo"
import { addStageOption, deleteStageOption, getKidsStageDetail } from "../../kids/stageDeFoot/api"
import ChooseCenterAndType from "../../kids/steps/ChooseCenterAndType"
import MesEnfants from "../../kids/steps/MesEnfants"
import { InsurancePopIn } from "../../kids/steps/OrderSummary"
import { addLeagueOption, deleteLeagueOption } from "../../league/api/api.js"
import { addCupOption, removeCupOption } from "../../teams/urbanCup/api"

import "./OrderSummary.scss"

function OrderSummary(props) {
    const [ addToCartTriggered, setAddToCartTriggered ] = useState(false)
    let { title, tunnelType, supersubRequest, customCls, supersubApplyMessage, responseID, backToStep } = props

    useResetScroll()
    // ONLY USED BY SDF & CUP AT THE MOMENT (&& Urbanleague && supersub now)
    const store = useStore()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const themeFeatures = useThemeFeatures()
    const mediaPath = useMediaPath()
    const theme = useTheme()
    const path = usePath()
    const { tu } = useUrbanLanguage()

    const help = <PhoneCallHelp tunnelType={tunnelType}/>

    const cgvUrl = themeFeatures([ "https://www.urbansoccer.fr/cgv-urbansoccer/","https://www.urbanpadel.fr/mentions-legales/cgv-urbanpadel/ " ])

    const userLoaded = useSelector(state => state.userLoaded)

    const promoCodeInput        = useRef(null)
    const step                  = useRef(null)

    // ## Store Values
    const forms = useSelector(state => state.forms)
    const tournamentRegistration = useSelector(state => state.forms.TournamentRegistration)
    const ozmoSubscription = useSelector(state => state.forms.ozmoSubscription)
    const optionsProducts = useSelector(state => state.forms.optionsProducts)
    const avoirFormData = useSelector(state => state.forms.avoirFormData)
    const supersubForm = useSelector(state => state.forms["supersubForm"])
    const { trackEcommerceEvent } = useDidomiEcommerceEvent()
    const trackEvent = useDidomiCustomEvent()

    // Props from navigate state
    const { state } = useLocation()
    if (state) {
        title = state.title ?? title
        tunnelType = state.tunnelType ?? tunnelType
        supersubRequest = state.supersubRequest ?? null
        customCls = state.customCls ?? supersubRequest
        supersubApplyMessage = state.supersubApplyMessage ?? supersubApplyMessage
        responseID = state.responseID ?? responseID

        if (state.cupRegistration && !tournamentRegistration) {
            dispatch(
                setFormEntry(
                    "TournamentRegistration",
                    state.cupRegistration,
                ),
            )
        }
    }

    // ## State
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ codeError, setCodeError ] = useState(false)
    const [ promoCode, setPromoCode ] = useState("")
    const [ availableDiscountCodes, setAvailableDiscountCodes ] = useState([])
    const [ cbCard, setCbCard ] = useState(null)
    const [ isUsingUserCB, setIsUsingUserCB ] = useState(false)
    const [ isUsingUserCB2, setIsUsingUserCB2 ] = useState(false)
    const [ paymentEnabled, setPaymentEnabled ] = useState(false)
    const [ pictureUrl, setPictureUrl ] = useState("/assets/images/placeholder-team.svg")
    const [ orderTitle, setOrderTitle ] = useState("Prénom Nom")
    const [ registrationType, setRegistrationType ] = useState()
    const [ centerName, setCenterName ] = useState("Registration center")
    const [ timeDisplay, setTimeDisplay ] = useState(null)
    const [ firstRegistrationPrice, setFirstRegistrationPrice ] = useState(0)
    const [ isPreregistration, setIsPreregistration ] = useState(false)
    const [ representantLegalChecked, setRepresentantLegalChecked ] = useState(false)
    const [ showAcceptCGV, setShowAcceptCGV ] = useState(false)
    const [ firstSubscription, setFirstSubscription ] = useState(null)
    const [ cartPriceDetailsContent, setCartPriceDetailsContent ] = useState(<></>)
    const [ cartLines, setCartLines ] = useState([])
    const [ optionsAtPayment, setOptionsAtPayment ] = useState([])
    const [ disableProductDeletion, setDisableProductDeletion ] = useState(false)
    const [ amountTotal, setAmountTotal ] = useState(0)
    const [ promoCodeDiscount, setPromoCodeDiscount ] = useState(0)
    const [ totalTVA, setTotalTVA ] = useState(0)
    const [ isPayFreeAmountPopInOpen, setIsPayFreeAmountPopInOpen ] = useState(false)
    const [ preregistrationEnabled, setPreregistrationEnabled ] = useState(false)
    const [ preRegisterLimitDays, setPreRegisterLimitDays ] = useState("5")
    const [ firstSubHasDiscountCode, setFirstSubHasDiscountCode ] = useState(false)
    const [ subOptionsIds, setSubOptionsIds ] = useState([])
    const [ equipmentSubTotal, setEquipmentSubTotal ] = useState(0)
    const [ equipmentDiscountOnSubTotal, setEquipmentDiscountOnSubTotal ] = useState(0)
    const [ cartOrderDetailsTitle, setCartOrderDetailsTitle ] = useState("")
    const [ shopName, setShopName ] = useState("")
    const [ saveCard, setSaveCard ] = useState(false)
    const [ allowPaymentSpread, setAllowPaymentSpread ] = useState(false)

    // ## Hooks
    const addCodeToSub = useAddDiscountCode(tunnelType)
    const deleteCodeFromSub = useRemoveDiscountCode(tunnelType)

    const isPaymentEnabled = useIsPaymentEnabled(tunnelType, paymentEnabled, representantLegalChecked)
    const formName = useTunnelTypeFormName(tunnelType)

    const {
        proceedCheckout,
        withMultiPayments,
    } = useProceedCheckout(
        {
            askRegisterPay: isUsingUserCB2,
            customData: tunnelType === "SUPERSUB" ?
                {
                    supersubApplyMessage: supersubApplyMessage ?? supersubRequest.description,
                }
                :
                null,
            firstSubscription: firstSubscription,
            isPreregistration: isPreregistration,
            isUsingUserCB: isUsingUserCB,
            lyraPaymentUrl: path(`/order/payment/${tunnelType.toLowerCase()}/${firstSubscription?.id}`),
            responseId: responseID,
            setAjaxLoading: setAjaxLoading,
            setPayFreeAmountPopInOpen: setIsPayFreeAmountPopInOpen,
            tunnelType: tunnelType,
        },
    )

    // use initialize order summary for tunnelType
    useEffect(
        () => {
            let newAvailableDiscountCode
            let catalogArticles = []
            let registrationOptionProducts = []
            let availableOptionProducts = []
            let cartDOM = []
            let optionsDOM = []
            let equipmentSubTotal = 0

            switch (tunnelType) {
            case "CUP":
                if (tournamentRegistration) {
                    const {
                        amountTTC,
                        amountHT,
                    } = tournamentRegistration.proforma ? tournamentRegistration.proforma : {}
                    const cup = tournamentRegistration.cup
                    newAvailableDiscountCode = tournamentRegistration.discountCodes
                    setAmountTotal(amountTTC)
                    setTotalTVA(amountTTC - amountHT)
                    setCenterName(tournamentRegistration.centerName)
                    if (cup.pictureUrl) {
                        setPictureUrl(cup.pictureUrl)
                    }
                    setOrderTitle(cup.name)
                    setFirstSubscription(tournamentRegistration)
                    setIsPreregistration(tournamentRegistration.statusValue === 2)
                    if (!paymentEnabled) {
                        setPaymentEnabled(tournamentRegistration.statusValue === 2)
                    }
                    setFirstRegistrationPrice(cup.amount)
                    let cupStartDate
                    if (cup.start.includes("T")) {
                        cupStartDate = new Date(cup.start)
                    } else {
                        let temp = cup.start.split("/")
                        cupStartDate = new Date(temp[2], +temp[1] - 1, temp[0])
                    }

                    if (tournamentRegistration?.id && !addToCartTriggered) {
                        trackEcommerceEvent("product.add_to_cart", buildDidomiEcommerceData(tournamentRegistration, tunnelType, themeFeatures))
                    }

                    setTimeDisplay(
                        <React.Fragment>
                            {
                                dayValues[cupStartDate.getDay()] + " " +
                                    formatTwoDigits(cupStartDate.getDate()) + "/" +
                                    formatTwoDigits(cupStartDate.getMonth() + 1) + "/" +
                                    cupStartDate.getFullYear() + " - "
                            }
                            <HourDisplay
                                minutes={cup.hour}
                                displayZero={true}
                            />
                            <br/>
                            {tournamentRegistration.teamName}
                        </React.Fragment>,
                    )
                    setDisableProductDeletion(tournamentRegistration.statusValue === 2)
                    setPreregistrationEnabled(cup.preregistrationEnabled)
                    setPreRegisterLimitDays(cup.preRegisterDuration)

                    registrationOptionProducts = tournamentRegistration.optionProducts
                    availableOptionProducts = tournamentRegistration.cup.optionProducts
                    catalogArticles = tournamentRegistration.catalogArticles

                    setEquipmentDiscountOnSubTotal(tournamentRegistration.catalogDiscountAmount)
                    setCartOrderDetailsTitle("Mes Équipements")
                    setShopName("Équipement")
                }
                break

            case "TRAINING":
                if (ozmoSubscription) {
                    let stageSub = ozmoSubscription.firstSubscription
                    const kid = stageSub.kid
                    const { amountTTC, amountHT } = stageSub.proforma || {}
                    const training = stageSub.training
                    newAvailableDiscountCode = stageSub.discountCodes
                    setAmountTotal(amountTTC)
                    setTotalTVA(amountTTC - amountHT)
                    setCenterName(`${training.centerName}`)
                    if (training.pictureUrl) {
                        setPictureUrl(training.pictureUrl)
                    }
                    setOrderTitle(kid ? `${kid?.firstName ?? userLoaded?.firstname} ${kid?.lastName ?? userLoaded?.lastname}` : `${training?.type} - ${training?.centerName}`)
                    setFirstSubscription(stageSub)
                    setIsPreregistration(stageSub.statusValue === 2)
                    if (!paymentEnabled) {
                        setPaymentEnabled(stageSub.statusValue === 2)
                    }

                    if (!representantLegalChecked && kid) {
                        setRepresentantLegalChecked(stageSub.statusValue === 2)
                    } else {
                        setRepresentantLegalChecked(true)
                    }

                    setFirstRegistrationPrice(training.amount)
                    setDisableProductDeletion(stageSub.statusValue === 2)

                    setTimeDisplay(
                        kid && (
                            <React.Fragment>
                                {training?.type} - {training?.centerName}
                            </React.Fragment>
                        ),
                    )
                    setPreregistrationEnabled(training.preregistrationEnabled)
                    setPreRegisterLimitDays(training.preRegisterDuration)
                    setRegistrationType(dayjs(training?.start)?.format("dddd ") + training.startDate + " - " + training.endDate)

                    registrationOptionProducts = stageSub.optionProducts
                    if (optionsProducts) {
                        availableOptionProducts = optionsProducts
                    } else {
                        setAjaxLoading(true)
                        getKidsStageDetail(training.id).then(
                            (stage) => {
                                stage = stage.data.data
                                availableOptionProducts = stage.optionProducts
                                dispatch(setFormEntry(
                                    "hasOption",
                                    stage.optionProducts.length !== 0,
                                ))
                                dispatch(setFormEntry(
                                    "optionsProducts",
                                    stage.optionProducts,
                                ))
                                dispatch(setFormEntry(
                                    "hasCatalogProducts",
                                    stage.catalogProducts.length !== 0,
                                ))
                                dispatch(
                                    setFormEntry(
                                        ShopStep.formDataName,
                                        {
                                            availableEquipment: stage.catalogProducts,
                                        },
                                    ),
                                )
                                dispatch(
                                    setFormEntry(
                                        ChooseCenterAndType.formName,
                                        {
                                            centerId: stage.centerId,
                                            selectedCenterId: stage.centerId,
                                            typeId: stage.typeId,
                                        },
                                    ),
                                )
                                dispatch(
                                    setFormEntry(
                                        MesEnfants.formName,
                                        {
                                            selectedKid: {
                                                ...stageSub.kid,
                                                equipment: stageSub.outfitSizeValue,
                                                position: stageSub.positionValue,
                                            },
                                        },
                                    ),
                                )

                                const formCreneau = store.getState().forms[CreneauxDispo.formName]

                                dispatch(
                                    setFormEntry(
                                        CreneauxDispo.formName,
                                        {
                                            ...formCreneau,
                                            selectedTimeslot: stage,
                                            selectedTimeslotValue: stage.id,
                                        },
                                    ),
                                )

                                setAjaxLoading(false)
                            },
                        )
                    }
                    catalogArticles = stageSub.catalogArticles

                    setEquipmentDiscountOnSubTotal(stageSub.catalogDiscountAmount)
                    setCartOrderDetailsTitle("Mes Équipements")
                    setShopName("Équipement")

                    setFirstSubHasDiscountCode(stageSub.discountCodes.length > 0)
                }
                break

            case "LEAGUE":
                if (tournamentRegistration) {
                    const {
                        amountTTC,
                        amountHT,
                    } = tournamentRegistration.proforma ? tournamentRegistration.proforma : {}
                    const league = tournamentRegistration.league
                    newAvailableDiscountCode = tournamentRegistration.discountCodes
                    setAmountTotal(amountTTC)
                    setTotalTVA(amountTTC - amountHT)
                    setCenterName(tournamentRegistration.centerName)
                    setAllowPaymentSpread(league.allowPaymentSpread)

                    setPictureUrl(null)

                    setOrderTitle(league.name)
                    setFirstSubscription(tournamentRegistration)
                    setIsPreregistration(tournamentRegistration.statusValue === 2)
                    if (!paymentEnabled) {
                        setPaymentEnabled(tournamentRegistration.statusValue === 2)
                    }
                    setFirstRegistrationPrice(league.amount)
                    let leagueStartDate
                    if (tournamentRegistration.league.start.includes("T")) {
                        leagueStartDate = new Date(tournamentRegistration.league.start)
                    } else {
                        let temp = tournamentRegistration.league.start.split("/");
                        [ temp[0], temp[1] ] = [ temp[1], temp[0] ]
                        temp = temp.join("/")
                        leagueStartDate = new Date(temp)
                    }

                    if (tournamentRegistration?.id && !addToCartTriggered) {
                        trackEcommerceEvent("product.add_to_cart", buildDidomiEcommerceData(tournamentRegistration, tunnelType, themeFeatures, {
                            "product_category4": leagueStartDate ? dayjs(leagueStartDate).format("ddd DD/MM - HH[h]mm") : "",
                        }))
                    }

                    setTimeDisplay(
                        <React.Fragment>
                            <span className="wrapper">
                                <span className="start"><img src="/assets/icons/calendar-orange.svg"/>Début : </span>
                                <span className="date">
                                    {
                                        dayValues[leagueStartDate.getDay()] + " " +
                                            formatTwoDigits(leagueStartDate.getDate()) + "/" +
                                            formatTwoDigits(leagueStartDate.getMonth() + 1) + "/" +
                                            leagueStartDate.getFullYear()
                                    }
                                </span>
                            </span>
                            <span className="wrapper">
                                <span className="teamDesc"><img src="/assets/icons/jersey-orange.svg"/>Mon équipe : </span>
                                <span className="teamName">{tournamentRegistration.teamName}</span>
                            </span>
                        </React.Fragment>,
                    )
                    setDisableProductDeletion(tournamentRegistration.statusValue === 2)
                    setPreregistrationEnabled(league.preregistrationEnabled)
                    setPreRegisterLimitDays(league.preRegisterDuration)

                    registrationOptionProducts = tournamentRegistration.optionProducts
                    availableOptionProducts = tournamentRegistration.league.optionProducts
                    catalogArticles = tournamentRegistration.catalogArticles

                    setEquipmentDiscountOnSubTotal(tournamentRegistration.catalogDiscountAmount)
                    setCartOrderDetailsTitle("Mes Équipements")
                    setShopName("Équipement")
                }
                break

            case "SUPERSUB":
                if (supersubRequest) {
                    const subReponse = supersubForm ? supersubForm.response : null
                    const { price, isFree, organizer } = supersubRequest
                    const { centerName, resourceTypeDisplay, start, end, amountDue } = supersubRequest.reservation
                    const startDate = new Date(start)
                    const endDate = new Date(end)
                    const dayOfDaWeek = dayValues[startDate.getDay()]
                    const startHour = <HourDisplay date={startDate}/>
                    const endHour = <HourDisplay date={endDate}/>
                    let priceToPay = (
                        userLoaded &&
                                (userLoaded.contactId === supersubRequest.organizer.id) &&
                                amountDue > 0
                    ) ?
                        price
                        :
                        (isFree || amountDue === 0) ?
                            0
                            :
                            price

                    if (supersubRequest.reservation?.id && !addToCartTriggered) {
                        trackEcommerceEvent("product.add_to_cart", buildDidomiEcommerceData(supersubRequest?.reservation, tunnelType, themeFeatures))
                    }
                    
                    setFirstSubscription(supersubRequest)
                    setCenterName(centerName + " - " + resourceTypeDisplay)
                    setOrderTitle(
                        subReponse ?
                            subReponse.contact.firstname + " " + subReponse.contact.lastname.substring(0, 1) + "."
                            : organizer ?
                                organizer.firstname + " " + organizer.lastname.substring(0, 1) + "."
                                : "")
                    setPictureUrl(supersubRequest.organizer.pictureUrl !== null ? supersubRequest.organizer.pictureUrl : "/assets/images/empty-avatar.svg")

                    priceToPay = supersubRequest.price <= amountDue ? supersubRequest.price : amountDue

                    setFirstRegistrationPrice(priceToPay)
                    setTimeDisplay(<>
                        {dayOfDaWeek.substring(0, 3)} {
                            formatTwoDigits(
                                startDate.getDate(),
                            ) + "/" +
                            formatTwoDigits(
                                startDate.getMonth() + 1,
                            )
                        } : {startHour} - {endHour}
                    </>)
                    setAmountTotal(priceToPay)
                    setTotalTVA(
                        (userLoaded && (userLoaded.contactId === supersubRequest.organizer.id)) ?
                            priceToPay * 20 / 120
                            :
                            isFree ?
                                0
                                :
                                priceToPay * 20 / 120,
                    )
                }

                break

            default:
                // eslint-disable-next-line no-console
                console.error("UNKNOWN TUNNEL TYPE OR NOT IMPLEMENTED : %o", tunnelType)
                // eslint-disable-next-line no-debugger
                debugger
                break
            }

            // handle total amount with avoir
            if (avoirFormData?.totalWithAvoir) {
                const amountTTCAvoir = avoirFormData.totalWithAvoir
                setAmountTotal(amountTTCAvoir)
            }

            // handle tva amount with avoir
            if (avoirFormData?.tvaWithAvoir) {
                const cupTVAAvoir = avoirFormData.tvaWithAvoir
                setTotalTVA(cupTVAAvoir)
            }

            catalogArticles.forEach(
                (equipment) => {
                    equipmentSubTotal += Number.parseInt(equipment.quantity) * Number.parseFloat(equipment.product.price)
                    cartDOM.push(
                        <li key={`product--${equipment.id}`}>
                            <SummaryCartEquimentLine
                                blackEquipmentCross
                                productOrder={equipment}
                                productInfos={equipment.product}
                                disableDeletion={disableProductDeletion}
                                tunnelType={tunnelType}
                            />
                        </li>,
                    )
                },
            )

            let newSubOptionsIds = []
            registrationOptionProducts.forEach(
                (option) => {
                    newSubOptionsIds.push(option.id)
                    optionsDOM.push(
                        <li key={option.id}>
                            <div className="info">{(tunnelType === "TRAINING" ? "Option : " : "") +  option.name}</div>
                            <div className="price">
                                <CurrencyDisplay price={option.price}/>
                            </div>
                        </li>,
                    )
                },
            )
            setSubOptionsIds(newSubOptionsIds)

            let optAtPayment = []
            availableOptionProducts.forEach(
                (option) => {
                    if (option.showAtPayment) {
                        optAtPayment.push(option)
                    }
                },
            )
            setOptionsAtPayment(optAtPayment)

            setCartPriceDetailsContent(optionsDOM)
            setEquipmentSubTotal(equipmentSubTotal)
            setCartLines(cartDOM)

            setAvailableDiscountCodes(newAvailableDiscountCode)
            if (newAvailableDiscountCode?.length > 0) {
                newAvailableDiscountCode.forEach(
                    (code) => {
                        if (!code.secondRegistrationDiscount) {
                            setPromoCode(code.code)
                            setPromoCodeDiscount(code.amount)
                        }
                    },
                )
            } else {
                setPromoCodeDiscount(0)
            }

            setAddToCartTriggered(true)

        }, [ tournamentRegistration, ozmoSubscription, forms ],
    )

    useEffect(
        () => {
            if (firstSubscription) {
                let {
                    amountTTC,
                    amountHT,
                } = firstSubscription?.proforma ? firstSubscription.proforma : {}
                let cupTVA = amountTTC - amountHT

                // handle total amount with avoir
                if (avoirFormData?.totalWithAvoir !== undefined) {
                    amountTTC = avoirFormData.totalWithAvoir
                }

                // handle tva amount with avoir
                if (avoirFormData?.tvaWithAvoir !== undefined) {
                    cupTVA = avoirFormData.tvaWithAvoir
                }

                if (amountTTC !== undefined) {
                    setAmountTotal(amountTTC)
                }
                if (cupTVA || cupTVA === 0) {
                    setTotalTVA(cupTVA)
                }
            }
        }, [ avoirFormData, firstSubscription ],
    )

    useEffect(() => {
        setAjaxLoading(true)
        getCreditCard().then(
            (result) => {
                setAjaxLoading(false)
                if (result?.data?.data?.tokenDetails) {
                    setCbCard(result.data.data)
                }
                if (result?.data?.data?.errorCode) {
                    deleteCreditCard()
                    ModalHandler.show(CbAliasError)
                }
            },
        )

        const userData = getUserData(userLoaded)

        let eventData = {
            "env_device": getDevice(),
            "env_language": getLang(),
            "env_template": "default",
            "env_work": window.location.hostname === "myurban.fr" ? "prod" : "preprod",
            "event": "dataLayer_ready",
        }

        eventData["page_chapter1"] = "order_summary"
        eventData["page_chapter2"] = (tunnelType || "").toLowerCase()
        eventData["page_chapter3"] = "undefined"
        eventData["page_name"] = document.title
        eventData["booking_funnel_type"] = themeFeatures([ "soccer_", "padel_" ]) + (tunnelType || "").toLowerCase()

        eventData = {
            ...eventData,
            ...userData,
        }

        trackEvent(eventData)

    }, [])

    const isResultOK = (res) => {
        if (!res || res?.Message) {return false}

        return (
            res?.data?.data?.subscription !== null
        ) && (
            res?.data?.data?.message === "" ||
            res?.data?.data?.message === undefined
        )
    }

    const addCode = () => {
        if (promoCode) {
            if (availableDiscountCodes) {
                availableDiscountCodes.forEach(
                    discountCode => {
                        if (discountCode.secondRegistrationDiscount) {
                            if (promoCode === discountCode.code) {
                                setCodeError(true)
                                setPromoCode("")
                                return
                            }
                        }
                    },
                )
            }
            addCodeToSub(firstSubscription.id, promoCode.toUpperCase()).then(
                (res) => {
                    if (isResultOK(res)) {
                        handleRegistrationUpdateResult(res)
                        setCodeError(false)
                    } else {
                        setCodeError(true)
                    }
                    setAjaxLoading(false)
                }, () => {
                    setCodeError(true)
                    setAjaxLoading(false)
                },
            ).catch(() => {
                setCodeError(true)
                setAjaxLoading(false)
            })
        } else {
            setCodeError(true)
            setAjaxLoading(false)
        }

    }

    function addPromoCode() {
        setAjaxLoading(true)

        if (firstSubscription?.discountCodes?.length > 0) {
            let hasNoOldCode = true
            firstSubscription.discountCodes.forEach(
                code => {
                    if (!code.secondRegistrationDiscount) {
                        hasNoOldCode = false

                        deleteCodeFromSub(firstSubscription.id, code.code).then(
                            (res) => {
                                handleRegistrationUpdateResult(res)
                                addCode()
                            },
                        )
                    }
                },
            )
            if (hasNoOldCode) {
                addCode()
            }
        } else {
            addCode()
        }
    }

    function deleteDiscountCode() {
        setAjaxLoading(true)
        deleteCodeFromSub(
            firstSubscription.id,
            promoCode,
        ).then(handleRegistrationUpdateResult)
    }

    function handleCGV(e) {
        setPaymentEnabled(e.target.checked)
    }

    function handleRepresentantLegal(e) {
        setRepresentantLegalChecked(e.target.checked)
    }

    function handleRegistrationUpdateResult(res) {
        let data
        switch (tunnelType) {
        case "CUP":
            data = res.data.data.subscription ?
                res.data.data.subscription
                :
                res.data.data
            setFirstSubHasDiscountCode(data.discountCodes.length > 0)
            break
        case "TRAINING":
            if (
                !res.data.data &&
                    (res.data.data && res.data.data.subscription === null)
            ) {
                data = {
                    ...forms[formName],
                }
            } else {
                data = {
                    firstSubscription: res.data.data.subscription ?
                        res.data.data.subscription
                        :
                        res.data.data,
                }
            }
            setFirstSubHasDiscountCode(data.firstSubscription.discountCodes.length > 0)
            break
        case "LEAGUE":
            if (res.data.data.subscription) {
                data = res.data.data.subscription
                setFirstSubHasDiscountCode(res.data.data.subscription.discountCodes.length > 0)
            } else {
                data = res.data.data
            }
            break

        default:
            setFirstSubHasDiscountCode(data.discountCodes.length > 0)
        }

        dispatch(
            setFormEntry(
                formName,
                data,
            ),
        )
        dispatch(
            setFormEntry("avoirFormData", {
                ...avoirFormData,
                recalcAvoir: true,
            }))
        setAjaxLoading(false)
    }

    function addOption(event) {
        const input = event.target
        const optionId = input.id.split("-")[1]

        let addOptionMethod
        let removeOptionMethod
        switch (tunnelType) {
        case "CUP":
            addOptionMethod = addCupOption
            removeOptionMethod = removeCupOption
            break

        case "TRAINING":
            addOptionMethod = addStageOption
            removeOptionMethod = deleteStageOption
            break

        case "LEAGUE":
            addOptionMethod = addLeagueOption
            removeOptionMethod = deleteLeagueOption
            break
        }

        setAjaxLoading(true)

        if (input.checked) {
            addOptionMethod(firstSubscription.id, optionId).then(handleRegistrationUpdateResult)
        } else {
            removeOptionMethod(firstSubscription.id, optionId).then(handleRegistrationUpdateResult)
        }
    }

    function useUserCB(e) {
        setIsUsingUserCB(e.target.checked)
        setIsUsingUserCB2(e.target.checked)
    }

    function onReturn() {
        if (forms.customReturn) {
            return navigate(forms.customReturn)
        }
        if (forms?.isAbandonned) {
            if (tunnelType === "TRAINING") {return navigate(themeFeatures([ "/kids/stagesDeFoot/listing", "/padel/stages" ]))}
            else if (tunnelType === "CUP") {return navigate(themeFeatures([ "/cup", "/padel/tournois" ]))}
        }
        if (!isPreregistration && tunnelType !== "SUPERSUB") {
            return undefined
        } else {
            const url = getListingUrl(tunnelType, themeFeatures)
            if (url) {
                navigate(path(url))
            }
            let returnPage
            switch (tunnelType) {
            case "CUP":
                returnPage = "/cup"
                break
            case "TRAINING":
                returnPage = themeFeatures([ "/kids/stagesDeFoot/listing", "/stages" ])
                break
            case "LEAGUE":
                returnPage = "/urbanleague/inscriptionLeague"
                break
            case "SUPERSUB":
                if (userLoaded && firstSubscription && (userLoaded.contactId !== firstSubscription.organizer.id)) {
                    returnPage = "/supersub/findMatch"
                } else {
                    returnPage = "/reservations/locations/" + firstSubscription?.reservation.id + "/candidaturesupersub"
                }
                dispatch(setFormEntry("supersubForm", null))
                dispatch(setFormEntry("candidatureSupersub", null))
                break
            default:
                returnPage = "/supersub/findMatch"
                break
            }
            navigate(path(returnPage))
        }
    }

    let sousTotal = firstSubscription ? amountTotal + promoCodeDiscount : 0

    let isNotSupersub = tunnelType !== "SUPERSUB"

    const cancelPreRegistrationButton = (
        <ButtonCta
            className="buttonCta--w-100 buttonCta--big"
            isCta2
            onClick={() => {
                cancelPreRegistration(
                    tunnelType,
                    setAjaxLoading,
                    firstSubscription,
                    navigate,
                    path,
                    ozmoSubscription?.secondSubscription,
                )
            }}
            text="Annuler"
        />

    )

    const buttonsCount = () => {
        let count = 1
        if (isPreregistration) {count += 1}
        if (allowPaymentSpread && (amountTotal !== 0)) {count += 1}
        if (!isPreregistration && preregistrationEnabled) {count += 1}
        return count
    }
    useEffect(() => {
        setShowAcceptCGV(false)
    }, [ isPaymentEnabled ])

    return (
        <Step
            {...props}
            title={title ?? "Ma commande"}
            help={help}
            helpId={"OrderSummaryHelp"}
            ref={step}
            cls={classNames("orderSummary noMarginBottom arrows-on-sides", {
                [customCls]: customCls,
                "padding-bottom": UserAgentHandler.isAndroid() || UserAgentHandler.isIOS(),
                preregistration: isPreregistration,
                [tunnelType?.toLowerCase()]: tunnelType,
            })}
            returnText={getReturnText()}
            goToPreviousStep={backToStep}
            onReturn={onReturn}
            // dev only
            otherId='last'
            hideNextBtn
        >
            {ajaxLoading && <Preloader fixed/>}
            <div
                className={"formsHolder c-row c-mrg--btm--4 c-mrg--btm--sm--1 orderSummary layoutSmall c-col c-col--10 " + tunnelType}
            >
                <div className="c-col c-col--content c-col--xm--12">
                    <div className="cartPriceDetails">
                        {tunnelType === "LEAGUE" && (<div className="titlePrice">

                            <div className="tunnelType">{tu("league.name")}</div>
                            <CurrencyDisplay customCls="price" price={firstRegistrationPrice}/>
                        </div>)}
                        <div className="cartPriceDetails__heading">
                            {pictureUrl && (
                                <div className="logo">
                                    <img src={pictureUrl} />
                                </div>
                            )}

                            <div className="content">
                                <div className="headline">
                                    <div className={tunnelType === "LEAGUE" ? "name name-league" : "name"}>{orderTitle}</div>
                                    {tunnelType !== "LEAGUE" && (
                                        <div className="price show-sm">
                                            <CurrencyDisplay price={firstRegistrationPrice} />
                                        </div>
                                    )}
                                </div>
                                {timeDisplay &&
                                    (<div className={tunnelType === "LEAGUE" ? "time time-league" : "time"}>
                                        {timeDisplay}
                                    </div>)
                                }
                                <div className={tunnelType === "TRAINING" ? "center centerTraining" : "center"}>
                                    {tunnelType === "LEAGUE" && <span className="centerDesc"><img src="/assets/icons/pin-orange.svg"/>Centre :{" "}</span>}
                                    {registrationType}
                                    {(registrationType && tunnelType !== "TRAINING") && " - "}
                                    {
                                        tunnelType === "LEAGUE" &&
                                        "UrbanSoccer - "
                                    }
                                    {
                                        tunnelType !== "TRAINING" &&
                                            centerName
                                    }
                                </div>
                                {tunnelType === "LEAGUE" && (<div className="minPayment">
                                    <span className="desc">Régler 10% pour finaliser l'inscription</span>
                                    <div className="infoBubble">
                                        <Tooltip
                                            id={"infoBubble-supp-discount-minPayment"}
                                        >
                                            <p>
                                                La facture doit être réglée en intégralité avant le 1er match. Dans le cas contraire, la carte bancaire utilisée lors de l'inscription sera débitée automatiquement du solde restant dû.
                                            </p>
                                        </Tooltip>
                                        <ActivationTrigger id={"buttonInfoDiscount"} target={"#infoBubble-supp-discount-minPayment"}>
                                            <div className="infoBubble__icon">
                                                i
                                            </div>
                                        </ActivationTrigger>
                                    </div>
                                </div>)}

                            </div>
                            {tunnelType !== "LEAGUE" && (<div className="price hide-sm">
                                <CurrencyDisplay price={firstRegistrationPrice}/>
                            </div>)}

                        </div>
                        {tunnelType === "CUP" && optionsAtPayment.length > 0 && (
                            <div className={"cancelContent"}>
                                {dayjs().isAfter(dayjs(tournamentRegistration.proforma.startDate).subtract(21, "days")) ? (
                                    <>
                                        <p>
                                            <img
                                                className="cancelInsurance"
                                                src={mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                            />
                                            Toute inscription est définitive, aucun remboursement, sous aucun motif, ne pourra avoir lieu.
                                            Si tu souhaites te désister :</p>
                                        <ul>
                                            <li>Avec option annulation : avoir valable 12 mois</li>
                                            <li>Sans option annulation : aucun remboursement</li>
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        <p>
                                            <img
                                                className="cancelInsurance"
                                                src={mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                            />
                                            Toute inscription est définitive. Cependant si tu te désistes avant
                                            le {dayjs(tournamentRegistration.proforma.startDate).subtract(21, "days").hour(tournamentRegistration?.cup?.hour / 60).format("DD/MM/YYYY - HH:mm")}
                                        </p>
                                        <ul>
                                            <li>Avec option annulation : remboursement</li>
                                            <li>Sans option annulation : avoir valable 12 mois (des frais de gestion d’un montant de 19€ seront conservés par URBANPADEL)</li>
                                        </ul>
                                        <p>
                                            Attention, en cas de désistement après le  {dayjs(tournamentRegistration.proforma.startDate).subtract(21, "days").hour(tournamentRegistration?.cup?.hour / 60).format("DD/MM/YYYY - HH:mm")} tu
                                            devras avoir souscris à l’option annulation pour obtenir un avoir valable 12 mois qui sera crédité sur ton compte MyUrban.
                                            Sans option annulation souscrite aucun remboursement ne pourra avoir lieu.
                                        </p>
                                    </>
                                )}
                            </div>
                        )}
                        {[ "LEAGUE", "CUP" ].includes(tunnelType) && optionsAtPayment.length > 0 && (
                            <div className="paymentOptions">
                                {optionsAtPayment.map(
                                    (el) => (
                                        <div className="cartBox__content bordered" key={"optAtPayment-" + el.id}>
                                            <div className="c-inputHolder--checkbox">
                                                <input
                                                    type="checkbox"
                                                    value=""
                                                    disabled={isPreregistration}
                                                    onChange={addOption}
                                                    defaultChecked={subOptionsIds.includes(el.id)}
                                                    id={"optAtPayment-" + el.id}
                                                />
                                                <label htmlFor={"optAtPayment-" + el.id}>
                                                    {el.name === "Assur Annul EdF" ?
                                                        "Assurance annulation"
                                                        :
                                                        el.name
                                                    }
                                                </label>
                                                {el.description && (
                                                    <img
                                                        className="cancelInsurance"
                                                        src={mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                        onMouseOver={e => e.currentTarget.src = mediaPath([ "/assets/icons/info-small-orange-hover.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                        onMouseOut={e => e.currentTarget.src = mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                        onClick={
                                                            () => {
                                                                ModalHandler.show(InsurancePopIn, {
                                                                    optionInfo: {
                                                                        description: el.description,
                                                                        title: el.name,
                                                                    },
                                                                })
                                                            }
                                                        }
                                                    />
                                                )}

                                            </div>

                                            <div className="h2-600 price-option">
                                                <CurrencyDisplay price={el.price}/>
                                            </div>
                                        </div>
                                    ),
                                )}
                            </div>
                        )}
                        <ul className={classNames("cartPriceDetails__content", { paddingBorder: tunnelType === "LEAGUE" && cartPriceDetailsContent?.length })}>
                            {cartPriceDetailsContent.length > 0 && (
                                <React.Fragment>
                                    {tunnelType !== "TRAINING" && <strong>Mes options :</strong>}
                                    {cartPriceDetailsContent}
                                </React.Fragment>
                            )}
                        </ul>
                    </div>

                    {cartLines.length > 0 &&
                        (<div className="cartOrderDetails c-mrg--btm--sm--1">
                            <div className="h3">{cartOrderDetailsTitle}</div>
                            <ul className="cartOrderDetails__list">
                                {cartLines}
                            </ul>

                            <ul className="cartOrderDetails__price">
                                {equipmentDiscountOnSubTotal < 0 &&
                                    (<li className="discount">
                                        <div className="info">Remise équipement :</div>
                                        <div className="price"><CurrencyDisplay
                                            price={equipmentDiscountOnSubTotal}/></div>
                                    </li>)
                                }
                                <li className="subtotal">
                                    <div className="info">Total {shopName} :</div>
                                    <div className="price">
                                        <CurrencyDisplay
                                            price={
                                                equipmentSubTotal
                                                + equipmentDiscountOnSubTotal
                                            }
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>)
                    }
                </div>

                <div className="c-col c-col--sidebar c-col--xm--12">
                    <div className={classNames("cartBox", { cartBoxPreRegistratfion: isPreregistration, noOption: optionsAtPayment.length === 0 })}>
                        {![ "LEAGUE", "CUP" ].includes(tunnelType) && optionsAtPayment.length > 0 &&
                            optionsAtPayment.map(
                                (el) => (
                                    <div className="cartBox__content bordered" key={"optAtPayment-" + el.id}>
                                        <div className="c-inputHolder--checkbox">
                                            <input
                                                type="checkbox"
                                                value=""
                                                disabled={isPreregistration}
                                                onChange={addOption}
                                                defaultChecked={subOptionsIds.includes(el.id)}
                                                id={"optAtPayment-" + el.id}
                                            />
                                            <label htmlFor={"optAtPayment-" + el.id}>
                                                {el.name === "Assur Annul EdF" ?
                                                    "Assurance annulation"
                                                    :
                                                    el.name
                                                }
                                            </label>
                                            {el.description && (
                                                <img
                                                    className="cancelInsurance"
                                                    src={mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                    onMouseOver={e => e.currentTarget.src = mediaPath([ "/assets/icons/info-small-orange-hover.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                    onMouseOut={e => e.currentTarget.src = mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                    onClick={
                                                        () => {
                                                            ModalHandler.show(InsurancePopIn, {
                                                                optionInfo: {
                                                                    description: el.description,
                                                                    title: el.name,
                                                                },
                                                            })
                                                        }
                                                    }
                                                />
                                            )}

                                        </div>

                                        <div className="h2-600 price-option">
                                            <CurrencyDisplay price={el.price}/>
                                        </div>
                                    </div>
                                ),
                            )
                        }

                        {!isPreregistration && (
                            <React.Fragment>
                                {isNotSupersub && (
                                    <div className="cartBox__content bordered">
                                        <div className="h4">Sous total</div>
                                        <div className="h2-600">
                                            <CurrencyDisplay formatZero={true} price={sousTotal}/>
                                        </div>
                                    </div>
                                )}
                                {isNotSupersub && (
                                    <div className="cartBox__content column" ref={promoCodeInput}>
                                        <div className={classNames("discount-div", { disabled: isPreregistration })}>
                                            <TextInput
                                                label="Code promo"
                                                value={promoCode}
                                                customRootCls="codeInput"
                                                onChange={setPromoCode}
                                                disabled={firstSubHasDiscountCode || isPreregistration}
                                                actions={[ {
                                                    onClick: !isPreregistration ?
                                                        firstSubHasDiscountCode ?
                                                            () => {
                                                                deleteDiscountCode()
                                                                setCodeError(false)
                                                                setPromoCode("")
                                                            }
                                                            :
                                                            addPromoCode
                                                        :
                                                        () => {
                                                        },
                                                    render: firstSubHasDiscountCode ? (
                                                        <img src={mediaPath([ "/assets/icons/cross-orange.svg", "/assets/images/padel/icons/cross-close.svg" ])}/>
                                                    ) : (
                                                        <ButtonCta
                                                            text="OK"
                                                            noSkew
                                                            noShadow
                                                        />),
                                                } ]}
                                            />
                                        </div>

                                        {codeError && (
                                            <div className="rowPromo">
                                                <div className="info">Code incorrect</div>
                                            </div>
                                        )}
                                        {promoCodeDiscount > 0 && (
                                            <div className="rowPromo">
                                                <div className="info">Remise :</div>
                                                <div className="price">
                                                    - <CurrencyDisplay price={promoCodeDiscount}/>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </React.Fragment>
                        )}

                        {isNotSupersub && (
                            <AvoirCheckbox
                                totalAmount={firstSubscription ?
                                    firstSubscription.proforma.amountTTC : null}
                                tva={firstSubscription ?
                                    firstSubscription.proforma.amountTTC - firstSubscription.proforma.amountHT : null}
                                formName="avoirFormData"
                                isPreregistration={isPreregistration}
                            />
                        )}

                        <div
                            className={
                                "cartBox__info column biggerPadding noMargin" +
                                (tunnelType === "SUPERSUB" ?
                                    " bordered"
                                    :
                                    ""
                                )
                            }
                        >
                            <div className="rowPrice big">
                                <div className="info">Total</div>
                                <div className="price">
                                    <CurrencyDisplay
                                        formatZero={true}
                                        price={
                                            amountTotal
                                        }
                                    />
                                </div>
                            </div>

                            <div className="rowPrice">
                                <div className="info">Dont TVA :</div>
                                <div className="price">
                                    <CurrencyDisplay formatZero={true} price={totalTVA}/>
                                </div>
                            </div>
                        </div>

                        <div className="cartBox__cta">
                            {tunnelType === "TRAINING" && firstSubscription?.kid && (
                                <div className="c-inputHolder--checkbox checkbox-representant">
                                    <input type="checkbox" id="cartCGV" value=""
                                        disabled={isPreregistration}
                                        checked={representantLegalChecked}
                                        onChange={handleRepresentantLegal}/>
                                    <label>
                                        Je certifie être le représentant légal de <span
                                            className="kidName">{firstSubscription?.kid ? firstSubscription.kid.firstName : ""}</span>
                                    </label>
                                </div>
                            )}

                            <div className="c-inputHolder--checkbox">
                                <input
                                    type="checkbox"
                                    id="cartCGV"
                                    disabled={isPreregistration}
                                    checked={paymentEnabled}
                                    value=""
                                    onChange={handleCGV}
                                />
                                <label>
                                    J’accepte les&nbsp;<a onClick={() => window.open(cgvUrl, "_blank")}>Conditions Générales de Vente</a>
                                </label>
                            </div>

                            {!(cbCard && Object.keys(cbCard).length > 0) && (<div className="c-inputHolder--checkbox cb-div">
                                <input
                                    type="checkbox"
                                    id="saveCard"
                                    checked={saveCard}
                                    value=""
                                    onChange={() => setSaveCard(!saveCard)}
                                />
                                <label>
                                    J'enregistre ma carte
                                </label>
                            </div>)}

                            {cbCard && Object.keys(cbCard).length > 0 && (
                                <div className="c-inputHolder--checkbox cb-div">
                                    <input
                                        type="checkbox"
                                        id="cb"
                                        onChange={useUserCB}
                                    />
                                    <label>
                                        <span>J'utilise ma carte enregistrée</span>
                                        <span>({cbCard.tokenDetails.pan})</span>
                                    </label>
                                </div>
                            )}

                            {showAcceptCGV && (
                                <div className="acceptCGV">
                                    <img
                                        src={mediaPath([ "/assets/icons/notifications.svg", "/assets/images/padel/icons/icons-notifications.svg" ])}
                                        className="alert"
                                    />
                                    Tu n'as pas accepté les CGV
                                </div>
                            )}
                            {(firstSubscription?.kid && !representantLegalChecked) && showAcceptCGV && (
                                <div className="acceptCGV">
                                    <img
                                        src={mediaPath([ "/assets/icons/notifications.svg", "/assets/images/padel/icons/icons-notifications.svg" ])}
                                        className="alert"
                                    />
                                    Tu dois être le représentant légal de {firstSubscription?.kid?.firstName}
                                </div>
                            )}
                            <div className={classNames("orderSummaryButtonsHolder", {
                                even: buttonsCount() % 2 === 0,
                                odd: buttonsCount() % 2 !== 0,
                            })}>
                                <ButtonCta
                                    isCta2
                                    className={classNames("btn btn--orange btn--big btn--full",{
                                        disabled: !isPaymentEnabled,
                                    })}
                                    onClick={
                                        () => {
                                            if (isPaymentEnabled) {
                                                proceedCheckout(false, "", "", isUsingUserCB2, saveCard)
                                            } else {setShowAcceptCGV(true)}
                                        }
                                    }
                                    text={(amountTotal) !== 0 ? (
                                        <span>Payer</span>
                                    ) :
                                        <span>Finaliser</span>
                                    }
                                />

                                {isPreregistration &&
                                    cancelPreRegistrationButton
                                }
                                {allowPaymentSpread && (amountTotal !== 0) && (
                                    <ButtonCta
                                        className="buttonCta--w-100 buttonCta--big"
                                        text={"Payer en 3 fois sans frais"}
                                        isAlt
                                        disabled={!isPaymentEnabled}
                                        onClick={() => {
                                            if (isPaymentEnabled) {
                                                proceedCheckout(3, amountTotal, "", isUsingUserCB2)
                                            }
                                        }}
                                    />
                                )}
                                {(!isPreregistration && preregistrationEnabled) && (
                                    <ButtonCta
                                        className={classNames("doubleText", {
                                            "buttonCta--big": theme === "theme-soccer",
                                            disabled: !isPaymentEnabled,
                                        })}
                                        text="Paiement différé"
                                        subText= {"à régler sous " + preRegisterLimitDays + " jours"}
                                        isCta2
                                        onClick={
                                            isPaymentEnabled ?
                                                () => {
                                                    preregister(
                                                        tunnelType,
                                                        setAjaxLoading,
                                                        firstSubscription,
                                                        dispatch,
                                                        navigate,
                                                        path,
                                                    )
                                                }
                                                :
                                                () => setShowAcceptCGV(true)
                                        }
                                    />
                                )}
                            </div>
                            {(
                                tunnelType === "SUPERSUB" &&
                                    userLoaded &&
                                    firstSubscription &&
                                    userLoaded.contactId !== firstSubscription.organizer.id
                            ) &&
                                (<div className="supersubPaymentSubline">Tu seras débité uniquement si
                                    l'organisateur accepte ta demande et que le match a bien lieu.</div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            {tunnelType === "LEAGUE" && (
                <PayFreeAmount
                    registration={firstSubscription}
                    withMultiPayments={withMultiPayments}
                    paiementCallback={(multiPayments, amountToPay, notPayzen) => {
                        setAjaxLoading(true)
                        proceedCheckout(multiPayments, amountToPay, notPayzen, isUsingUserCB2)
                    }}
                    tunnelType={tunnelType}
                    isOpen={isPayFreeAmountPopInOpen}
                    setIsOpen={setIsPayFreeAmountPopInOpen}
                />
            )}
        </Step>
    )
}

OrderSummary.propTypes = {
    backToStep: number,
    customCls: string,
    responseID: number,
    supersubApplyMessage: string,
    supersubRequest: object,
    title: string,
    tunnelType: string,
}

export default OrderSummary
