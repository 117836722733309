import classNames from "classnames"
import { array, func, object, string } from "prop-types"
import { useState } from "react"

import useMediaPath from "../../../../../hooks/useMediaPath.js"
import usePlural from "../../../../../hooks/usePlural.js"
import CurrencyDisplay from "../../../../currencies/CurrencyDisplay.jsx"
import { ShopCartEquipmentLine } from "../ShopCartEquipmentLine/ShopCartEquipmentLine.jsx"

import "./CartOverview.scss"

export const CartOverview = ({ discount, advertising, deleteProduct, products = [], pricingInfos }) => {
    const mediaPath = useMediaPath()
    const [ opened, setOpened ] = useState(false)
    const { maxAmount, amountPerLevel, level, id } = discount || {}

    return (
        <aside className="cart-overview-wrapper">
            <div className={classNames("cart-overview", {
                "opened": opened,
            })}>
                <a className="cart-close-button" onClick={() => setOpened(false)} title="Fermer">
                    <img src={mediaPath([ "/assets/icons/cross-orange.svg", "/assets/icons/cross-white-diag.svg" ])}
                        alt="Fermer"/>
                </a>

                <div>
                    <h2 className="cart-overview-title box-padding">Panier</h2>

                    <div className="cart-overview-info box-padding" key={"discount-" + id}>
                        {amountPerLevel && level ? (
                            <p>
                                {level}€ euros achetés = {amountPerLevel}€ offerts
                                {maxAmount !== null && ` dans la limite de ${maxAmount}€ offerts`}
                            </p>
                        ) : null}
                    </div>
                </div>

                {advertising && (
                    <div className="cart-overview-advertising box-padding">
                        <div className="cart-overview-wysiwyg" dangerouslySetInnerHTML={{ __html: advertising }}></div>
                    </div>
                )}

                {products.length > 0 && (
                    <ul className="cart-overview-products box-padding" >
                        {products.map((product, index) => (
                            <ShopCartEquipmentLine productOrder={product} removeProduct={deleteProduct} key={index}/>
                        ))}
                    </ul>
                )}

                {products.length > 0 && (
                    <div className="cart-overview-price-wrapper box-padding">
                        {pricingInfos.discountOnSubTotal < 0 ? (
                            <>
                                <div className="cart-overview-price subtotal">
                                    <div className="cart-overview-total__label">Total Équipements</div>
                                    <div className="cart-overview-total__price">
                                        <CurrencyDisplay price={pricingInfos?.subtotal}/>
                                    </div>
                                </div>

                                <div className="cart-overview-price discount">
                                    <div className="cart-overview-total__label">Remise :</div>
                                    <div className="cart-overview-total__price">
                                        <CurrencyDisplay price={pricingInfos?.discountOnSubTotal}/>
                                    </div>
                                </div>

                                <div className="cart-overview-price total">
                                    <div className="cart-overview-total__label">Total après remise</div>
                                    <div className="cart-overview-total__price">
                                        <CurrencyDisplay
                                            price={pricingInfos?.subtotal + pricingInfos?.discountOnSubTotal}/>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="cart-overview-price subtotal">
                                <div className="cart-overview-total__label">Total Équipements</div>
                                <div className="cart-overview-total__price">
                                    <CurrencyDisplay price={pricingInfos?.subtotal}/>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {!products.length && (
                    <div className="cart-overview-empty box-padding">
                        {mediaPath([
                            "Tu n'as aucun équipement dans ton panier.",
                            "Vous n'avez aucun équipement dans votre panier.",
                        ])}
                    </div>
                )}
            </div>
            <button className="cart-mobile-button" onClick={() => setOpened(true)}>
                <img alt="Panier"
                    src={mediaPath([ "/assets/icons/icon--cart--orange.svg", "/assets/images/padel/icons/icon--cart--padel-green.svg" ])}/>
                <div>
                    <span className="count">
                        {products.length} {usePlural(products.length, "article", "articles")}
                    </span>
                    <div className="price">
                        <CurrencyDisplay price={pricingInfos?.subtotal + pricingInfos?.discountOnSubTotal}/>
                    </div>
                </div>
                <img className="arrow" src="/assets/icons/arrow-up--white.svg" alt="Flèche"/>
            </button>
        </aside>
    )
}

CartOverview.propTypes = {
    advertising: string,
    deleteProduct: func,
    discount: object,
    pricingInfos: object,
    products: array,
}
