import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import { ProductPreviewModal } from "./ProductPreviewModal.jsx"
import useMediaPath from "../../../../../hooks/useMediaPath.js"
import ModalHandler from "../../../../../providers/Modal/ModalHandler.jsx"
import ButtonCta from "../../../../Buttons/button/button-cta.jsx"
import CurrencyDisplay from "../../../../currencies/CurrencyDisplay.jsx"
import { SelectInput } from "../../../../Inputs/Select/SelectInput.jsx"
import Modal from "../../../../Modal/Modal.jsx"
import MessagePopin from "../../../../popin/MessagePopin/MessagePopin.jsx"
import { TooltipLight } from "../../../../tooltip/tooltipLight/tooltipLight.jsx"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

import "./ShopProduct.scss"

export const ShopProduct = ({ product, addProduct }) => {
    const mediaPath = useMediaPath()
    const productImages = [ product.pictureUrl, ...product.secondaryPicturesUrls ]
    const [ selectedSize, setSelectedSize ] = useState(product?.sizes?.length > 0 ? product.sizes[0].value : "")
    const [ selectedQuantity, setSelectedQuantity ] = useState(1)
    const [ addModalOpen, setAddModalOpen ] = useState(false)

    useEffect(() => {
        if (!selectedSize) {
            setSelectedSize(product?.sizes?.length > 0 ? product.sizes[0].value : "")
        }
    }, [ product ])

    const handleAddProduct = () => {
        const size = !!selectedSize && parseInt(selectedSize)

        if ((product?.sizes?.length > 1 && !product.sizes.find(e => e.value === size))) {
            ModalHandler.show(MessagePopin, {
                message: "Renseigne une taille",
                title: "Taille manquante",
            })
        }
        else if (!selectedQuantity) {
            ModalHandler.show(MessagePopin, {
                message: "Renseigne une quantité",
                title: "Quantité manquante",
            })
        }
        else if ((product?.sizes.find(e => e.value === size) || product?.sizes.length <= 1) && selectedQuantity !== 0) {
            addProduct({
                count: selectedQuantity,
                product: product,
                size: size || product?.sizes[0]?.value,
            })
        }
    }

    const actionsContent = (withLabel) => (
        <div className="product-actions">
            {product.sizes && (
                product.sizes.length > 1
                || (product.sizes.length === 1 && product.sizes[0].label !== "Unique")
            ) && (
                <SelectInput
                    fullWidth
                    label={withLabel ? "Taille" : null}
                    id={product.id + "_sizes"}
                    value={selectedSize}
                    options={product.sizes}
                    onChange={(event)=>{setSelectedSize(event.target.value)}}
                    customRootCls="sizeSelect"
                    customArrow={[
                        "url('/assets/icons/arrow-down-orange.svg')",
                        "url('/assets/images/padel/icons/icon-arrow-down-red.svg')",
                    ]}
                />
            )}

            <SelectInput
                fullWidth
                label={withLabel ? "Quantité" : null}
                id={product.id + "_count"}
                value={selectedQuantity}
                options={Array.from({ length: 10 }).map((_, i) => {
                    return { label: i + 1, value: i + 1 }
                })}
                onChange={(event)=>{setSelectedQuantity(parseInt(event.target.value))}}
                customArrow={[
                    "url('/assets/icons/arrow-down-orange.svg')",
                    "url('/assets/images/padel/icons/icon-arrow-down-red.svg')",
                ]}
            />
            <ButtonCta
                className="product-add-button"
                onClick={() => {
                    handleAddProduct()
                    setAddModalOpen(false)
                }}
                text="Ajouter"
                disabled={product?.sizes?.length === 0}
            />
        </div>
    )

    return (
        <>
            <article className="product-card" aria-label={product.name}>
                {
                    product?.description && (
                        <TooltipLight
                            text={<p dangerouslySetInnerHTML={{ __html: product?.description }}></p>}
                            dark
                            centered
                        >
                            <div className="product-tooltip">
                                <img
                                    src={mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                    alt="info"/>
                            </div>
                        </TooltipLight>
                    )
                }
                <div className="product-carousel">
                    <Swiper
                        modules={[ Navigation, Pagination ]}
                        pagination={{ clickable: true }}
                        slidesPerView={1}
                        wrapperTag="ul"
                    >
                        {productImages?.map((image, index) => (
                            <SwiperSlide key={product.id + index} tag="li">
                                <img
                                    src={image}
                                    loading={"lazy"}
                                    alt={product.name + index}
                                    onClick={() => {
                                        ModalHandler.show(ProductPreviewModal, {
                                            images: productImages,
                                            name: product.name,
                                        })
                                    }}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="product-details">
                    <h3>{product.name}</h3>
                    <CurrencyDisplay price={product.price}/>
                </div>
                <div className="product-actions">
                    {actionsContent(false)}
                </div>
                <div className="product-actions-mobile">
                    <div className="product-price">
                        <CurrencyDisplay price={product.price}/>
                    </div>
                    <button className="btn btn--orange" onClick={() => setAddModalOpen(true)}>
                        <img alt={"Ajouter au panier"} src="/assets/icons/icon--cart--white.svg"/>
                    </button>
                </div>
            </article>
            <Modal isOpen={addModalOpen} className="product-add-modal" onClose={() => setAddModalOpen(false)} portalIdentifier={"#content"} withPortal>
                {actionsContent(true)}
            </Modal>
        </>
    )
}

const productPropType = {
    description: PropTypes.string,
    id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
    name: PropTypes.string.isRequired,
    pictureUrl: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    secondaryPicturesUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
    sizes: PropTypes.array.isRequired,
}

ShopProduct.propTypes = {
    addProduct: PropTypes.func.isRequired,
    product: PropTypes.shape(productPropType),
}
