import { handleOzmoReloadingAction } from "../store/actions/scaffold"

function handleRedirections(store, history) {
    switch (history.location.pathname) {

    case "/reload/accountVerification":

        store.dispatch(
            handleOzmoReloadingAction("accountVerify"),
        )

        break

    case "/padel/reload/accountVerification":

        store.dispatch(
            handleOzmoReloadingAction("accountVerifyPadel"),
        )

        break

    case "/reload/locationPrivateInvit": // /reload

        store.dispatch(
            handleOzmoReloadingAction("locationPrivateInvit"),
        )

        break
    }
}

export default handleRedirections
