import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom"

import { completeSuperSubRequest } from "../../../components/SuperSub/SelectSuperSub/api"
import { useTheme } from "../../../components/ThemeContext/ThemeContext.jsx"
import {
    generateLocationPublicInvitDetails,
    getLocation,
    getUser,
    handleSplitPaymentToken,
    persistPayzenCbAlias,
} from "../../../globalAPI/api"
import { getSuperSubsRequest } from "../../../globalAPI/supersub/api.js"
import { getBirthdayRegistration } from "../../../pages/kids/birthdayReservation/api"
import { getKidsSchoolSubscription } from "../../../pages/kids/ecoleDeFoot/api"
import { getKidsStageSubscription } from "../../../pages/kids/stageDeFoot/api"
import { getLeagueRegistration } from "../../../pages/league/api/api.js"
import { getPreBook } from "../../../pages/reservation/tunnelReservation/api"
import { getCupRegistrationDetails } from "../../../pages/teams/urbanCup/api"
import usePath from "../../../routes/services/usePath"
import haveCompo from "../../../services/haveCompo"
import updateNextBooking from "../../../services/updateNextBooking"
import { toggleLevel3Overlay } from "../../../store/actions/content"
import { setFormEntry } from "../../../store/forms/actions"
import useThemeFeatures from "../../useThemeFeatures"

function useRedirections() {
    const [
        queryParameters,
        // setQueryParameters
    ] = useSearchParams()

    const [ isLoading, setIsLoading ] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const path = usePath()
    const themeFeatures = useThemeFeatures()
    const theme = useTheme()

    const user = useSelector(state => state.userLoaded)

    const handle = async () => {
        if (user !== undefined && Object.keys(user).length > 0 && queryParameters) {
            const urlTheme = queryParameters.get("theme")

            const urlSelector = (soccerUrl) => urlTheme === "theme-padel" ? "/padel" + soccerUrl : soccerUrl

            const krAnswer = state?.krAnswer

            if (!krAnswer && state?.isPaymentReturn) {
                throw new Error("No krResponse in state")
            }

            if (queryParameters.get("goto")) {
                if (queryParameters.get("goto") === "reserver") {
                    navigate(path("/reserver"))
                }
            }

            let props = { isOldUrl: true, wwwReload: true }

            switch (queryParameters.get("action")) {
            case "kidsStageSubscription":
                await kidStageSubscription(props)
                break

            case "kidsSchoolSubscription":
                await kidSchoolSubscription(props)
                break

            case "leagueSubscription":
                if (queryParameters.get("center") !== undefined) {
                    props.centerId = queryParameters.get("center")
                }
                navigate(urlSelector("/urbanleague/new"), { state: props })
                break

            case "payzenAliasRegister":
                if (krAnswer?.transactions?.[0]?.paymentMethodToken) {
                    await persistPayzenCbAlias({ alias: krAnswer?.transactions?.[0]?.paymentMethodToken })
                    dispatch(toggleLevel3Overlay(false, null))
                    navigate(urlSelector("/profil/moyensDePaiement"))
                }

                break

            case "payzenSplitPayment":
                await handleSplitPaymentToken(queryParameters.get("token")).then((result) => {
                    let tokenStatus = result.status
                    if (tokenStatus === "VALID") {
                        let cbCard = result.card
                        let tokenDetails = result.tokenDetails
                        dispatch(
                            toggleLevel3Overlay(
                                true,
                                "payzenCbPayment",
                                {
                                    amount: tokenDetails.amount,
                                    card: cbCard,
                                    navigate: navigate,
                                    path: path,
                                    paymentType: tokenDetails.resourceType,
                                    resourceId: tokenDetails.resourceId,
                                    returnUrl: import.meta.env.VITE_URL_BASE + "/?action=payzenPayment&type=" + tokenDetails.resourceType,
                                    splitToken: tokenDetails.splitToken,
                                    theme: theme,
                                }, null, null, () => window.location.href = "/",
                            ),
                        )
                    }
                })
                break

            case "payzenPayment":
                await payzenPayment(urlTheme, urlSelector, krAnswer)
                break

            default:
                break
            }
        }

    }

    const payzenPayment = async (urlTheme, urlSelector, krAnswer) => {
        let resourceType = queryParameters.get("type")?.toUpperCase()
        let subscriptionId = krAnswer?.transactions?.[0]?.metadata?.resourceId
        let isSharePayment = queryParameters.get("isSharePayment")
        let isRedirectToListing = queryParameters.get("redirectToListing")
        const you = urlTheme === "theme-padel" ? "te" : "vous"
        const you2 = urlTheme === "theme-padel" ? "t'" : "vous "
        const secondSub = queryParameters.get("secondSub")

        const params = {
            centerId: queryParameters.get("bookingCenterId"),
            date: queryParameters.get("bookingDate"),
            discountId: queryParameters.get("haveDiscountFromPreviousStep") && queryParameters.get("discountId"),
            discountLabel: queryParameters.get("haveDiscountFromPreviousStep") &&  queryParameters.get("discountLabel"),
            duration: queryParameters.get("duration"),
            hour: queryParameters.get("hour"),
            mn: queryParameters.get("mn"),
            part: queryParameters.get("part"),
            players: queryParameters.get("players"),
            resourceType: queryParameters.get("bookingResourceType"),
            typeName: queryParameters.get("typeName"),
        }
        Object.keys(params).forEach((param) => !params[param] && delete params[param])

        let fromListing = queryParameters.get("fromListing")

        if (krAnswer?.orderStatus === "REFUSED") {
            navigate({
                pathname: urlSelector("/confirmcommand"),
                search: createSearchParams(params).toString(),
            },{
                state: {
                    fromGestion: queryParameters.get("fromGestion"),
                    krAnswer: krAnswer,
                    locationId: krAnswer?.transactions?.[0]?.metadata?.resourceId,
                    preBookingId: krAnswer?.transactions?.[0]?.metadata?.preReservationId,
                    success: false,
                    theme: queryParameters.get("theme"),
                    tunnelType: resourceType,
                },
            })
            return
        }

        if (import.meta.env.VITE_URL_BASE ===  "http://localhost:3000" && queryParameters.get("ipn")) {
            const ipn = import.meta.env.VITE_URL_BASE + decodeURIComponent(queryParameters.get("ipn"))
            const params = {
                ...Object.fromEntries(queryParameters.entries()),
                "kr-answer": JSON.stringify(krAnswer),
            }

            // eslint-disable-next-line no-console
            console.log("LOCALHOST ONLY IPN CALL", ipn, params)

            await axios.post(ipn, params).then(
                (res) => {
                    // eslint-disable-next-line no-console
                    console.log("res Payment Payzen: %o", res)
                },
            )
        }
        if (resourceType === "BOOKING") {
            if (queryParameters.get("origin") && queryParameters.get("origin") === "tunnel") {
                updateNextBooking()
                navigate(urlSelector("/confirmcommand"), {
                    state: {
                        customContentIdentifier: "locations",
                        customLink: "/reservations/locations",
                        krAnswer: krAnswer,
                        message: "bookingConfirm",
                        success: true,
                        title: "Réservation réussie !",
                        tunnelType: resourceType,
                    },
                })
            } else if (queryParameters.get("fromGestion") && queryParameters.get("fromGestion") === "true") {
                updateNextBooking()
                navigate(urlSelector("/confirmcommand"), {
                    state: {
                        fromGestion: true,
                        krAnswer: krAnswer,
                        locationId: krAnswer?.transactions?.[0]?.metadata?.resourceId,
                        message: "Bon match.",
                        subtitle: "Ton paiement a bien été enregistré.",
                        success: true,
                        title: "Paiement effectué !",
                        tunnelType: resourceType,
                    },
                })
            } else {
                setFormEntry("ChooseCenterActivity", null)
                let numberOfCheck = 0
                const linkConstructor = () => {
                    let link = "/reserver?"
                    if (krAnswer?.transactions?.[0]?.metadata?.preReservationId) {
                        link += (krAnswer?.transactions?.[0]?.metadata?.preReservationId + "&")
                    }
                    if (queryParameters.get("bookingResourceType")) {
                        link += (queryParameters.get("bookingResourceType") + "&")
                    }
                    link += (queryParameters.get("bookingDate") + "&" + queryParameters.get("bookingCenterId"))
                    return link
                }
                const redirectionLink = linkConstructor()

                const checkPrebook = async (increment) => {
                    if (increment) {
                        numberOfCheck += 1
                    }
                    if (numberOfCheck < 6) {
                        const res = await getPreBook(krAnswer?.transactions?.[0]?.metadata?.preReservationId)
                        if (res?.Message) {
                            navigate(urlSelector("/confirmcommand"), {
                                state: {
                                    customContentIdentifier: "reserver",
                                    customLink: redirectionLink,
                                    isCanceled: true,
                                    krAnswer: krAnswer,
                                    message: "cancelPayment",
                                    success: true,
                                    title: "Paiement annulé&nbsp;!",
                                    tunnelType: resourceType,
                                },
                            })
                        } else if (res?.preReservation?.reservationId) {
                            generateLocationPublicInvitDetails(res?.preReservation?.reservationId)
                            updateNextBooking()
                            const compo = haveCompo(res.preReservation.resourceType)
                            navigate(urlSelector("/confirmcommand"), {
                                state: {
                                    bookingId: res.preReservation.reservationId,
                                    customContentIdentifier: "reserver",
                                    customLink: "/reserver",
                                    krAnswer: krAnswer,
                                    message: compo ? "bookingConfirmCompo" : "bookingConfirm",
                                    success: true,
                                    title: "Réservation réussie&nbsp;!",
                                    tunnelType: resourceType,
                                },
                            })
                        } else {
                            setTimeout(() => {
                                checkPrebook(true)
                            }, 1000)
                        }
                    } else {
                        navigate(urlSelector("/confirmcommand"), {
                            state: {
                                customContentIdentifier: "reserver",
                                customLink: redirectionLink,
                                isCanceled: true,
                                krAnswer: krAnswer,
                                message: "cancelPayment",
                                success: true,
                                title: "Paiement annulé&nbsp;!",
                                tunnelType: resourceType,
                            },
                        })
                    }
                }
                await checkPrebook()
            }
        } else if (resourceType === "BILL" || resourceType === "DEBT") {
            if (resourceType === "DEBT") {
                await getUser().then(() => navigate(urlSelector("/confirmcommand"), {
                    state: {
                        krAnswer: krAnswer,
                        subtitle: `Le paiement de ${ resourceType === "BILL" ? " ta facture" : " ton ardoise"} à bien été enregistré.`,
                        success: true,
                        title: "Paiement effectué !",
                        tunnelType: resourceType,
                    },
                }))
            } else {
                navigate(urlSelector("/confirmcommand"), {
                    state: {
                        krAnswer: krAnswer,
                        subtitle: `Le paiement de ${ resourceType === "BILL" ? " ta facture" : " ton ardoise"} à bien été enregistré.`,
                        success: true,
                        title: "Paiement effectué !",
                        tunnelType: resourceType,
                    },
                })
            }
        } else if (resourceType === "TRAINING") {
            let subscriptionId = krAnswer?.transactions?.[0]?.metadata?.resourceId
            await getKidsStageSubscription(subscriptionId).then((stageSubscription) => {
                navigate(urlSelector("/confirmcommand"), {
                    state: {
                        confirmedSubscription: stageSubscription.data.data,
                        krAnswer: krAnswer,
                        message: `Un email de confirmation ${you2}a été envoyé.`,
                        subtitle: "trainingConfirm",
                        success: true,
                        title: "Inscription réussie !",
                        tunnelType: resourceType,
                    },
                })
            })
        } else if (resourceType === "SCHOOL") {
            await getKidsSchoolSubscription(subscriptionId).then((schoolSubscription) => {
                navigate(urlSelector("/confirmcommand"), {
                    state: {
                        confirmedSubscription: schoolSubscription,
                        krAnswer: krAnswer,
                        message: `Un email de confirmation ${you2}a été envoyé.`,
                        secondSubscription: secondSub,
                        subtitle: schoolSubscription?.kid ?  `Nous ${you} confirmons l'inscription de ${schoolSubscription.kid.firstName} ${schoolSubscription.kid.lastName}.` : "",
                        success: true,
                        title: "Inscription réussie&nbsp;!",
                        tunnelType: resourceType,
                    },
                })
            })
        } else if (resourceType === "LEAGUE") {
            if (isSharePayment) {
                await getLeagueRegistration(subscriptionId).then((leagueSubscription) => {
                    navigate("/confirmcommand", {
                        state: {
                            confirmedSubscription: leagueSubscription,
                            krAnswer: krAnswer,
                            success: true,
                            title: "Paiement réussi&nbsp;!",
                            tunnelType: resourceType,
                        },
                    })
                })
            } else if (isRedirectToListing) {
                await getLeagueRegistration(subscriptionId).then((leagueSubscription) => {
                    const subscription = leagueSubscription.data.data
                    navigate("/confirmcommand", {
                        state: {
                            confirmedSubscription: subscription,
                            krAnswer: krAnswer,
                            message: "isRedirectToListing",
                            success: true,
                            title: "Paiement effectué !",
                            tunnelType: resourceType,
                        },
                    })
                })
            } else {
                await getLeagueRegistration(subscriptionId).then((leagueSubscription) => {
                    const subscription = leagueSubscription.data.data
                    navigate("/confirmcommand", {
                        state: {
                            confirmedSubscription: subscription,
                            krAnswer: krAnswer,
                            message: "Un email de confirmation  t'a été envoyé.",
                            success: true,
                            title: fromListing ? "Paiement réussi&nbsp;!" : "Inscription réussie&nbsp;!",
                            tunnelType: resourceType,
                        },
                    })
                })
            }
        } else if (resourceType === "BIRTHDAY") {
            await getBirthdayRegistration(subscriptionId).then((res) => {
                let birthdaySubscription = res.data.data
                navigate(urlSelector("/confirmcommand"), {
                    state: {
                        confirmedSubscription: birthdaySubscription,
                        krAnswer: krAnswer,
                        message: "Un email de confirmation vous a été envoyé.",
                        subtitle: "Nous vous confirmons votre réservation d'anniversaire.",
                        success: true,
                        title: "Réservation réussie&nbsp;!",
                        tunnelType: resourceType,
                    },
                })
            })
        } else if (resourceType === "CUP") {
            await getCupRegistrationDetails(subscriptionId).then((res) => {
                let cupSubscription = res.data.data
                navigate(urlSelector("/confirmcommand"), {
                    state: {
                        confirmedSubscription: cupSubscription,
                        krAnswer: krAnswer,
                        message: "Un email de confirmation t'a été envoyé.",
                        subtitle: "cupConfirm",
                        success: true,
                        title: "Inscription réussie&nbsp;!",
                        tunnelType: resourceType,
                    },
                })
            })
        } else if (resourceType === "SUPERSUB" || resourceType === "SUPERSUB_REPONSE") {
            const displayConfirm = (res) => {

                navigate(urlSelector("/confirmcommand"), {
                    state: {
                        confirmedSubscription: res?.reservation,
                        customContentIdentifier: "findMatch",
                        customLink: "/supersub/findMatch?isMenuClick=true",
                        krAnswer: krAnswer,
                        message: "playerSupersubConfirm",
                        subtitle: "Ta demande pour rejoindre le match a bien été envoyée.",
                        success: true,
                        title: "Tu y es presque&nbsp;!",
                        tunnelType: "SUPERSUB",
                    },
                })
            }

            getSuperSubsRequest(krAnswer?.transactions?.[0]?.metadata?.supersubRequestId).then((res) => {
                displayConfirm(res?.data?.data)
            })
        } else if (resourceType === "OFFER_SUPERSUB_PLACE") {
            const displayConfirm = (res) => {
                navigate(urlSelector("/confirmcommand"), {
                    state: {
                        confirmedSubscription: res,
                        customContentIdentifier: "locations",
                        customLink: "/reservations/locations/avenir/candidatureSupersub/" + krAnswer?.transactions?.[0]?.metadata?.reservationId,
                        doRefresh: true,
                        krAnswer: krAnswer,
                        message: "freeSupersubConfirm",
                        subtitle: "Nous te confirmons la convocation de ton SuperSub.",
                        success: true,
                        title: "Félicitations&nbsp;!",
                        tunnelType: "SUPERSUB",
                    },
                })
            }
            await getLocation(queryParameters.get(krAnswer?.transactions?.[0]?.metadata?.reservationId)).then((res) => {
                const lastRequest = res.supersubRequests[res.supersubRequests.length - 1]
                if (res.amountDue === 0 && res.supersubRequests && res.supersubRequests.length > 0 && lastRequest.status !== 1) {
                    completeSuperSubRequest(lastRequest.id).then(() => displayConfirm(res))
                } else {
                    displayConfirm(res)
                }
            })
        } else if (resourceType === "REGISTERCB") {
            navigate(urlSelector("/profil/moyensDePaiement"))
        }
    }

    const kidStageSubscription = async (props) => {
        if (queryParameters.get("type")) {
            props.stageTypeProps = queryParameters.get("type")
        }
        if (queryParameters.get("center")) {
            props.centerIdProps = queryParameters.get("center")
        }
        props.initialStep = 0
        if (queryParameters.get("type") || queryParameters.get("center")) {
            navigate(themeFeatures([ "/kids/stagesDeFoot/new", "/padel/coursStages/inscription" ]))
        }
    }

    const kidSchoolSubscription = async (props) => {
        if (queryParameters.get("type")) {
            props.schoolType = queryParameters.get("type")
        }
        if (queryParameters.get("center")) {
            props.centerId = queryParameters.get("center")
        }
        navigate(themeFeatures([ "/kids/ecoleDeFoot/new", "/padel/coursStages/inscription" ]), { state: props })
    }

    useEffect(() => {
        setIsLoading(true)
        handle().then(() => setIsLoading(false)).catch(() => setIsLoading(false))
    }, [ user, state ])

    return { isLoading }
}

export default useRedirections
