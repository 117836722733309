import dayjs from "dayjs"
import { string } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

import PlayerSelectionPreform from "./components/PlayerSelectionPreform"
import KidAdultForm from "../../../../components/KidAdultForm/KidAdultForm"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import { useSchoolTrainingCurrent } from "../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import Step from "../../../../components/stepsManagement/Step"
import { addUserKid, createUserPlayerProfile, editUserKid, getUser, getUserKids, modifyUserPlayerProfile } from "../../../../globalAPI/api"
import useMediaPath from "../../../../hooks/useMediaPath"
import { editUser } from "../../../profil/api"

import "./PlayerSelection.scss"

export default function PlayerSelection(props) {
    const { title } = props
    const user = useSelector(state => state.userLoaded)

    const [ isLoadingKids, setIsLoadingKids ] = useState(true)
    const mediaPath = useMediaPath()
    const playerSelectionRef = useRef(null)
    
    const currentSelection = useSchoolTrainingCurrent()
    const { value: { selectedKid, selectedAreaId, isAdult, kids, kidForm, adultForm }, setValue: setCurrentSelection } = currentSelection || {}
    const { firstname, lastname, birthDate, gender } = kidForm

    useEffect(() => {
        playerSelectionRef?.current?.validate()
        if (!kids) {
            getUserKids().then(
                (response) => {
                    setCurrentSelection(prev => ({ ...prev, kids: response?.reverse() }))
                    setIsLoadingKids(false)
                    playerSelectionRef?.current?.validate()
                },
            )
        } else {
            setIsLoadingKids(false)
        }
        getUser().then(res => {
            const schoolSubscriptions = res?.data?.data?.schoolRegistrations?.map(registration => ({ kid: { id: registration.kidId }, school: { id: registration?.entityId } }))
            const stageSubscriptions = res?.data?.data?.trainingRegistrations?.map(registration => ({ kid: { id: registration.kidId }, training: { id: registration?.entityId } }))
            setCurrentSelection(prev => ({ ...prev, schoolSubscriptions: schoolSubscriptions ?? [], stageSubscriptions: stageSubscriptions ?? [] }))
            playerSelectionRef?.current?.validate()
        })
        
    }, [])

    const handleKid = () => {
        const childData = {
            birthDate: dayjs(birthDate).format("YYYY-MM-DDT00:00:00"),
            firstName: firstname,
            genderValue: gender,
            lastName: lastname,
        }

        if (!selectedKid?.id) {
            addUserKid(childData).then(
                (res) => {
                    getUserKids().then(
                        (response) => {
                            setCurrentSelection(prev => ({ ...prev, kids: response?.reverse(), selectedKid: response.find(kid => kid.id === res.id) }))
                            setIsLoadingKids(false)
                        },
                    )
                },
            )
        } else if (selectedKid?.firstName !== firstname
            || selectedKid?.lastName !== lastname
            || selectedKid.genderValue !== parseInt(gender)
            || (selectedKid?.birthDate?.split("/")?.reverse()?.join("-") !== birthDate)
        ) {
            editUserKid(
                selectedKid.id,
                childData,
            ).then(
                () => {
                    getUserKids().then(
                        (response) => {
                            setCurrentSelection(prev => ({ ...prev, kids: response?.reverse(), selectedKid: response.find(kid => kid.id === selectedKid.id) }))
                        },
                    )
                },
            )
        }
    }
    
    const api = user.playerProfile?.playerProfileId ? modifyUserPlayerProfile : createUserPlayerProfile
    
    const handleAdult = () => {
        const formBirthdate = dayjs(adultForm?.birthdate).format("YYYY-MM-DDT00:00:00")
        if (formBirthdate !== user.birthdate || user.title !== parseInt(adultForm.title)) {
            const newUser = {
                ...user,
                birthdate: formBirthdate,
                title: adultForm.title,
            }
            editUser(newUser)
        }
        if (user.playerProfile.level !== parseInt(adultForm.level) || user.playerProfile.ranking !== adultForm.ranking) {
            const profil = {
                ...user.playerProfile,
                level: adultForm.level,
                ranking: adultForm.ranking,
            }
            api(user.id, profil)
        }
    }

    const handleChange = () => {
        if (!isAdult) {
            handleKid()
        } else {
            handleAdult()
        }
    }

    useEffect(() => {
        if (selectedAreaId && ((isAdult && adultForm.birthdate && adultForm.title) || (!isAdult && firstname && lastname && birthDate && gender))) {
            playerSelectionRef?.current?.validate()
            if (isAdult) {
                playerSelectionRef?.current?.setRecap(
                    <div>
                        {user.firstname}
                        <br />
                        {user.lastname}
                    </div>,
                )
            } else {
                playerSelectionRef?.current?.setRecap(
                    <div>
                        {firstname}
                        <br />
                        {lastname}
                    </div>,
                )
            }
        } else {
            playerSelectionRef?.current?.unvalidate()
        }
    }, [ selectedAreaId, isAdult, kidForm, adultForm ])

    return (
        <Step
            {...props}
            title={title}
            help={<PhoneCallHelp tunnelType="TRAINING"/> }
            helpId={"playerSelectionHelp"}
            className="playerSelection"
            noReturn
            onValidation={handleChange}
            nextText={<span className="customText">Suivant</span>}
            ref={playerSelectionRef}
        >
            <PlayerSelectionPreform />
            <div className="formsHolder noHPadding c-row justify-center layoutSmall">
                <div className="c-col c-col--8 c-col--sm--12">
                    {!selectedAreaId && (
                        <p className={"emptyAlert"}>
                            <img src={mediaPath([ "/assets/icons/notifications.svg", "/assets/images/padel/icons/icons-info.svg" ])} className="alert"/>
                            Sélectionne une ville pour poursuivre ton inscription.
                        </p>
                    )}
                    <KidAdultForm isLoadingKids={isLoadingKids} />
                </div>
            </div>
        </Step>
    )
}

PlayerSelection.propTypes = {
    title: string,
}
