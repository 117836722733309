import { useEffect, useState } from "react"

import DebtBillDisplay from "./components/DebtBillDisplay"
import PageHeader from "../../components/layout/pageHeader/PageHeader"
import Preloader from "../../components/loaders/preloader/preloader"
import { getArdoises, getUsCenters } from "../../globalAPI/api"
import useMediaPath from "../../hooks/useMediaPath"
import useResetScroll from "../../hooks/useResetScroll"

import "./Debt.scss"

const Debt = () => {
    useResetScroll()
    const mediaPath = useMediaPath()
    const [ centers, setCenters ] = useState([])
    const [ ardoises, setArdoises ] = useState([])
    const [ isLoading, setIsLoading ] = useState(true)

    const dateSort = (a, b) => {
        const startDateA = a.date.split("/").reverse().join("-")
        const startDateB = b.date.split("/").reverse().join("-")
        return startDateA === startDateB ?
            0
            :
            startDateA < startDateB ?
                1
                :
                -1
    }

    useEffect(
        () => {
            getUsCenters().then(
                (res) => {
                    if (res && res.data) {
                        setCenters(res.data)
                    }
                },
            )
            getArdoises().then(
                res => {
                    setArdoises(res.items?.filter(
                        ardoise => ardoise.status === 1,
                    ).sort(dateSort))
                    setIsLoading(false)
                },
            )
        },[],
    )

    return (
        <div className="debt">
            <PageHeader
                title={"Ardoises"}
                goBack={false}
            />
            <div className="c-row">
                <div className="c-col c-col--10 c-col--sm--12 no-padding ">
                    {isLoading && <Preloader fixed={true} />}
                    {!isLoading && ardoises.length > 0 && (
                        <div className="debtsListing">
                            {ardoises.map(
                                ardoise => (
                                    <DebtBillDisplay
                                        key={ardoise.id + ardoise.date + ardoise.centerId}
                                        item={ardoise}
                                        centerName={centers && centers.length > 0 && centers.find(center => center.id === ardoise.centerId)?.name}
                                        tunnelType="DEBT"
                                    />
                                ),
                            )}
                        </div>
                    )}
                    {!isLoading && ardoises.length === 0 && (
                        <div className="noDebt">
                            <img src={mediaPath([ "/assets/images/noBill.svg", "/assets/images/padel/icons/icons-not-bill.svg" ])} />
                            <p>Tu n'as aucune ardoise.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Debt
