import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock-upgrade"
import classNames from "classnames"
import { format, isSameDay } from "date-fns"
import { fr } from "date-fns/locale"
import PropTypes, { array, arrayOf, bool, func, node, number, object, oneOfType, string } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { DayPicker } from "react-day-picker"
import { IMaskInput } from "react-imask"
import { useDispatch } from "react-redux"

import { useAssets } from "../../../providers/AssetsProvider.jsx"
import { changeDateValue } from "../../../store/actions/scaffold"
import { TooltipLight } from "../../tooltip/tooltipLight/tooltipLight"

import "./InputDate.scss"
import "../../Inputs/TextInput/TextInput.scss"
import "react-day-picker/dist/style.css"

const InputDate = ({
    label,
    value,
    defaultValue,
    bind,
    immutableValue,
    onChange,
    required,
    min,
    max,
    useDatePicker,
    customClass,
    tooltip,
    placeholder,
    daywp,
    monthwp,
    yearwp,
    customIcon,
    disabled,
    datePickerCallback,
    iconSize,
    error,
    position,
}) => {
    const datePicker = useRef(null)
    const dayPicker = useRef(null)
    const dispatch = useDispatch()
    const backgroundSelector = customIcon ? customIcon : null
    const [ showDatePicker, setShowDatePicker ] = useState(false)
    const [ isClicked, setIsClicked ] = useState(false)
    const [ touchStart, setTouchStart ] = useState(null)
    const [ touchEnd, setTouchEnd ] = useState(null)
    const [ month, setMonth ] = useState(getInputDate())
    const { m } = useAssets()

    const minSwipeDistance = 50

    const onTouchStart = (e) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!showDatePicker || !touchStart || !touchEnd) {
            return
        }
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isRightSwipe) {
            setMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1))
        }
        if (isLeftSwipe) {
            setMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1))
        }
    }

    let dayFormat = parseInt(daywp)
    let monthFormat = parseInt(monthwp) - 1
    let yearFormat = parseInt(yearwp)
    let dateWpFormat
    if (dayFormat && monthFormat && yearFormat) {
        dateWpFormat = new Date(yearFormat, monthFormat, dayFormat, 1)
    }

    if (dateWpFormat && isClicked === false) {
        selectDay(dateWpFormat)
    }

    if (bind) {
        if (bind[2] === undefined) {
            value = bind[0]
            onChange = (v) => {
                if (v === "") {
                    bind[1](null)
                } else if (v.length === 10) {
                    const newValue = new Date(v.split("/").reverse().join("-"))
                    if (newValue instanceof Date && !isNaN(newValue)) {
                        bind[1](newValue)
                    }
                }
                dispatch(changeDateValue(v))
            }
        } else {
            if (bind[0]) {
                bind[0] = bind[0].split("-").reverse().join("/")
            }
            value = bind[0]
            onChange = (v) => {
                if (v === "") {
                    bind[2]([ bind[1] ], null)
                } else if (v.length === 10) {
                    const newValue = v.split("/").reverse().join("-")
                    if (new Date(newValue) instanceof Date && !isNaN(new Date(newValue))) {
                        bind[2]([ bind[1] ], newValue)
                    }
                }
                dispatch(changeDateValue(v))
            }
        }
    }

    if (value) {
        if (typeof value === "object" && value.constructor.name === "Date") {
            value = format(value, "dd/MM/yyyy")
        }
    }

    if (datePicker.current) {
        datePicker.current.max = format(new Date(), "yyyy-MM-dd")
    }

    function getInputDate() {
        if (value) {
            let dmy = value.split("/")
            return new Date(dmy[2], dmy[1] - 1, dmy[0])
        } else {
            return new Date()
        }
    }

    function selectDay(day, modifiers = {}) {
        setIsClicked(true)
        if (modifiers.disabled) {
            return
        }

        let formattedDate = format(day, "yyyy-MM-dd")
        if (bind) {
            if (bind[2] !== undefined) {
                bind[2]([ bind[1] ], formattedDate)
            } else {
                bind[1](day)
            }
        } else {
            value = formattedDate
        }
        if (datePickerCallback) {
            datePickerCallback(false)
        }
        setShowDatePicker(false)
        document.removeEventListener("click", listenerClickOutside)
    }

    function isSelectedDay(day) {
        return isSameDay(day, getInputDate())
    }

    function listenerClickOutside(event) {
        event.stopPropagation()
        event.preventDefault()
        if (dayPicker.current && !dayPicker.current.contains(event.target)) {
            setShowDatePicker(false)
            if (datePickerCallback) {
                datePickerCallback(false)
            }
            document.removeEventListener("click", listenerClickOutside)
        } else if (!dayPicker.current) {
            document.removeEventListener("click", listenerClickOutside)
        }
    }

    let isReadOnly = false
    isReadOnly = immutableValue ? !!defaultValue : undefined
    isReadOnly = useDatePicker ? true : isReadOnly

    useEffect(() => {
        showDatePicker
            ? document.addEventListener("click", listenerClickOutside)
            : document.removeEventListener("click", listenerClickOutside)

        // body scrpll lock
        if (showDatePicker) {
            disableBodyScroll(dayPicker.current)
        } else {
            clearAllBodyScrollLocks()
        }

    }, [ showDatePicker ])

    return (
        <div
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            className={classNames("field-container date-input", {
                [customClass]: customClass,
                noLabel: !label,
            })}
        >
            <div className={classNames("text-input-wrapper", {
                disabled: disabled,
                error: error,
            })}>
                <div
                    className={"text-input"}
                    style={{
                        backgroundImage: backgroundSelector,
                    }}
                >
                    {label &&
                        <label className="text-input-label">{ label }</label>
                    }
                    <IMaskInput
                        onClick={useDatePicker && !showDatePicker
                            ? (e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                if (datePickerCallback) {
                                    datePickerCallback(!showDatePicker)
                                }
                                setShowDatePicker(true)
                            }
                            : undefined
                        }
                        mask={Date}
                        pattern={"d{/}`m{/}`Y"}
                        format={(date) => {
                            return format(date, "dd/MM/yyyy")
                        }}
                        parse={(str) => {
                            const yearMonthDay = str.split("/")
                            return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0])
                        }}
                        inputMode="numeric"
                        min={min ? new Date(min.getTime() - 24 * 60 * 60000) : undefined}
                        max={max}
                        className="text-input-field"
                        type="text"
                        value={value}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        onAccept={onChange}
                        readOnly={isReadOnly}
                        required={required}
                        placeholder={placeholder}
                        ref={datePicker}
                    />
                </div>
                <div className={classNames("input-actions", {
                    [iconSize]:iconSize,
                })}>
                    {!customIcon ? (
                        <div
                            className={"input-action"}
                            onClick={useDatePicker
                                ? (e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (useDatePicker) {
                                        !showDatePicker
                                            ? document.addEventListener("click", listenerClickOutside)
                                            : document.removeEventListener("click", listenerClickOutside)
                                        if (datePickerCallback) {
                                            datePickerCallback(!showDatePicker)
                                        }
                                        setShowDatePicker(!showDatePicker)
                                    }
                                }
                                : undefined
                            }
                        >
                            <img
                                alt=""
                                src={m("icons.inputs.calendar")}
                                // src={mediaPath([ "/assets/icons/icon-calendar.svg", "/assets/images/padel/icons/icon-calendar.svg" ])}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setShowDatePicker((prev) => !prev)
                                    !showDatePicker
                                        ? document.addEventListener("click", listenerClickOutside)
                                        : document.removeEventListener("click", listenerClickOutside)
                                    if (datePickerCallback) {
                                        datePickerCallback(!showDatePicker)
                                    }
                                }}
                            />
                        </div>
                    ) : null}
                    {tooltip && (
                        <div className={"input-action" }>
                            <TooltipLight text={tooltip}>
                                <img
                                    alt="helper-text"
                                    src={m("icons.inputs.questionMark")}
                                />
                            </TooltipLight>
                        </div>
                    )}
                </div>
            </div>
            {showDatePicker && (
                <>
                    <div
                        className="datepicker-modal-overlay"
                        onClick={() => {
                            setShowDatePicker(false)
                            if (datePickerCallback) {
                                datePickerCallback(false)
                            }
                        }}
                    />
                    <div
                        ref={dayPicker}
                    >
                        <DayPicker
                            modifiers={{
                                disabled: {
                                    after: max,
                                    before: min,
                                },
                            }}
                            locale={fr}
                            disabled={[ {
                                after: max,
                                before: min,
                            } ]}
                            className={classNames({
                                [customClass]: customClass,
                                [position]: position,
                            })}
                            onDayClick={selectDay}
                            enableOutsideDaysClick
                            month={month}
                            onMonthChange={setMonth}
                            selected={isSelectedDay}
                            captionLayout={"dropdown"}
                            fromDate={min ? min : new Date(new Date().getFullYear() - 75, 0, 1)}
                            toDate={max ? max : new Date(new Date().getFullYear() + 5, 11, 31)}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
InputDate.propTypes = {
    bind: oneOfType([ string, number, array ]),
    checkValue: bool,
    customClass: string,
    customIcon: oneOfType([ string, bool ]),
    datePickerCallback: oneOfType([ bool, func ]),
    daywp: number,
    defaultValue: bool,
    disabled: bool,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    iconSize: PropTypes.oneOf([ "small", "medium", "large" ]),
    immutableValue: bool,
    label: oneOfType([ string, node, arrayOf(node) ]),
    max: object,
    min: object,
    monthwp: number,
    onBlur: oneOfType([ bool, func ]),
    onChange: func,
    onFocus: oneOfType([ bool, func ]),
    placeholder: string,
    position: oneOfType([ bool, string ]),
    required: bool,
    tooltip: string,
    useDatePicker: bool,
    value: arrayOf([ string, number, func ]),
    yearwp: number,
}

InputDate.defaultProps = {
    iconSize: "medium",
}

InputDate.displayName = "InputDate"

export default InputDate
