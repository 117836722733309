import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import { deleteAccount, editUser, modifyPassword } from "./api"
import PasswordComplexity from "../../components/authentification/passwordComplexity/passwordComplexity"
import {
    searchCitiesByCityNameGouv,
    searchCitiesByDepartmentGouv,
} from "../../components/authentification/signupForm/api"
import ButtonCta from "../../components/Buttons/button/button-cta"
import MapToggle from "../../components/Buttons/mapToggle/mapToggle"
import countries from "../../components/CountrySelection/assets/countries"
import CountrySelection from "../../components/CountrySelection/CountrySelection"
import { Autocomplete } from "../../components/Forms/Autocomplete/Autocomplete.jsx"
import InputDate from "../../components/Forms/InputDate/InputDate"
import { SelectInput } from "../../components/Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../components/Inputs/TextInput/TextInput.jsx"
import PageHeader from "../../components/layout/pageHeader/PageHeader"
import Modal from "../../components/Modal/Modal"
import Preloader from "../../entries/main/../../components/loaders/preloader/preloader"
import { getUsCenters, getUser } from "../../globalAPI/api"
import useUrbanLanguage from "../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../hooks/useMediaPath"
import useResetScroll from "../../hooks/useResetScroll"
import useThemeFeatures from "../../hooks/useThemeFeatures"
import useThemeHeader from "../../hooks/useThemeHeader"
import TitleValues from "../../model/enums/User/TitleValues"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import usePath from "../../routes/services/usePath"
import disconnectUser from "../../services/disconnectUser"
import reduceStringToLength from "../../services/reduceStringToLength"

import "./EditPersonnalInformations.scss"

function EditPersonnalInformations() {
    useResetScroll()
    const path = usePath()
    const mediaPath = useMediaPath()
    const navigate = useNavigate()
    const location = useLocation()
    const themeFeatures = useThemeFeatures()
    const themeHeader = useThemeHeader()
    const userLoaded = useSelector(state => state.userLoaded)

    function formatDate(dateStr) {
        return dateStr ? dateStr.split("T")[0].split("-").reverse().join("/") : ""
    }

    const [ firstName, setFirstName ] = useState(userLoaded ? userLoaded.firstname : "")
    const [ lastName, setLastName ] = useState(userLoaded ? userLoaded.lastname : "")
    const [ birthDate, setBirthDate ] = useState(userLoaded ? userLoaded.birthdate ? new Date(userLoaded.birthdate) : undefined : undefined)
    const [ address, setAddress ] = useState(userLoaded ? userLoaded.street : "")
    const [ centerId, setCenterId ] = useState(userLoaded ? userLoaded.centerId : "")
    const [ centersOptions, setCentersOptions ] = useState([])
    const [ country, setCountry ] = useState(
        countries.find(c => parseInt(c.iso) === userLoaded.country) ?? {
            code: "fr",
            iso: 250,
            name: "France",
        },
    )
    const [ city, setCity ] = useState(userLoaded ?
        { codesPostaux: [ userLoaded.zip ? userLoaded.zip : null ], nom : userLoaded.city ? userLoaded.city : null } : {})
    const [ civility, setCivility ] = useState(userLoaded ? associateGender() : "")
    const [ newsletter, setNewsletter ] = useState(userLoaded ? userLoaded.newsletter : false)
    const [ partnerNewsletter, setPartnerNewsletter ] = useState(userLoaded ? userLoaded.partnerNewsletter : false)
    const [ padelNewsletter, setPadelNewsletter ] = useState(userLoaded?.padelNewsletter)
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ errorPassword, setErrorPassword ] = useState(null)
    const [ clickOnValidate, setClickOnValidate ] = useState(false)

    function hasInfoChange() {
        return firstName !== userLoaded.firstname ||
            lastName !== userLoaded.lastname ||
            (birthDate && userLoaded.birthdate && (birthDate !== formatDate(userLoaded.birthdate))) ||
            (birthDate && !userLoaded.birthdate) ||
            address !== userLoaded.street ||
            centerId !== userLoaded.centerId ||
            city.codesPostaux[0] !== userLoaded.zip ||
            city.nom !== userLoaded.city ||
            civility !== userLoaded.title ||
            newsletter !== userLoaded.newsletter ||
            padelNewsletter !== userLoaded.padelNewsletter ||
            partnerNewsletter !== userLoaded.partnerNewsletter ||
            parseInt(country.iso) !== userLoaded.country
        
    }

    const [ oldPassword, setOldPassword ] = useState(null)
    const [ newPassword, setNewPassword ] = useState(false)
    const [ complexity, setComplexity ] = useState(0)

    useEffect(() => {
        initCenters()
        if (userLoaded && userLoaded.country) {
            const userCountry = countries.find(country => parseInt(country.iso) === userLoaded.country)
            setCountry(userCountry)
        }
    }, [])

    function associateGender() {
        let genderData = ""
        if (userLoaded.title === 1) {
            genderData = "male"
        } else if (userLoaded.title === 2) {
            genderData = "female"
        } else if (userLoaded.title === 9) {
            genderData = "other"
        }
        return genderData
    }

    function saveUser() {
        setLoading(true)
        const newUser = Object.assign({}, userLoaded)
        newUser.firstname = reduceStringToLength(firstName, 63)
        newUser.lastname = reduceStringToLength(lastName, 63)

        let genderData = 0
        if (civility === "male") {
            genderData = 1
        } else if (civility === "female") {
            genderData = 2
        } else if (civility === "other") {
            genderData = 9
        }

        if (birthDate) {
            newUser.birthdate = birthDate instanceof Date ?
                birthDate.toLocaleDateString("en-EN")
                :
                birthDate
            
        } else {
            newUser.birthdate = ""
        }
        newUser.street = address
        newUser.centerId = centerId
        newUser.zip = city.codesPostaux[0] ? city.codesPostaux[0].toUpperCase() : null
        newUser.city = city.nom
        newUser.newsLetter = newsletter
        newUser.padelNewsletter = padelNewsletter
        newUser.partnerNewsletter = partnerNewsletter
        newUser.title = genderData
        newUser.country = parseInt(country.iso)
        newUser.jobTitle = userLoaded.jobTitle

        editUser(newUser).then(
            async (res) => {
                if (res?.data?.data?.Message) {
                    await setError(res.data.data.Message)
                } else {
                    await setError(null)
                }

                if (!oldPassword || !newPassword) {
                    setLoading(false)
                    if (res?.data?.data && !res.data.data.Message) {
                        goBack()
                    }
                } else if (oldPassword && newPassword) {
                    changePassword()
                }
            },
        )
    }

    function goBack() {
        setLoading(true)
        getUser().then(
            () => {
                setLoading(false)
                navigate(-1)
            },
        )
    }

    function changePassword() {
        setLoading(true)
        modifyPassword(oldPassword, newPassword, themeHeader).then(
            (res) => {
                setLoading(false)
                if (res.data && res.data.data && res.data.data.Message) {
                    setErrorPassword(res.data.data.Message)
                } else {
                    setErrorPassword(null)
                    disconnectUser(path("/login"))
                }
            },
        )
    }

    function applyChanges() {
        setClickOnValidate(true)

        if (hasInfoChange()) {
            saveUser()
            return
        }

        if (oldPassword && newPassword) {
            changePassword()
        }
    }

    function deleteAccountAndDisconnect() {
        deleteAccount().then(
            () => {
                disconnectUser(path("/login"))
            },
        )
    }

    function initCenters() {
        let centersArr = []
        getUsCenters(themeHeader).then((res) => {
            if (res && res.data) {
                const currentTheme = location?.pathname?.includes("/padel/") ? "theme-padel" : "theme-soccer"
                const padelTypes = [ 12, 14, 15 ]
                const havePadel = center => center?.resourceTypes?.some(
                    type => padelTypes.find(id => id === parseInt(type.key)),
                )
                const centersToExclude = [ 1, 38, 36, 35, 23, 18, 17, 8, 40, 21 ]
                res.data.forEach((center) => {
                    if (!centersToExclude.includes(center.id) && (currentTheme !== "theme-padel" || (currentTheme === "theme-padel" && havePadel(center)))) {
                        centersArr.push({ adresse: center. address, label: center.name,
                            lat: center.latitude, lng: center.longitude, value: center.id })
                    }
                })
            }
            setCentersOptions(centersArr)
        })
    }

    function searchCitiesByPostalCode(val) {
        if (val && val.length > 0) {
            let searchTerms = val
            if (val.length === 1) {
                searchTerms = val + "0"
            }

            if (searchTerms.length === 3 && !isNaN(searchTerms)) {
                return searchCitiesByDepartmentGouv(searchTerms.substring(0, 2))
            } else if (searchTerms.length < 3) {
                return new Promise((resolve) => {
                    resolve({
                        data: [],
                    })
                })
            } else {
                return new Promise((resolve) => {
                    resolve()
                })
            }
        }
    }

    function searchCitiesByName(val) {
        if (val && val.length > 0) {
            return searchCitiesByCityNameGouv(val)
        } else {
            return new Promise((resolve) => {
                resolve({
                    data: [],
                })
            })
        }
        
    }

    function initPostalCodeAPI(searchTerms) {
        if (searchTerms && searchTerms.length >= 2) {
            return searchCitiesByDepartmentGouv(searchTerms.substring(0, 2))
        } else {
            return new Promise((resolve) => {
                resolve({
                    data: [],
                })
            })
        }
    }

    const openDeletePopIn = () => {
        ModalHandler.show(DeleteAccountPopIn, {
            deleteAccountAndDisconnect: deleteAccountAndDisconnect,
        })
    }

    return (
        <div className="p-editPersonnalInformations">
            <PageHeader title="Éditer mes informations" goBack={path("/profil/coordonnees")} />
            {loading && <Preloader fixed={true} />}
            <div className="c-row justify-center">
                <div className="c-col c-col--8 c-col--m--12">
                    <div className="greyCard">
                        <div className="inputsWrapper">
                            <div className="first-part">
                                <TextInput
                                    label={"Prénom"}
                                    maxLength={63}
                                    value={firstName}
                                    onChange={setFirstName}
                                    required
                                    error={error === "Prénom incorrect" || (clickOnValidate && !firstName)}
                                />
                                <TextInput
                                    label={"Nom"}
                                    maxLength={63}
                                    value={lastName}
                                    onChange={setLastName}
                                    required
                                    error={error === "Nom incorrect" || (clickOnValidate && !lastName)}
                                />
                                <InputDate
                                    label="Date de naissance"
                                    bind={[ birthDate, setBirthDate ]} 
                                    max={new Date()}
                                    useDatePicker={false}
                                    tooltip="Pour l'inscription d'un enfant, la date de naissance à renseigner ci-dessous est celle du représentant légal."
                                    customClass="customDayPicker"
                                    placeholder={themeFeatures([ undefined, "JJ/MM/AAAA" ])}
                                    checkValue
                                />
                                <SelectInput
                                    id={"Civilité"}
                                    label={"Civilité"}
                                    options={TitleValues}
                                    value={civility}
                                    onChange={(event)=>{setCivility(event.target.value)}}
                                    placeholderOption="Genre"
                                />
                                <SelectInput
                                    id={"Centre"}
                                    label={"Centre"}
                                    options={centersOptions}
                                    value={centerId}
                                    onChange={
                                        (event) => {
                                            setCenterId(event.target.value)
                                        }
                                    }
                                    hasAfterSelect={
                                        (<MapToggle headerTitle="Choix du centre" markersData={centersOptions}
                                            cls={clickOnValidate && centerId === null ? "mapToggleError" : null}
                                            closeDialogCallBack={(id) => {
                                                setCenterId(id)
                                            }}/>)
                                    }
                                    placeholderOption="Centre"
                                    required={!!userLoaded.centerId}
                                />
                                <TextInput
                                    label={"Adresse"}
                                    value={address}
                                    onChange={setAddress}
                                    placeholder={"Saissisez votre adresse"}
                                />
                            </div>
                            <div className="country-zipcode">
                                <CountrySelection
                                    selectedCountry={country}
                                    setSelectedCountry={setCountry}
                                />
                                <Autocomplete
                                    label="Code Postal"
                                    initialValue={city}
                                    onlyNumbers={country && country.code === "fr" }
                                    initFunction={userLoaded && userLoaded.country !== 250 ? () => initPostalCodeAPI(city.codesPostaux[0]) : undefined}
                                    maxLength={country && country.code === "fr" ? 5 : null}
                                    apiCall={country && country.code === "fr" ? (val) => {
                                        return searchCitiesByPostalCode(val)
                                    } : undefined}
                                    localFilter={({ val, option }) => {
                                        return option.codesPostaux[0].includes(val)
                                    }}
                                    getOptionLabel={(option) => option.codesPostaux ? option.codesPostaux[0] : ""}
                                    onChange={(val) => {
                                        if (val && val.nom) {
                                            setCity(val)
                                        } else {
                                            const cp = city && city.codesPostaux ? city.codesPostaux[0] : null
                                            setCity({ codesPostaux: [ cp ], nom: val ? val : null })
                                        }
                                    }}
                                    displayFunction={(option) =>  {
                                        return <>{option.codesPostaux[0]} {option.nom}</>
                                    }}
                                />
                            </div>

                            <Autocomplete
                                label="Ville"
                                initialValue={city}
                                initFunction={country && country.code === "fr" ? () => {
                                    return searchCitiesByName(city.nom)
                                } : undefined}
                                apiCall={country && country.code === "fr" ? (val) => {
                                    return  searchCitiesByName(val)
                                } : undefined}
                                debounce
                                getOptionLabel={(option) => option.nom ? option.nom : ""}
                                onChange={(val) => {
                                    setCity(val)
                                    if (val && val.nom) {
                                        setCity(val)
                                    } else {
                                        const cp = city && city.codesPostaux ? city.codesPostaux[0] : null
                                        setCity({ codesPostaux: [ cp ], nom: val ? val : null })
                                    }
                                }}
                                displayFunction={(option) =>  {
                                    return <>{option.nom} {option.codesPostaux[0]}</>
                                }}
                            />
                        </div>

                        <div className="v-container">

                            {
                                userLoaded.authenticationTypes && 
                                userLoaded.authenticationTypes.findIndex((authType) => authType === 0) !== -1 &&

                                    (<React.Fragment>
                                        <TextInput
                                            required={true}
                                            label="Ancien mot de passe"
                                            onChange={setOldPassword}
                                            error={errorPassword === "L'ancien mot de passe est invalide."}
                                            type="password"
                                        />

                                        <PasswordComplexity
                                            label="Nouveau mot de passe"
                                            onPasswordChange={(val) => setNewPassword(val)}
                                            onComplexityChange={
                                                (data) => {
                                                    setComplexity(data)
                                                }
                                            }
                                        />
                                    </React.Fragment>)
                            }
                            <div className="newsletters">
                                <h2>Profite de cadeaux, codes promos et remises en recevant les meilleures offres et exclusivités :</h2>
                                <div className="c-inputHolder--radioSquare">
                                    <input
                                        type="checkbox" id="padel_exlu"
                                        name="padelNewsletter" value={padelNewsletter}
                                        onChange={
                                            () => {
                                                setPadelNewsletter(!padelNewsletter)
                                            }
                                        }
                                        defaultChecked={padelNewsletter}
                                        checked={padelNewsletter}
                                    />
                                    <label htmlFor="padel_exlu">UrbanPadel</label>
                                </div>
                                <div className="c-inputHolder--radioSquare">
                                    <input
                                        type="checkbox" id="mailing_exclu"
                                        name="newsletter" value={newsletter}
                                        onChange={
                                            () => {
                                                setNewsletter(!newsletter)
                                            }
                                        }
                                        defaultChecked={newsletter}
                                        checked={newsletter}
                                    />
                                    <label htmlFor="mailing_exclu">UrbanSoccer</label>
                                </div>
                                <div className="c-inputHolder--radioSquare">
                                    <input
                                        type="checkbox" id="partner_exclu"
                                        name="partnerNewsletter" value={partnerNewsletter}
                                        onChange={
                                            () => {
                                                setPartnerNewsletter(!partnerNewsletter)
                                            }
                                        }
                                        defaultChecked={partnerNewsletter}
                                        checked={partnerNewsletter}
                                    />
                                    <label htmlFor="partner_exclu">Partenaires</label>
                                </div>
                            </div>

                            <ButtonCta
                                text="Enregistrer"
                                onClick={applyChanges}
                                disabled={
                                    (
                                        hasInfoChange() === false && (!oldPassword && !newPassword)
                                    ) 
                                    ||
                                    (
                                        (!oldPassword && newPassword) ||
                                        (oldPassword && !newPassword) || 
                                        (oldPassword && newPassword && complexity <= 2)
                                    )
                                }
                            />

                            <div className="link-orange" onClick={
                                () => {
                                    openDeletePopIn()
                                }
                            }>
                                Supprimer mon compte
                            </div>

                            {
                                error &&
                                    (<div className="errorLabel">
                                        <img className="iconError" src={mediaPath([ "/assets/icons/errorNotif.svg", "/assets/images/padel/icons/errorNotif.svg" ])} />
                                        <div>
                                            {
                                                error
                                            }
                                        </div>
                                    </div>)
                            }

                            {
                                errorPassword &&
                                    (<div className="errorLabel">
                                        <img className="iconError" src={mediaPath([ "/assets/icons/errorNotif.svg", "/assets/images/padel/icons/errorNotif.svg" ])} />
                                        <div>
                                            {
                                                errorPassword
                                            }
                                        </div>
                                    </div>)
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export const DeleteAccountPopIn = ModalHandler.create(({ visible, hide, deleteAccountAndDisconnect }) => {
    const { tu } = useUrbanLanguage()
    const [ isButtonDisabled, setIsButtonDisabled ] = useState(false)

    const handleDeleteClick = () => {
        setIsButtonDisabled(true)
        setTimeout(() => {
            setIsButtonDisabled(false)
        }, 4000)
        deleteAccountAndDisconnect()
    }

    return (<Modal
        isOpen={visible}
        onClose={hide}
        className="modal-content-delete-account"
    >
        <header>Es-tu sûr ?</header>
        <section className="popin--content">
            Tu n'aimes plus le {tu("otherName")} et tu veux supprimer ton compte ?
            <div className="btn-holder">
                <ButtonCta
                    isAlt={true}
                    onClick={hide}
                    text="Non"
                />
                <ButtonCta
                    isAlt={true}
                    onClick={handleDeleteClick}
                    text="Oui"
                    disabled={isButtonDisabled}
                />
            </div>
        </section>
    </Modal>
    )
})

export default EditPersonnalInformations
