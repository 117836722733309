import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
    getSortedEquipments,
} from "../../../../../components/reservation/Shop/New/service.js"
import { ShopStep } from "../../../../../components/reservation/Shop/New/ShopStep/ShopStep.jsx"
import useAddSearchParam from "../../../../../hooks/useAddSearchParams.js"
import resetScroll from "../../../../../services/resetScroll.js"
import { addProductToCart, removeProductFromCart } from "../../../../../store/cart/actions.js"
import { setFormEntry } from "../../../../../store/forms/actions.js"
import { nextStep, skipStep } from "../../../../../store/stepsManagement/actions.js"
import { addStageProduct, deleteStageProduct, getKidsStageDetail } from "../../api.js"

const tunnelType = "TRAINING"

export const StageDeFootProducts = () => {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ advertising, setAdvertising ] = useState(null)
    const [ catalogDiscount, setCatalogDiscount ] = useState(null)

    const { forms, cart, availableEquipment } = useSelector(state => ({
        availableEquipment: state.forms[ShopStep.formDataName]?.availableEquipment,
        cart: state.cart,
        checkSkipStepEDF: state.checkSkipStepEDF,
        forms: state.forms,
        stepManagement: state.stepManagement,
    }))

    const dispatch = useDispatch()
    const addSearchParams = useAddSearchParam()

    const stageSubscription = forms.ozmoSubscription.firstSubscription
    const hasCatalogProducts = forms.hasCatalogProducts
    const pickedProducts = stageSubscription?.catalogArticles ?? []

    useEffect(() => {
        resetScroll()
        const fetchKidsStageDetail = async () => {
            setIsLoading(true)
            try {
                const res = await getKidsStageDetail(stageSubscription.training.id)
                setAdvertising(res.data.data.advertising)
                setCatalogDiscount(res.data.metadata.catalogDiscounts?.[0])
                setInfoStage(res.data.data.catalogProducts)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error("Failed to fetch kids stage detail:", error)
            } finally {
                setIsLoading(false)
            }
        }

        if (!hasCatalogProducts) {
            dispatch(skipStep())
        } else {
            fetchKidsStageDetail()
        }
    }, [])

    const setInfoStage = (formatedAvailableEquipment = []) => {
        const products = getSortedEquipments(formatedAvailableEquipment)

        dispatch(setFormEntry(ShopStep.formDataName, {
            ...forms[ShopStep.formDataName],
            availableEquipment: products,
        }))

        stageSubscription.catalogArticles.forEach((element) => {
            if (!cart.equipments[element.product.id]) {
                const equipment = formatedAvailableEquipment.find((equipment) => equipment.id === element.product.id)
                let formattedSizes = {}
                equipment.sizes.forEach((size) => {
                    formattedSizes[size.Value] = size.Key
                })
                dispatch(addProductToCart(element.product.id, formattedSizes[element.size], element.quantity, element.product.price))
            }
        })
    }

    const addProduct = (product) => {
        let initialValue = 0
        const { size, count, product : { id } } = product
        const articleInCatalog = pickedProducts.find(
            x => x.sizeValue === parseInt(size) &&
                x.product.id === id,
        )

        if (articleInCatalog) {
            initialValue = articleInCatalog.quantity
        }
        setIsLoading(true)

        const handleStageProductAdd = () => {
            addStageProduct(
                stageSubscription.id,
                id,
                size,
                parseInt(count) + initialValue,
            ).then(
                (res) => {
                    setIsLoading(false)
                    dispatch(setFormEntry("ozmoSubscription", {
                        ...forms["ozmoSubscription"],
                        firstSubscription: res.data.data,
                    }))
                    dispatch(setFormEntry("avoirFormData", {
                        ...forms.avoirFormData,
                        recalcAvoir: true,
                    }))
                },
            )
        }

        if (initialValue !== 0) {
            deleteStageProduct(
                stageSubscription.id,
                id,
                size,
            ).then(handleStageProductAdd)
        } else {
            handleStageProductAdd()
        }
    }

    const removeProduct = (product) => {
        dispatch(removeProductFromCart(product.product.id, product.sizeValue + ""))

        setIsLoading(true)
        deleteStageProduct(stageSubscription.id, product.product.id, product.sizeValue).then(
            (res) => {
                setIsLoading(false)
                dispatch(setFormEntry(
                    "ozmoSubscription", {
                        ...forms["ozmoSubscription"],
                        firstSubscription: res,
                    },
                ))
                dispatch(setFormEntry("avoirFormData", {
                    ...forms.avoirFormData,
                    recalcAvoir: true,
                }))
            },
        ).catch(() => setIsLoading(false))
    }
    const subTotal = pickedProducts.reduce((acc, product) => acc + product.product.price * product.quantity, 0)
    return (
        <ShopStep
            tunnelType={tunnelType}
            addProduct={addProduct}
            advertising={advertising}
            availableProducts={availableEquipment}
            isLoading={isLoading}
            products={pickedProducts}
            discount={catalogDiscount}
            deleteProduct={removeProduct}
            checkBeforeNextStep={() => {
                addSearchParams(
                    "equipmentId",
                    forms?.TournamentRegistration?.catalogArticles?.length ?
                        forms?.TournamentRegistration?.catalogArticles[0]?.id : "none",
                )
                dispatch(nextStep())
            }}
            pricingInfos={{
                discountOnSubTotal: stageSubscription?.catalogDiscountAmount,
                subtotal: subTotal,
            }}
        />
    )

}
