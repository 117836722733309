import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import NoSchoolTraining from "./components/NoSchoolTraining/NoSchoolTraining"
import SlotChoicePreform from "./components/SlotChoicePreform/SlotChoicePreform"
import SlotChoiceSlots from "./components/SlotChoiceSlots/SlotChoiceSlots"
import ActiveFilters from "../../../../components/ActiveFilters/ActiveFilters"
import CheckBlock from "../../../../components/Forms/CheckBlock/CheckBlock"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import {
    useSchoolTrainingCurrent,
    useSchoolTrainingFilters,
    useSchoolTrainingTunnel,
} from "../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import Step from "../../../../components/stepsManagement/Step"
import { getKidsStages } from "../../../../globalAPI/api"
import { useDidomiEcommerceEvent } from "../../../../hooks/useDidomiDataLayer.js"
import useThemeFeatures from "../../../../hooks/useThemeFeatures.js"
import resetScroll from "../../../../services/resetScroll"
import TimeSort from "../../../../services/Sorting/TimeSort"
import { clearSchoolOptions } from "../../../../store/cart/actions"
import { clearAllForms, removeFormEntry, setFormEntry } from "../../../../store/forms/actions"
import { goToStep, nextStep } from "../../../../store/stepsManagement/actions"
import { cancelSchoolRegistration, getKidsSchools } from "../../../kids/ecoleDeFoot/api"
import { cancelStageRegistration } from "../../../kids/stageDeFoot/api"
import schoolTrainingRegistration from "../../functions/schoolTrainingRegistration"

import "./SlotChoice.scss"

export default function SlotChoice(props) {
    const user = useSelector(state => state.userLoaded)
    const currentStep = useSelector(state => state.stepManagement.currentStep)
    const ozmoSubscription = useSelector(state => state.forms.ozmoSubscription)
    const stepManagement = useSelector(state => state.stepManagement)

    const dispatch = useDispatch()

    const [ isLoading, setIsLoading ] = useState(false)
    const [ slotsToDisplay, setSlotsToDisplay ] = useState(null)
    
    const { value: { availableSlots, selectedAreaCenters, isAdult, prestation, kidForm, selectedSlot, periodStart, centers, selectedKid, schoolSubscriptions, stageSubscriptions, isGlobalLoading, fromStep4 }, setValue: setCurrentSelection } = useSchoolTrainingCurrent() || {}
    const { value: tunnelType } = useSchoolTrainingTunnel()
    const { value: { filterLevel, filterType, filterCenter }, setValue: setCurrentFilter } = useSchoolTrainingFilters()
    const { trackEcommerceEvent } = useDidomiEcommerceEvent()
    const themeFeatures = useThemeFeatures()
    const apiCall = (params) => {
        setIsLoading(true)
        const getFunction = tunnelType === "SCHOOL" ? getKidsSchools : tunnelType === "TRAINING" ? getKidsStages : undefined
        getFunction?.(params).then(res => {
            const sessions = res?.sessions?.map(session => ({ ...session, tunnelType }))
            const selectedKidAge = kidForm?.birthDate && Math.floor((new Date() - new Date(kidForm?.birthDate)) / (1000 * 60 * 60 * 24 * 365))
            const filteredSlots = sessions.filter(slot => {
                const target = tunnelType === "SCHOOL" ? slot.schoolDetails : slot
                const { minAge, maxAge } = target
                if (filterLevel && (
                    (target?.maxLevel && target?.maxLevel < filterLevel)
                    || (target?.minLevel && target?.minLevel > filterLevel)
                )) {
                    return false
                } else if (filterType !== null && (filterType === 1 && target.individualCollective === 0 || (filterType !== 1 && target?.individualCollective !== filterType))) {
                    return false
                } else if (filterCenter && target?.centerId !== filterCenter) {
                    return false
                } else if (selectedKidAge && ((minAge && selectedKidAge < minAge) || (maxAge && selectedKidAge > maxAge))) {
                    return false
                } else {
                    return true
                }
            })
            if (tunnelType === "SCHOOL") {
                filteredSlots?.sort(TimeSort.sortByAscSchoolStart)
            }
            setIsLoading(false)
            setCurrentSelection(prev => ({ ...prev, availableSlots: filteredSlots }))
        })
    }

    const slotChoiceRef = useRef(null)

    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
    }
    const removeFormInStore = (name) => {
        dispatch(removeFormEntry(name))
    }

    const filterSubscribed = subs => subs.filter(sub => !!(
        (isAdult
            && ((tunnelType === "SCHOOL" && sub.school.id === selectedSlot.value)
                || (tunnelType === "TRAINING" && sub.id === selectedSlot.id))
        ) || (!isAdult
            && ((tunnelType === "SCHOOL" && sub.school.id === selectedSlot.value && sub.kid?.id === selectedKid?.id)
                || (tunnelType === "TRAINING" && sub.training?.id === selectedSlot.id && sub.kid?.id === selectedKid?.id))
        )),
    )

    const filterSLots = slots => {
        return slots.filter(slot => {
            const target = tunnelType === "SCHOOL" ? slot.schoolDetails : slot

            if (filterLevel && (
                (target?.maxLevel && target?.maxLevel < filterLevel)
                    || (target?.minLevel && target?.minLevel > filterLevel)
            )) {
                return false
            } else if (
                filterType !== null
                && (
                    filterType === 1 && target.individualCollective === 0
                    || (filterType !== 1 && target?.individualCollective !== filterType)
                )
            ) {
                return false
            } else {
                return !(filterCenter && target?.centerId !== filterCenter)
            }
        })
    }

    useEffect(() => {
        setFormInStore("choixEnfant", selectedKid)
    }, [ selectedKid ])

    useEffect(() => {
        if (apiCall && selectedAreaCenters && prestation && currentStep === 2 && !fromStep4) {
            setSlotsToDisplay([])
            setCurrentSelection(prev => ({ ...prev, availableSlots: null }))
            const params = {
                [tunnelType === "SCHOOL" ? "schoolType" : "stageType"]: prestation.value,
                ageCategory: isAdult ? 1 : 0,
                kidBirthYear:  kidForm?.birthDate?.split("-")?.[0],
                orderByAsc: true,
                periodStart: dayjs(periodStart).format("YYYY-MM-DD"),
            }
            selectedAreaCenters.forEach((id, i) => params[`centersId[${i}]`] = id)
            Object.keys(params).forEach((param) => (params[param] === null || params[param] === undefined || params[param] === "") && delete params[param])
            apiCall(params)
        } else if (fromStep4) {
            resetScroll()
            setCurrentSelection(prev => ({ ...prev, fromStep4: false }))
            if (ozmoSubscription?.firstSubscription?.id) {
                const api = tunnelType === "SCHOOL" ? cancelSchoolRegistration : cancelStageRegistration
                api(ozmoSubscription.firstSubscription.id)
                dispatch(clearAllForms())
                dispatch(clearSchoolOptions())
            }
        }
    }, [ selectedAreaCenters, isAdult, prestation, kidForm, currentStep, periodStart ])

    useEffect(() => {
        if (selectedSlot && (!selectedSlot.schoolDetails?.full && !selectedSlot.full)) {
            slotChoiceRef?.current?.validate()
        } else {
            slotChoiceRef?.current?.unvalidate()
        }
    }, [ selectedSlot ])

    useEffect(() => {
        if (availableSlots) {
            const filteredSlots = filterSLots(availableSlots)

            if (
                selectedSlot
                && !filteredSlots?.some(slot =>
                    (tunnelType === "TRAINING" && slot.id === selectedSlot.id)
                    || (tunnelType === "SCHOOL" && slot.value === selectedSlot.value),
                )
            ) {
                setCurrentSelection(prev => ({ ...prev, selectedSlot: undefined }))
            }
            setSlotsToDisplay(filteredSlots)
            resetScroll()
        }
    }, [ availableSlots, filterLevel, filterType, filterCenter ])

    const checkSkipStep = () =>
        (selectedSlot?.schoolDetails?.optionProducts ?? []).every(option => option.showAtPayment === true) &&
        (
            filterSLots(availableSlots)
                .filter(el => el.value !== selectedSlot?.value && el.schoolDetails?.promotedForSecondRegistration)
                .length
            || !selectedSlot?.schoolDetails?.discount
        )

    return (
        <Step
            {...props}
            title={tunnelType === "SCHOOL" ? "Choix du créneau" : "Choix du stage"}
            help={<PhoneCallHelp tunnelType={tunnelType}/> }
            helpId={"HTMLSpanElementHelp"}
            className="slotChoice"
            returnText={<span className="customText">Précédent</span>}
            nextText={<span className="customText">Suivant</span>}
            checkBeforeNextStep={() => {
                const skipPrestaSup = checkSkipStep()

                setCurrentSelection(prev => ({ ...prev, fromStep4: true, skipPrestaSup }))

                schoolTrainingRegistration(
                    user,
                    selectedKid,
                    selectedSlot,
                    tunnelType,
                    setFormInStore,
                    removeFormInStore,
                    setIsLoading,
                    () => skipPrestaSup
                        ? dispatch(goToStep(stepManagement.currentStep + 2))
                        : dispatch(nextStep()),
                    slotsToDisplay,
                    filterSubscribed(tunnelType === "SCHOOL" ? schoolSubscriptions : stageSubscriptions),
                    isAdult,
                    trackEcommerceEvent,
                    themeFeatures,
                )
            }}
            ref={slotChoiceRef}
        >
            {(isLoading || isGlobalLoading) && <Preloader fixed/>}
            <SlotChoicePreform />
            {(filterLevel || filterType !== null || filterCenter !== null) && (
                <ActiveFilters>
                    {filterLevel && (
                        <CheckBlock
                            checked
                            unselect
                            action={
                                () => {
                                    setCurrentFilter(prev => ({ ...prev,
                                        filterLevel: null,
                                    }))
                                }
                            }
                        >
                            {`Niveau ${filterLevel}`}
                        </CheckBlock>
                    )}
                    {filterType !== null && (
                        <CheckBlock
                            checked
                            unselect
                            action={
                                () => {
                                    setCurrentFilter(prev => ({ ...prev,
                                        filterType: null,
                                    }))
                                }
                            }
                        >
                            {filterType === 0 ? "Individuel" : "Collectif"}
                        </CheckBlock>
                    )}
                    {filterCenter !== null && (
                        <CheckBlock
                            checked
                            unselect
                            action={
                                () => {
                                    setCurrentFilter(prev => ({ ...prev,
                                        filterCenter: null,
                                        filterLevel: filterLevel,
                                        filterType: filterType,
                                    }))
                                }
                            }
                        >
                            {centers?.find(center => center.key === filterCenter)?.value}
                        </CheckBlock>
                    )}
                </ActiveFilters>
            )}
            {!isLoading && slotsToDisplay?.length === 0 && <NoSchoolTraining />}
            <SlotChoiceSlots slots={slotsToDisplay} />
        </Step>
    )
}
