import classNames from "classnames"
import { array, bool, func, string } from "prop-types"
import { useRef } from "react"

import useThemeFeatures from "../../../../../hooks/useThemeFeatures.js"
import UserAgentHandler from "../../../../../services/UserAgentHandler.js"
import Preloader from "../../../../loaders/preloader/preloader.jsx"
import PhoneCallHelp from "../../../../popin/help/phoneCall.jsx"
import Step from "../../../../stepsManagement/Step.jsx"
import { CartOverview } from "../CartOverview/CartOverview.jsx"
import { ShopProduct } from "../ShopProduct/ShopProduct.jsx"

import "./ShopStep.scss"

export const ShopStep = (props) => {
    const themeFeatures = useThemeFeatures()
    const stepRef = useRef(null)

    const {
        title,
        tunnelType,
        isLoading,
        subtitle,
        availableProducts,
        addProduct,
    } = props

    return (
        <Step
            {...props}
            title={title ?? themeFeatures([ "Équipement Nike", "Équipement" ])}
            help={ <PhoneCallHelp tunnelType={tunnelType}/> }
            helpId={"ShopHelp"}
            ref={stepRef}
            optionnal
            cls={classNames("shopProducts arrows-on-sides noMarginBottom", {
                isMobile: UserAgentHandler.isAndroid() || UserAgentHandler.isIOS(),
            })}
            otherId='5'
        >
            { isLoading && <Preloader fixed={true} />}

            <div className="formsHolder c-row c-col c-mrg--btm--4 c-mrg--btm--sm--1 layoutSmall">
                {subtitle && <div className="h3">{subtitle}</div>}
                <div className="product-container">
                    <div className="grid-products">
                        {availableProducts?.length > 0 ?
                            availableProducts.map((product, index) => (
                                <ShopProduct
                                    key={index}
                                    product={product}
                                    addProduct={addProduct}
                                />
                            ))
                            : !isLoading && <div>Aucun équipement disponible</div>
                        }
                    </div>
                    <CartOverview {...props} />
                </div>
            </div>
        </Step>
    )
}

ShopStep.formName = "equipmentForm"
ShopStep.formDataName = "equipmentData"

ShopStep.propTypes = {
    addProduct: func,
    availableProducts: array,
    isLoading: bool,
    subtitle: string,
    title: string,
    tunnelType: string,
}

