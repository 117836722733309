import { clearAllBodyScrollLocks } from "body-scroll-lock-upgrade"
import { bool, func, oneOfType } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import { signIn } from "./api"
import APP_CONFIG from "../../../business/config"
import {
    getTokenLocation,
    getUserPlayerProfile,
    retrieveLocationPublicInvitDetails,
} from "../../../globalAPI/api"
import useQueryParametersObject from "../../../hooks/routing/useQueryParametersObject"
import useAppRedirect from "../../../hooks/useAppRedirect"
import { useLoginTracking } from "../../../hooks/useDidomiDataLayer.js"
import useMediaPath from "../../../hooks/useMediaPath"
import useThemeHeader from "../../../hooks/useThemeHeader"
import PhoneConfirm from "../../../pages/authentication/phoneConfirm/phoneConfirm"
import ConfirmationPopinContent from "../../../pages/home/ConfirmationPopinContent"
import { getLeagueTeamPublicById } from "../../../pages/league/api/myLeague.js"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import usePath from "../../../routes/services/usePath"
import resetScroll from "../../../services/resetScroll"
import { validateEmail } from "../../../services/validation.js"
import { loadUrlQueryParameters, loadUser, toggleGlobalLoader, toggleShowFooter } from "../../../store/actions/scaffold"
import { resetAuthStepper } from "../../../store/auth/actions/actions"
import { setFormEntry } from "../../../store/forms/actions"
import store from "../../../store/store.js"
import ButtonCta from "../../Buttons/button/button-cta"
import { TextInput } from "../../Inputs/TextInput/TextInput.jsx"
import { TeamInvitModal } from "../../league/TeamInvitModal/TeamInvitModal.jsx"
import Preloader from "../../loaders/preloader/preloader"
import { useIsWebview, useThemeUpdate } from "../../ThemeContext/ThemeContext"
import AuthStep from "../authStep/authStep"
import AuthStepper from "../authStepper/authStepper"
import { sendConfirmEmail } from "../emailConfirmation/api"
import EmailConfirmation from "../emailConfirmation/emailConfirmation"
import SignupComponent from "../signupComponent/signupComponent"

import "./loginComponent.scss"

function LoginComponent({
    forSignup,
    isMobile,
}) {
    const [ clickOnLogin, setClickOnLogin ] = useState(false)

    const [ searchParams ] = useSearchParams()
    
    const [ email, setEmail ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ isValidMail, setIsValidMail ] = useState(false)
    const [ isValidPassword, setIsValidPassword ] = useState(false)
    const [ apiError, setApiError ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState(null)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ displayPhoneConfirmation, setDisplayPhoneConfirmation ] = useState({ account: null, val: false })
    const [ displayEmailConfirmation, setDisplayEmailConfirmation ] = useState(false)
    const [ isSending, setIsSending ] = useState(false)
    const [ newEmail, setNewEmail ] = useState("")
    const [ retryComplete, setRetryComplete ] = useState(false)
    const [ isSuccess, setIsSuccess ] = useState(false)
    const [ successOrErrorMsg, setSuccessOrErrorMsg ] = useState(false)
    const [ booking, setBooking ] = useState({})

    const invitationStatus = useSelector(state => state.forms.invitationStatus)
    const { state } = useLocation()
    const setTheme = useThemeUpdate()
    const themeHeader = useThemeHeader()
    const path = usePath()
    const location = useLocation()
    const mediaPath = useMediaPath()
    const isWebview = useIsWebview()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const appRedirect = useAppRedirect()
    const queryParameters = useQueryParametersObject()
    const { trackLoginAttempt } = useLoginTracking()

    const loginForm = useRef(null)
    const recaptchaLoginRef = useRef(null)

    useEffect(
        () => {
            resetScroll()
            if (isWebview && queryParameters?.passwordChanged) {appRedirect("/login")}
            if (state?.afterLoginUrl?.includes("action=tokenActivation")) {
                const regexToken = /&PJT=([^&]+)(?=&fromUser)/
                const regexTheme = /&theme=([^&]+)(?=&id)/
                const regexInvitation = /&invitation=([^&]+)(?=&theme)/
                const publicJoinToken = regexToken.exec(state.afterLoginUrl)?.[1]
                const theme = regexTheme.exec(state.afterLoginUrl)?.[1]
                const invitation = regexInvitation.exec(state.afterLoginUrl)?.[1]
                if (publicJoinToken) {
                    if (invitation === "TYPE_LEAGUE_TEAM") {
                        const teamId = /&id=([^&]+)(?=&PJT)/.exec(state.afterLoginUrl)?.[1]
                        const fromUser = /&fromUser=([^&]+)/.exec(state.afterLoginUrl)?.[1]

                        if (teamId) {
                            getLeagueTeamPublicById(teamId).then((team) => {
                                if (team?.publicJoinToken) {
                                    ModalHandler.show(TeamInvitModal, {
                                        acceptCallback: () => {
                                            acceptFunction({ ...team, accepted: true }, "leagueTeam")
                                            ModalHandler.hide(TeamInvitModal)
                                        },
                                        cls: "noFooter",
                                        fromUser: fromUser,
                                        team: team,

                                    }).then(clearAllBodyScrollLocks)
                                }
                            })
                        }
                    } else {
                        getTokenLocation(decodeURIComponent(decodeURIComponent(publicJoinToken))).then(res => {
                            retrieveLocationPublicInvitDetails(res.id).then((result) => {
                                if (result) {
                                    setBooking({ ...result, ...res, publicJoinToken, theme })
                                    openPopin({ ...result, ...res, publicJoinToken, theme })
                                }
                            })
                        })
                    }
                }
            }
        }, [],
    )

    const acceptFunction = (booking, type = "booking") => {
        localStorage.setItem("publicJoinToken", JSON.stringify({
            id: booking.id,
            theme: booking.theme ?? "theme-soccer",
            token: decodeURIComponent(decodeURIComponent(booking.publicJoinToken)),
            type: type,
        }))
        setBooking(prev => ({ ...prev, accepted: true }))
        closePopin()
    }

    async function login(emailParam, passwordParam) {
        const captchaToken = await recaptchaLoginRef.current.executeAsync()
        recaptchaLoginRef.current.reset()
        setClickOnLogin(true)
        setAjaxLoading(true)
        signIn(emailParam || email, passwordParam || password, captchaToken).then(
            (res) => {
                handleLoginReturn(res)
            },
        ).catch(
            () => {
                trackLoginAttempt(false)
                setAjaxLoading(false)
                setApiError(true)
            },
        )
    }

    const modalProps = {
        afterClose: () => dispatch(setFormEntry("invitationStatus",  { ...invitationStatus, closed: true })),
        publicInvit: true,
        type: "location",
        withLeagueInvitation: queryParameters.invitation === "TYPE_FRIENDSHIP_AND_LEAGUE",
    }

    const openPopin = (booking) => {
        ModalHandler.show(ConfirmationPopinContent, {
            ...modalProps,
            customAccept: () => acceptFunction(booking),
            locationDetails: booking,
        })
    }

    const closePopin = () => {
        ModalHandler.hide(ConfirmationPopinContent)
    }

    function handleLoginReturn(res) {
        setErrorMessage(null)

        if (res?.data?.data?.failureReason) {
            trackLoginAttempt(false)
            setAjaxLoading(false)
            if (res.data.data.failureReason === 1) { // require phone confirmation
                setApiError(false)
                localStorage.setItem("changeCoordinatesToken", res.data.data.changeCoordinatesToken)
                setDisplayPhoneConfirmation({ account: res.data.data.account ?? null, val: true })
            } else if (res.data.data.failureReason === 2) { // require email confirmation
                setErrorMessage("Ton compte n'a pas été confirmé vérifie tes mails")
                setApiError(true)
            } else if (res.data.data.failureReason === 3) { // require activation
                setErrorMessage("Ton compte n'a pas été activé")
                setApiError(true)
            }

        } else if (res?.data?.data?.account && res.data.data.success)  {
            trackLoginAttempt(true)
            const account = res.data.data.account
            setApiError(false)
            window.scrollTo(0, 0)
            let token = res.data?.data?.token ? res.data.data.token : null
            let userid = account?.id
            if (token && userid) {
                localStorage.setItem("auth-token", token)
                localStorage.setItem("auth-userid", userid)
            }
            getUserPlayerProfile(userid).then(profile => {
                account.playerProfile = profile
                store.dispatch(
                    loadUser({
                        data: account,
                    }),
                )
                setAjaxLoading(false)
                const publicJoinToken =  JSON.parse(localStorage.getItem("publicJoinToken"))
                if (publicJoinToken?.token) {
                    const themePrefix = publicJoinToken.theme === "theme-soccer" ? "/" : "/padel/"
                    let searchString = ""
                    if (publicJoinToken?.type === "leagueTeam") {
                        searchString = `?action=tokenActivation&invitation=TYPE_LEAGUE_TEAM&theme=${publicJoinToken.theme}&id=${publicJoinToken.id}&PJT=${publicJoinToken.token}`
                    } else {
                        searchString = `?action=tokenActivation&invitation=TYPE_LOCATION&theme=${publicJoinToken.theme}&id=${publicJoinToken.id}&PJT=${publicJoinToken.token}`
                    }
                    dispatch(loadUrlQueryParameters(searchString))
                    navigate(`${themePrefix}${searchString}`)
                } else if (state?.afterLoginUrl) {
                    navigate(state.afterLoginUrl)
                    dispatch(toggleGlobalLoader(false))
                } else {
                    navigate("/")
                }
            })

        } else {
            setAjaxLoading(false)
            setApiError(true)
            trackLoginAttempt(false)
        }
    }

    useEffect(() => {
        const regexPadel = /^(?:\/padel\/)/g
        const isPadelSetUniverse = state?.afterLoginUrl === "/setUniverse/1"
        const redirectionIsPadel = state?.afterLoginUrl?.match(regexPadel)
        const locationIsPadel = location?.pathname === "/padel/login"
        if (isWebview) {
            setTheme("theme-soccer")
            if (locationIsPadel) {window.location.href = ("/login")}
        } 
        else if (!isWebview) {
            if (locationIsPadel) {
                setTheme("theme-padel")}
            else if (redirectionIsPadel || isPadelSetUniverse) {navigate("/padel/login", { state: { afterLoginUrl: state?.afterLoginUrl } })}
        }

    }, [ state, location ])

    return (
        <React.Fragment>
            { ajaxLoading &&
                <Preloader fixed={true}/>
            }
            {isMobile && (<img className="appLogo" src={mediaPath([
                "/assets/images/logo-white.svg",
                "/assets/images/padel/logo/logo-green.svg",
                "/assets/images/business/logo-business.png",
            ])} />)}
            <div className="loginComponent">
                <div className="header">Je me connecte !</div>
                {!apiError && queryParameters.passwordChanged === "true" && (
                    <div className="infoLabel">
                        <img className="iconError" src="/assets/icons/notifDarkGreen.svg"/>
                        {
                            errorMessage ?
                                <div>{errorMessage}</div>
                                :
                                <div>Ton mot de passe a bien été modifié</div>
                        }

                    </div>
                )}
                {!apiError && queryParameters.accountIsVerified && queryParameters.accountIsVerified === "true" && (
                    <div className="infoLabel">
                        <img className="iconError" src="/assets/icons/notifDarkGreen.svg"/>
                        <div>Ton compte a été validé, tu peux te connecter</div>
                    </div>
                )}
                {!apiError && booking.accepted ? (
                    <div className="infoLabel invitation">
                        <img className="iconError" src="/assets/icons/notifDarkGreen.svg"/>
                        <div>Pour valider ta participation au match, tu dois te connecter ou créer un compte.</div>
                    </div>
                ) : (<></>)}
                {!apiError && queryParameters.accountVerificationError && (
                    <div className="errorLabel">
                        <img className="iconError" src="/assets/icons/errorNotif.svg" />
                        <div>Votre compte n'a pas pu être validé. {searchParams.get("accountVerificationError")}.</div>
                    </div>
                )}

                <div className="loginForm" ref={loginForm}>
                    <TextInput
                        id="cy--input__login-email"
                        label="Adresse e-mail"
                        name="email"
                        required={true}
                        customRootCls={"inverted-bg"}
                        type="email"
                        inputMode="email"
                        onChange={(v) => {
                            setIsValidMail(validateEmail(v))
                            setEmail(v)}
                        }
                        error={!ajaxLoading && ((clickOnLogin && !isValidMail) || (clickOnLogin && apiError))}
                        onFocus={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(false))
                            }
                        }}
                        onBlur={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(true))
                            }
                        }}
                        autoFocus
                    />
                    <TextInput
                        id="cy--input__login-password"
                        customRootCls={"inverted-bg"}
                        name="password"
                        required={true}
                        type={"password"}
                        label={"Mot de passe"}
                        onChange={(val) => {
                            setIsValidPassword(val)
                            setPassword(val)
                        }}
                        onFocus={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(false))
                            }
                        }}
                        onBlur={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(true))
                            }
                        }}
                        error={!ajaxLoading && ((clickOnLogin && !isValidPassword) || (clickOnLogin && apiError))}
                        onEnter={() => {
                            login(email, password)
                        }}
                    />
                    {
                        !ajaxLoading && ((clickOnLogin && (!isValidMail || !isValidPassword || apiError))) && (
                            <div className="errorLabel">
                                <img className="iconError" src="/assets/icons/errorNotif.svg" />
                                { errorMessage ?
                                    <div>{errorMessage}</div>
                                    :
                                    <div>Adresse e-mail ou mot de passe incorrect</div>
                                }
                            </div>
                        )
                    }
                    <ButtonCta
                        id={"cy--input__login-submit"}
                        text= "Connexion"
                        isAlt= {mediaPath([ true,true,false ])}
                        tabindex= {2}
                        onClick= {() => {
                            login(email, password)
                        }}
                    />
                    <div
                        className="forgotPasswordDiv"
                        onClick={() => navigate(path("/forgotPassword"))}
                    >
                        Mot de passe oublié ?
                    </div>
                    {isMobile &&
                        (
                            <SignupComponent
                                displaySignupPageAction={forSignup}
                                isMobile={isMobile}
                            />
                        )
                    }
                </div>
            </div>
            { displayPhoneConfirmation.val &&
                (<div className="overlay-level3 no-pdg flex-column">
                    <PhoneConfirm
                        prevAction={(isPhoneConfirmed) => {
                            setDisplayPhoneConfirmation(false)
                            localStorage.removeItem("changeCoordinatesToken")

                            if (isPhoneConfirmed) {
                                login()
                            }
                        }}
                        initialPhoneNumber={displayPhoneConfirmation.account ? displayPhoneConfirmation.account.phoneNumber : ""}
                        userId={displayPhoneConfirmation.account ? displayPhoneConfirmation.account.id : ""}
                        step1Title="Renseigner un téléphone"
                        step2Title="Confirmer ton téléphone"
                        isUserPhoneNotConfirm={true}
                        userEmail={displayPhoneConfirmation?.account?.emailAddress}
                        goToEmailStep={() => {
                            dispatch(resetAuthStepper())
                            setDisplayPhoneConfirmation(prev => ({ account: prev?.account ? { ...prev.account } : null, val: false }))
                            setDisplayEmailConfirmation(true)
                        }}
                    />
                </div>)
            }
            {displayEmailConfirmation && (
                <div className="overlay-level3 no-pdg flex-column">
                    <AuthStepper
                        title={"Création de compte"}
                        prevAction={() => {
                            setDisplayEmailConfirmation(false)
                        }}
                        rootClasses="women-bkg"
                        help={<p>Tu rencontres un problème pour te connecter ou créer un compte ? Contacte-nous au 0 806 23 00 24</p>}
                    >
                        <AuthStep>
                            {isSending &&
                                <Preloader fixed={true} />
                            }
                            <EmailConfirmation
                                introText="Félicitations ton téléphone a bien été validé"
                                introTextIsBold={true}
                                retryValue={displayPhoneConfirmation?.account?.emailAddress ? displayPhoneConfirmation?.account?.emailAddress : ""}
                                retryAction={() => {
                                    setIsSending(true)

                                    const emailAddr = newEmail ? newEmail : displayPhoneConfirmation?.account?.emailAddress

                                    sendConfirmEmail(displayPhoneConfirmation?.account?.id, emailAddr, localStorage.getItem("changeCoordinatesToken"), themeHeader).then((resEmail) => {
                                        setIsSending(false)
                                        if (resEmail?.data?.data?.Message) {
                                            setRetryComplete(true)
                                            setIsSuccess(false)
                                            setSuccessOrErrorMsg(resEmail.data.data.Message)
                                        } else {
                                            if (resEmail?.data?.data?.id) {
                                                setRetryComplete(true)
                                                setIsSuccess(true)
                                                setSuccessOrErrorMsg("Un nouvel email a été envoyé")
                                            }
                                        }

                                    })

                                }}
                                onChangeAction={(val) => {
                                    setNewEmail(val)
                                }}
                                retryComplete={retryComplete}
                                isSuccess={isSuccess}
                                successOrErrorMsg={successOrErrorMsg}
                            />
                        </AuthStep>
                    </AuthStepper>
                </div>
            )}
            <ReCAPTCHA
                ref={recaptchaLoginRef}
                sitekey={APP_CONFIG.recapchaSiteKey}
                size="invisible"
            />
        </React.Fragment>
    )

}

LoginComponent.propTypes = {
    forSignup: oneOfType([ bool, func ]),
    forgotPasswordCallback: func,
    isMobile: bool,
    redirectSignupCallback: func,
}

export default LoginComponent
