import { bool, object, string } from "prop-types"
import { useState } from "react"
import { useDispatch, useSelector, useStore } from "react-redux"

import Preloader from "../../../../entries/main/../../components/loaders/preloader/preloader"
import useMediaPath from "../../../../hooks/useMediaPath"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import { removeBirthdayCatalogArticle } from "../../../../pages/kids/birthdayReservation/api"
import CreneauDispo from "../../../../pages/kids/birthdayReservation/steps/CreneauxDispo"
import { deleteSchoolProduct } from "../../../../pages/kids/ecoleDeFoot/api"
import { deleteStageProduct } from "../../../../pages/kids/stageDeFoot/api"
import { deleteLeagueProduct } from "../../../../pages/league/api/api.js"
import { removeCupProduct } from "../../../../pages/teams/urbanCup/api"
import { removeProductFromCart } from "../../../../store/cart/actions"
import { setFormEntry } from "../../../../store/forms/actions"
import CurrencyDisplay from "../../../currencies/CurrencyDisplay"

import "./SummaryCartEquimentLine.scss"

function SummaryCartEquimentLine({ productOrder, productInfos, disableDeletion = false, tunnelType, blackEquipmentCross }) {
    let sizes
    if (productInfos === undefined) {
        sizes = []
    } else {
        sizes = productInfos.sizes
    }
    const store = useStore()
    let formattedSizes = {}
    /* istanbul ignore else */
    if (sizes !== undefined) {
        sizes.forEach(element => {
            formattedSizes[element.Key] = element.Value
        })
    }
    const mediaPath = useMediaPath()
    const dispatch = useDispatch()
    const themeHeader = useThemeHeader()
    const [ ajaxLoading, setAjaxLoading ] = useState(false)

    const forms = useSelector(state => state.forms)

    // birthday data
    const formCreneauDispo = forms[CreneauDispo.formName] !== undefined ?
        forms[CreneauDispo.formName]
        : {}

    const avoirFormData = store.getState().forms.avoirFormData !== undefined ?
        store.getState().forms.avoirFormData
        : {}

    function removeProduct() {
        dispatch(removeProductFromCart(productOrder.product.id, productOrder.sizeValue + ""))

        switch (tunnelType) {
        case "BIRTHDAY": {
            if (formCreneauDispo?.birthdaySubscription?.id) {
                setAjaxLoading(true)

                let catalogArticleId = productOrder.id

                removeBirthdayCatalogArticle(formCreneauDispo.birthdaySubscription.id, catalogArticleId).then((res) => {
                    dispatch(setFormEntry(CreneauDispo.formName, {
                        ...formCreneauDispo,
                        birthdaySubscription: res.data.data,
                    }))
                    dispatch(
                        setFormEntry("avoirFormData", {
                            ...avoirFormData,
                            recalcAvoir: true,
                        }))
                    setAjaxLoading(false)
                })
            }
            setAjaxLoading(false)
            break
        }

        case "SCHOOL": {
            let schoolSubscriptionId = forms["ozmoSubscription"].firstSubscription.id
            
            setAjaxLoading(true)
            deleteSchoolProduct(schoolSubscriptionId, productOrder.product.id, productOrder.sizeValue).then(
                (res) => {
                    setAjaxLoading(false)
                    dispatch(setFormEntry("ozmoSubscription", {
                        ...store.getState().forms["ozmoSubscription"],
                        firstSubscription: res,
                    }))
                    dispatch(
                        setFormEntry("avoirFormData", {
                            ...avoirFormData,
                            recalcAvoir: true,
                        }))
                },
            )
            break
        }

        case "CUP": {
            const cupSubscription = forms.TournamentRegistration
            setAjaxLoading(true)
            removeCupProduct(cupSubscription.id, productOrder.product.id, productOrder.sizeValue, themeHeader).then(
                (res) => {
                    setAjaxLoading(false)
                    dispatch(setFormEntry("TournamentRegistration",{
                        ...res.data.data,
                    }))
                    dispatch(
                        setFormEntry("avoirFormData", {
                            ...avoirFormData,
                            recalcAvoir: true,
                        }))
                },
            )
            break
        }

        case "TRAINING": {
            const stageId = forms.ozmoSubscription.firstSubscription.id
            setAjaxLoading(true)
            deleteStageProduct(stageId, productOrder.product.id, productOrder.sizeValue).then(
                (res) => {
                    setAjaxLoading(false)
                    dispatch(setFormEntry(
                        "ozmoSubscription", {
                            ...store.getState().forms["ozmoSubscription"],
                            firstSubscription: res,
                        },
                    ))
                    dispatch(
                        setFormEntry("avoirFormData", {
                            ...avoirFormData,
                            recalcAvoir: true,
                        }))
                },
            )
            break
        }
                    
        case "LEAGUE": {
            const leagueSubscription = forms.TournamentRegistration
            setAjaxLoading(true)
            deleteLeagueProduct(leagueSubscription.id, productOrder.product.id, productOrder.sizeValue).then(
                (res) => {
                    setAjaxLoading(false)
                    dispatch(setFormEntry("TournamentRegistration",{
                        ...res.data.data,
                    }))
                    dispatch(
                        setFormEntry("avoirFormData", {
                            ...avoirFormData,
                            recalcAvoir: true,
                        }))
                },
            )
            break
        }

        /* istanbul ignore next */
        default: 
            // eslint-disable-next-line no-console
            console.error("NOT IMPLEMENTED TUNNEL TYPE : %o (or break statement missing)", tunnelType)
            // eslint-disable-next-line no-debugger
            debugger
            break
        }
    }

    return (
        <div className="productInfo__row">
            {ajaxLoading && 
                /* istanbul ignore next */
                <Preloader fixed={true}/>
            }
            <div className="productInfo__thumb">
                <img src={productInfos?.pictureUrl}/>
            </div>

            <div className="productInfo__content"> {/*Cette div permet de changer l'orientation de vertical sur la page equipement à horizontal sur la page commande*/}
                <div>
                    <div className="productInfo__name">{productInfos?.name}</div>
                    <div className="productInfo__desc"> 
                        {productOrder?.size &&
                            (
                                "Taille " + 
                                (
                                    formattedSizes[productOrder.size] ? 
                                        formattedSizes[productOrder.size] + " - "
                                        :
                                        productOrder.size + " - "
                                )
                            )
                        }
                        Qté {productOrder?.quantity}
                    </div>
                </div>

                <div className="productInfo__price">
                    <CurrencyDisplay price={productInfos?.price}/>
                </div>
            </div>
            
            { !disableDeletion && 
                (<div className="productInfo__action">
                    <button onClick={removeProduct} data-testid="remove">
                        <img src={mediaPath([ "/assets/icons/cross-orange.svg", `/assets/images/padel/icons/icon-cross-${blackEquipmentCross ? "black" : "red"}.svg` ])} alt=""/>
                    </button>
                </div>)
            }
        </div>
    )
}

SummaryCartEquimentLine.propTypes = {
    blackEquipmentCross: bool,
    disableDeletion: bool,
    productInfos: object,
    productOrder: object,
    tunnelType: string,
}

export default SummaryCartEquimentLine
