import axios from "axios"

import { downloadFile, handleBadRequest, handleNotFound, handlePreconditionFailed, handleUnauthorized } from "./helpers"
import { toggleAjaxCalling } from "../store/actions/scaffold"
import store from "../store/store"

axios.defaults.baseURL = window.location.origin
axios.defaults.timeout = 60000

const withDataVerbs = [ "post", "put", "patch" ]

axios.interceptors.request.use((config) => {
    store.dispatch(toggleAjaxCalling(true))
    const queryParams = new URLSearchParams(window.location.search)
    const theme = queryParams.get("theme")
    const themeActivity = getActivityFromTheme(theme)
    const lastUniverse = localStorage.getItem("lastUniverse")
    const activity = theme ? themeActivity : getActivityFromTheme(lastUniverse)

    const headers = {
        "activity": activity,
        ...config.headers,
    }

    if (config.noActivity) {
        delete headers.activity
    }

    // Add auth tokens if available
    const authToken = localStorage.getItem("auth-token")
    const authUserId = localStorage.getItem("auth-userid")

    if (!config.noAuth && authToken && authUserId) {
        headers["auth-token"] = authToken
        headers["auth-userid"] = authUserId
    }

    return { ...config, headers }
})

// Handle responses
axios.interceptors.response.use(
    response => handleResponse(response),
    error => handleError(error),
)

function handleResponse(response) {
    store.dispatch(toggleAjaxCalling(false))
    return response
}

// Function to handle errors
function handleError(error) {
    store.dispatch(toggleAjaxCalling(false))
    const status = error?.response?.status
    const errorMessage = error?.response?.data ?? error?.response?.statusText

    switch (status) {
    case 400:
        handleBadRequest(errorMessage)
        break
    case 401:
        handleUnauthorized(errorMessage)
        break
    case 412:
        handlePreconditionFailed(errorMessage)
        break
    case 404:
        handleNotFound(errorMessage)
        break
    default:
        return error.response
    }

    return Promise.reject(error)
}

export const _apiCall = async (
    apiResourceIdentifier, apiVerb, extraHeaders, data, binary, sync, withStatus, isUploadProgress, progressBarEl, disableError, noActivity, openFile = true, noAuth, fileName,
) => {
    try {
        const method = apiVerb.toLowerCase()
        const url = `/api/${apiResourceIdentifier}`

        extraHeaders = {
            ...extraHeaders,
        }
        // Preparing the configuration object for Axios
        let config = {
            headers: extraHeaders || {},
            noActivity: noActivity,
        }

        // Handling binary response type
        if (binary === true) {
            config.responseType = "blob"
        }
        if (noAuth) {
            config.noAuth = noAuth
        }

        // Handling upload progress
        if (isUploadProgress && progressBarEl) {
            progressBarEl.set(0)
            config.onUploadProgress = (progressEvent) => {
                let uploadPercentage = progressEvent.loaded / progressEvent.total
                progressBarEl.animate(uploadPercentage)
            }
        }

        // Handling FormData for non-JSON content types
        if (extraHeaders && extraHeaders["Content-Type"] !== "application/json" && !(data instanceof FormData) && !!data) {
            let formData = new FormData()
            Object.keys(data || {}).forEach((key) => {
                formData.append(key, data[key])
            })
            data = formData
        }

        const response = withDataVerbs.includes(method) ?
            await axios[method](url, data ?? undefined, config)
            : await axios[method](url, config)

        const contentDisposition = response?.headers["content-disposition"]
        if (contentDisposition && openFile) {
            downloadFile({ file: response?.data, fileName: fileName })
        }

        return response
    } catch (error) {
        if (!disableError) {
            throw error
        }
    }
}

// Fake API call with delay, to simulate a real API call while integrating without API
export const _fakedApiCallWIthDelay = async (apiResourceIdentifier, apiVerb, extraHeaders, data, binary, sync, withStatus, isUploadProgress, progressBarEl, disableError, noActivity) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(_apiCall(apiResourceIdentifier, apiVerb, extraHeaders, data, binary, sync, withStatus, isUploadProgress, progressBarEl, disableError, noActivity))
        }, 1500)
    })
}

function getActivityFromTheme(theme) {
    switch (theme) {
    case "theme-padel":
        return 2
    case "theme-business":
        return 3
    default:
        return 1
    }
}
