import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getSortedEquipments } from "../../../../../components/reservation/Shop/New/service.js"
import { ShopStep } from "../../../../../components/reservation/Shop/New/ShopStep/ShopStep.jsx"
import useAddSearchParam from "../../../../../hooks/useAddSearchParams.js"
import resetScroll from "../../../../../services/resetScroll.js"
import { addProductToCart, removeProductFromCart } from "../../../../../store/cart/actions.js"
import { setFormEntry } from "../../../../../store/forms/actions.js"
import { nextStep, skipStep } from "../../../../../store/stepsManagement/actions.js"
import { addSchoolProduct, deleteSchoolProduct, getKidsSchoolDetail } from "../../api.js"

const tunnelType = "SCHOOL"

export const EcoleDeFootProducts = () => {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ advertising, setAdvertising ] = useState(null)
    const [ catalogDiscount, setCatalogDiscount ] = useState(null)

    const { forms, cart, availableEquipment, checkSkipStepEDF, stepManagement } = useSelector(state => ({
        availableEquipment: state.forms[ShopStep.formDataName]?.availableEquipment,
        cart: state.cart,
        checkSkipStepEDF: state.checkSkipStepEDF,
        forms: state.forms,
        stepManagement: state.stepManagement,
    }))

    const dispatch = useDispatch()
    const addSearchParams = useAddSearchParam()

    const schoolSubscription = forms.ozmoSubscription.firstSubscription
    const hasCatalogProducts = forms?.choixCreneau?.selectedTimeslot?.schoolDetails?.catalogProducts?.length > 0
    const pickedProducts = schoolSubscription?.catalogArticles ?? []

    useEffect(() => {
        resetScroll()
        const fetchKidsSchoolDetail = async () => {
            setIsLoading(true)
            try {
                const res = await getKidsSchoolDetail(schoolSubscription.school.id)
                setAdvertising(res.data.advertising)
                setCatalogDiscount(res.metadata.catalogDiscounts?.[0])
                setInfoSchool(res.data.catalogProducts)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error("Failed to fetch kids shool detail:", error)
            } finally {
                setIsLoading(false)
            }
        }

        if (!hasCatalogProducts) {
            dispatch(skipStep())
        } else {
            fetchKidsSchoolDetail()
        }
    }, [])

    const setInfoSchool = (formatedAvailableEquipment = []) => {
        const products = getSortedEquipments(formatedAvailableEquipment)
        dispatch(setFormEntry(ShopStep.formDataName, {
            ...forms[ShopStep.formDataName],
            availableEquipment: products,
        }))

        schoolSubscription.catalogArticles.forEach((element) => {
            if (!cart.equipments[element.product.id]) {
                const equipment = formatedAvailableEquipment.find((equipment) => equipment.id === element.product.id)
                let formattedSizes = {}
                equipment.sizes.forEach((size) => {
                    formattedSizes[size.Value] = size.Key
                })
                dispatch(addProductToCart(element.product.id, formattedSizes[element.size], element.quantity, element.product.price))
            }
        })
    }

    const addProduct = (product) => {
        let initialValue = 0
        const { size, count, product : { id } } = product
        const articleInCatalog = pickedProducts.find(
            x => x.sizeValue === parseInt(size) &&
                x.product.id === id,
        )

        if (articleInCatalog) {
            initialValue = articleInCatalog.quantity
        }
        setIsLoading(true)

        const handleProductAdd = () => {
            addSchoolProduct(
                schoolSubscription.id,
                id,
                size,
                parseInt(count) + initialValue,
            ).then(
                (res) => {
                    setIsLoading(false)
                    dispatch(setFormEntry("ozmoSubscription", {
                        ...forms["ozmoSubscription"],
                        firstSubscription: res.data.data,
                    }))
                    dispatch(setFormEntry("avoirFormData", {
                        ...forms.avoirFormData,
                        recalcAvoir: true,
                    }))
                },
            )
        }

        if (initialValue !== 0) {
            deleteSchoolProduct(
                schoolSubscription.id,
                id,
                size,
            ).then(handleProductAdd)
        } else {
            handleProductAdd()
        }
    }

    const removeProduct = (product) => {
        dispatch(removeProductFromCart(product.product.id, product.sizeValue + ""))

        setIsLoading(true)
        deleteSchoolProduct(schoolSubscription.id, product.product.id, product.sizeValue).then(
            (res) => {
                setIsLoading(false)
                dispatch(setFormEntry(
                    "ozmoSubscription", {
                        ...forms["ozmoSubscription"],
                        firstSubscription: res,
                    },
                ))
                dispatch(setFormEntry("avoirFormData", {
                    ...forms.avoirFormData,
                    recalcAvoir: true,
                }))
            },
        ).catch(() => setIsLoading(false))
    }
    const subTotal = pickedProducts.reduce((acc, product) => acc + product.product.price * product.quantity, 0)
    return (
        <ShopStep
            tunnelType={tunnelType}
            addProduct={addProduct}
            advertising={advertising}
            availableProducts={availableEquipment}
            isLoading={isLoading}
            products={pickedProducts}
            discount={catalogDiscount}
            deleteProduct={removeProduct}
            checkBeforeNextStep={() => {
                addSearchParams(
                    "equipmentId",
                    forms?.TournamentRegistration?.catalogArticles?.length ?
                        forms?.TournamentRegistration?.catalogArticles[0]?.id : "none",
                )
                dispatch(nextStep())
            }}
            pricingInfos={{
                discountOnSubTotal: schoolSubscription?.catalogDiscountAmount,
                subtotal: subTotal,
            }}
            goToPreviousStep={checkSkipStepEDF?.skipPrestaSupp ? stepManagement.currentStep - 2 : null}
        />
    )
}
