import classNames from "classnames"
import dayjs from "dayjs"
import { AnimatePresence, motion } from "framer-motion"
import { arrayOf, bool, number, object, string } from "prop-types"
import { useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import useMediaPath from "../../../hooks/useMediaPath.js"
import useThemeFeatures from "../../../hooks/useThemeFeatures.js"
import { changeGamePlayerStatus } from "../../../pages/league/api/myLeague.js"
import { isGameForfeit, isMvpVoteOpen, navigateToGameDetails } from "../../../pages/league/service/service.js"
import { useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"
import { AnimationPresets } from "../../../utilities/animations.js"
import ButtonCta from "../../Buttons/button/button-cta"
import Preloader from "../../loaders/preloader/preloader.jsx"
import VersusDisplay from "../VersusDisplay/VersusDisplay.jsx"

import "./DayMatches.scss"

export default function DayMatches({ isNext, matches, day, isOpen, onClick, filtered, week }) {
    const [ isLoading, setIsLoading ] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()

    const mediaPath = useMediaPath()

    const {
        selectedLeagueTeam,
        setSelectedTeamData,
        selectedTeamData,
        setShouldRevalidate,
    } = useLeagueStore()
    const userLoaded = useSelector((state) => state.userLoaded)

    const today = useMemo(() => dayjs(), [])

    const sortedMatches = useMemo(() => {
        const sortMatches = (data) => {
            return data.sort((a, b) => {
                if (dayjs(a.date).isSame(dayjs(b.date))) {
                    const aIsSelectedTeam = a.team1.teamId === selectedLeagueTeam.id || a.team2.teamId === selectedLeagueTeam.id
                    const bIsSelectedTeam = b.team1.teamId === selectedLeagueTeam.id || b.team2.teamId === selectedLeagueTeam.id
                    return aIsSelectedTeam && !bIsSelectedTeam ? -1 : !aIsSelectedTeam && bIsSelectedTeam ? 1 : 0
                }
                return dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
            })
        }

        let userMatches = matches.filter(match =>
            match.team1.teamId === selectedLeagueTeam.id || match.team2.teamId === selectedLeagueTeam.id,
        )

        userMatches?.sort((a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1)

        const otherMatches = matches?.filter(match =>
            match.team1.teamId !== selectedLeagueTeam.id && match.team2.teamId !== selectedLeagueTeam.id,
        )

        return [ ...userMatches, ...sortMatches(otherMatches) ]
    }, [ matches ])

    const userHasMatch = useMemo(() => {
        return !!filtered || sortedMatches?.some(match =>
            match.team1.teamId === selectedLeagueTeam.id || match.team2.teamId === selectedLeagueTeam.id,
        )
    }, [ filtered, sortedMatches, selectedLeagueTeam.id ])

    const changeUserStatus = async (status, matchId) => {
        setIsLoading(matchId)
        setShouldRevalidate(true)
        const res = await changeGamePlayerStatus(matchId, userLoaded.contactId, status, selectedLeagueTeam.id)

        if (res?.data?.players) {
            const newMatch = selectedTeamData.games[week].find(match => match.id === matchId)

            if (!newMatch?.player) {
                newMatch.player = {}
            }

            newMatch.player.status = status

            setSelectedTeamData(prev => ({
                ...prev,
                games: {
                    ...prev.games,
                    [week]: prev.games[week].map(match => match.id === matchId ? newMatch : match),
                },
            }))
        }
        setIsLoading(null)
    }

    const userMatchCount = useMemo(() => {
        return sortedMatches.filter(match =>
            match.team1.teamId === selectedLeagueTeam.id || match.team2.teamId === selectedLeagueTeam.id,
        ).length
    }, [ sortedMatches ])

    const goToMatchDetails = (match, openMvpVote) => {
        navigateToGameDetails(navigate, selectedLeagueTeam.id, match.id, {
            from: location.pathname,
            openMvpVote: openMvpVote,
        })
    }

    const renderMatch = useCallback((match, index) => {
        if (!match) {return null}

        const isUserMatch = match.team1.teamId === selectedLeagueTeam.id || match.team2.teamId === selectedLeagueTeam.id
        const isMatchPlayed = today.isAfter(match.date)

        const voteRequirements = isMvpVoteOpen(match)
            && !match?.mvpInfos?.selectedPlayer
            && match?.player?.status === 1
            && match?.players?.filter(player => player?.status === 1).length > 1

        return (<div className="match" key={index}>
            <div className="start">
                <img src={mediaPath([
                    "/assets/icons/calendar-orange.svg",
                    "/assets/icons/calendar-orange.svg",
                    "/assets/icons/business/calendar-orange.svg",
                ])} className="date" alt="Date de début"/>
                <span>{dayjs(match.date).format("dddDD/MM/YYYY")}</span>
                <img src={mediaPath([
                    "/assets/icons/heure-orange.svg",
                    "/assets/icons/heure-orange.svg",
                    "/assets/icons/business/heure-orange.svg",
                ])} className="hourIcon" alt="Heure de début"/>
                <span className={"hour"}>{dayjs(match.date).format("HH[h]mm")}</span>
            </div>
            <VersusDisplay
                team1={match.localTeam}
                team2={match.visitorTeam}
                isMatchPlayed={isMatchPlayed}
                isForfeit={isGameForfeit(match)}
            />
            {isUserMatch
                ? isMatchPlayed
                    ? voteRequirements
                        ? isGameForfeit(match)
                            ?  (
                                <ButtonCta text="Récapitulatif dU match" isAlt onClick={() => goToMatchDetails(match)}/>
                            )
                            : (
                                <ButtonCta text="désigne l’homme du match" isAlt onClick={() => goToMatchDetails(match, true)}/>
                            )
                        : <ButtonCta text="Récapitulatif dU match" isAlt onClick={() => goToMatchDetails(match)}/>
                    : (
                        (match?.player?.status === 0 || !match?.player) && !isGameForfeit(match) &&  !filtered ? isLoading === match.id ? <Preloader small cls={"game-loader"}/> : (<div className="buttonsWrapper">
                            <ButtonCta
                                text="absent"
                                icon="/assets/icons/decline.svg"
                                isCta2Alt
                                onClick={() => changeUserStatus(2, match.id)}
                            />
                            <ButtonCta
                                text="présent"
                                icon={mediaPath([ "/assets/icons/accept.svg", "/assets/icons/accept.svg", "/assets/icons/business/accept.svg" ])}
                                hoverChangeIcon="/assets/icons/accept.svg"
                                onClick={() => changeUserStatus(1, match.id)}
                            />
                        </div>)
                            : <ButtonCta text="Feuille de match" isAlt onClick={() => goToMatchDetails(match)}/>
                    )
                : null}
        </div>)
    }, [ today, isLoading ])

    const isMatchAlone = sortedMatches?.length > (userHasMatch ? userMatchCount : 0)

    return (
        <div className="dayMatches">
            <div className={classNames("title", {
                "filtered": filtered,
                isOpen: isOpen,
                "nextComing": isNext,
                "single" : !isMatchAlone,
            })} onClick={() => {
                isMatchAlone ? onClick() : null
            }}>
                <div>Journée {day}</div>
                {isNext && (
                    <div className="isNext">
                        <svg width="14" height="7" viewBox="0 0 14 7" fill="none">
                            <path d="M14 3.31654L10.8612 0H7.36841L10.5072 3.31654L7.36982 6.63158H10.8621L14 3.31654Z"
                                fill={mediaPath([ "#FF7832","#FF7832","white" ])}/>
                            <path
                                d="M7.36842 3.31654L3.88087 0H0L3.48755 3.31654L0.00157062 6.63158H3.88192L7.36842 3.31654Z"
                                fill={mediaPath([ "#FF7832","#FF7832","white" ])}/>
                        </svg>
                        PROCHAIN MATCH
                    </div>
                )}
                {isMatchAlone && !filtered && (
                    <ArrowIcon isNext={isNext}/>
                )}
            </div>
            <div className="summary">
                {(!userHasMatch || (selectedLeagueTeam?.id === filtered && !sortedMatches.length))
                    ?
                    (
                        <div className="noMatch">Journée de repos pour ton équipe !</div>
                    )
                    : sortedMatches.length > 0
                        ? (
                            sortedMatches.filter(match =>
                                match.team1.teamId === (filtered ?? selectedLeagueTeam.id) || match.team2.teamId === (filtered ?? selectedLeagueTeam.id),
                            ).map((match, index) =>
                                renderMatch(match, index),
                            )
                        )
                        : (
                            <div className="noMatch">Aucun match prévu sur cette
                            journée {!filtered && `pour l'équipe ${selectedLeagueTeam.name}`}</div>
                        )}
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        {...AnimationPresets.fadeInHeightEase}
                        className="details"
                    >
                        {!filtered && sortedMatches.slice(userHasMatch ? userMatchCount : 0).map(renderMatch)}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

const ArrowIcon = ({ isNext }) => {
    const themeFeature = useThemeFeatures()

    const variant1 = (
        <svg className="arrow" width="14" height="9" viewBox="0 0 14 9" fill="none">
            <g clipPath="url(#clip0_3254_114)">
                <path d="M12.7056 0.977173L6.86618 7.19935L0.97465 0.977172" stroke={classNames({
                    "#FF7832": !isNext,
                    "white": isNext,
                })} strokeWidth="2"/>
            </g>
            <defs>
                <clipPath id="clip0_3254_114">
                    <rect width="9" height="14" fill="white" transform="translate(14) rotate(90)"/>
                </clipPath>
            </defs>
        </svg>
    )

    const variant2 = (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow">
            <path
                d="M10 0.342915L5.18468 4.78271L0 -4.37114e-07L-2.28055e-07 5.21729L5.18468 10L10 5.55908L10 0.342915Z"
                fill={classNames({
                    "#0C1D31": isNext,
                    "#FFBB33": !isNext,
                })}/>
        </svg>
    )

    return themeFeature([ variant1, variant1, variant2 ])
}

DayMatches.propTypes = {
    day: number,
    filtered: number,
    isNext: bool,
    isOpen: bool,
    matches: arrayOf(object),
    noMatch: bool,
    onClick: () => {},
    week: string,
}

ArrowIcon.propTypes = {
    isNext: bool,
}
