import dayjs from "dayjs"
import { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Outlet, matchPath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"

import PageHeader from "../../../../../components/layout/pageHeader/PageHeader.jsx"
import Preloader from "../../../../../components/loaders/preloader/preloader.jsx"
import PayFreeAmount from "../../../../../components/reservation/PayFreeAmount/PayFreeAmount.jsx"
import PreviousStepButton from "../../../../../components/stepsManagement/PreviousStepButton.jsx"
import TabSwitcher from "../../../../../components/TabSwitcher/TabSwitcher.jsx"
import { getLocation } from "../../../../../globalAPI/api.js"
import useLocationVideos from "../../../../../hooks/reservation/location/useLocationVideos.js"
import useProceedCheckout from "../../../../../hooks/reservation/useProceedCheckout.jsx"
import useIsMobile from "../../../../../hooks/useIsMobile.js"
import useResetScroll from "../../../../../hooks/useResetScroll.js"
import { LocationPaths } from "../../../../../routes/ApplicationPaths/locationPaths.js"
import usePath from "../../../../../routes/services/usePath.js"
import {
    hasUserAlreadyPaidWithAvoir,
    hasUserAlreadyPaidWithGymlib,
} from "../../../../../services/locationManagementService.js"
import UserAgentHandler from "../../../../../services/UserAgentHandler.js"
import { useLocationStore } from "../../../store/store.js"
import { isCompositionTabAvailable } from "../../functions/functions.jsx"
import getAppropriateButton from "../../functions/getAppropriateButton.jsx"

import "./LocationLayout.scss"

export const LocationLayout = () => {
    const navigate = useNavigate()
    const { locationId } = useParams()
    const location = useLocation()
    const path = usePath()
    const isMobile = useIsMobile()
    const pageHeaderRef = useRef()
    const [ searchParams ] = useSearchParams()

    const { currentLocation, setCurrentLocation, paymentModalOpen, actionClosePaymentModal } = useLocationStore()
    const { hasVideos } = useLocationVideos(currentLocation)
    const user = useSelector(state => state.userLoaded)

    const [ isLoading, setIsLoading ] = useState(false)
    const [ isAddPaiementPopinOpen, setIsAddPaiementPopinOpen ] = useState(false)
    const [ headingTitle, setHeadingTitle ] = useState("")
    const [ pageHeaderHeight, setPageHeaderHeight ] = useState(0)
    const [ useAlias, setUseAlias ] = useState(false)

    const currentLocationExists = Object.keys(currentLocation).length > 0

    useResetScroll()

    const extraPaymentMethodAlreadyUsed = useMemo(() => {
        return hasUserAlreadyPaidWithGymlib(user?.contactId, currentLocation) || hasUserAlreadyPaidWithAvoir(user?.contactId, currentLocation)
    }, [ location, user, currentLocation ])

    const {
        proceedCheckout,
        payzenForm,
    } = useProceedCheckout(
        {
            customData: {
                centerId: currentLocation.centerId,
                fromGestion: true,
                id: currentLocation.id,
                players: currentLocation.maxAttendees,
                pricePerPart: currentLocation.pricePerPart,
                start: currentLocation.start,
            },
            firstSubscription: currentLocation,
            isUsingUserCB: useAlias,
            lyraPaymentUrl: path("/reserver/paiement/reservation/" + currentLocation?.id),
            redirectToListing: true,
            setAjaxLoading: setIsLoading,
            setPayFreeAmountPopInOpen: setIsAddPaiementPopinOpen,
            tunnelType: "BOOKING",
        },
    )

    useEffect(() => {
        const updateHeaderHeight = () => {
            if (pageHeaderRef.current) {
                setPageHeaderHeight(pageHeaderRef.current.offsetHeight)
            }
        }

        updateHeaderHeight()

        window.addEventListener("resize", updateHeaderHeight)

        return () => window.removeEventListener("resize", updateHeaderHeight)
    }, [])

    useEffect(
        () => {
            if (locationId) {
                setIsLoading(true)
                getLocation(locationId).then(
                    (res) => {
                        if (res?.Message && res?.Message !== "Modèle d'email d'invitation introuvable") {
                            navigate(path("/notfound"))
                        } else {
                            setCurrentLocation(res)

                            if (matchPath(path(LocationPaths.reservationBase), location.pathname)) {
                                navigate(path(
                                    isCompositionTabAvailable(res)
                                        ? LocationPaths.toReservationComposition(res?.id) : LocationPaths.toReservationInfos(res?.id),
                                ))
                            }
                        }
                    },
                ).finally(() => setIsLoading(false))
            } else if (!locationId) {
                navigate(path(LocationPaths.listingReservations))
            }
        }
        , [],
    )

    useEffect(() => {
        if (isMobile && currentLocationExists) {
            setHeadingTitle(dayjs(currentLocation?.start).format("ddd DD/MM - HH[h]mm"))
        } else if (currentLocationExists) {
            setHeadingTitle(dayjs(currentLocation?.start).format("dddd DD MMMM - HH[h]mm"))
        }
    }, [ isMobile, currentLocation ])

    const tabs = useMemo(() => {
        if (currentLocationExists && currentLocation?.id) {
            const baseTabs = [
                {
                    name: "Joueurs",
                    route: path(LocationPaths.toReservationPlayers(currentLocation?.id)),
                },
                {
                    name: "Info",
                    route: path(LocationPaths.toReservationInfos(currentLocation?.id)),
                },

            ]

            if (isCompositionTabAvailable(currentLocation)) {
                baseTabs.unshift({
                    name: "Compo",
                    route: path(LocationPaths.toReservationComposition(currentLocation?.id)),
                })
            }
            if (currentLocation?.price !== 0) {
                baseTabs.push({
                    name: "Paiements",
                    route: path(LocationPaths.toReservationPayments(currentLocation?.id)),
                })
            }
            if (hasVideos || (location.state && location.state?.blockHasVideos)) {
                baseTabs.push({
                    name: "Videos",
                    route: path(LocationPaths.toReservationVideos(currentLocation?.id)),
                })
            }
            return baseTabs
        } else {
            return []
        }
    }, [ currentLocation?.id, hasVideos ])

    useEffect(() => {
        if (paymentModalOpen === true) {
            setIsAddPaiementPopinOpen(true)
            //closes the modal at store level
            actionClosePaymentModal()
        }
    }, [ paymentModalOpen ])

    useEffect(() => {
        // redirection from previous location management "onglet" QP routing
        if (searchParams.get("onglet")) {
            const tab = tabs.find((tab) => tab.route.split("/").pop() === searchParams.get("onglet"))
            if (tab) {
                navigate(tab.route)
            }
        }
    }, [ searchParams, tabs ])

    return (
        <div className={`step arrows-on-sides infoReservation ${(UserAgentHandler.isAndroid() || UserAgentHandler.isIOS()) && !UserAgentHandler.isFirefox() ? "padding-bottom" : ""}`}>
            <PreviousStepButton
                callback={() => navigate(path(LocationPaths.listingReservations))}
                text="&nbsp;"
                height={pageHeaderHeight}
            />
            { isLoading &&
                <Preloader fixed={true}/>
            }

            {payzenForm}
            <PayFreeAmount
                registration={
                    {
                        amountTotal: currentLocation.discount ? currentLocation.price - currentLocation.discount.discount : currentLocation.price,
                        centerName: currentLocation.centerName,
                        discount: currentLocation.discount,
                        dueAmount: currentLocation.amountDue,
                        id: currentLocation.id,
                        playersNumber: currentLocation.maxAttendees,
                        price: currentLocation.price,
                        resourceType: currentLocation.resourceType,
                        resourceTypeDisplay: currentLocation.resourceTypeDisplay,
                        start: currentLocation.start,
                    }
                }
                onUseAlias={(v) => setUseAlias(v)}
                haveDiscount={!!currentLocation?.discount?.code}
                paiementCallback={proceedCheckout}
                withPartPayment={!!currentLocation?.pricePerPart}
                withAvoir={!extraPaymentMethodAlreadyUsed}
                withGymlibPayments={!extraPaymentMethodAlreadyUsed}
                pricePerPart={currentLocation?.pricePerPart || 0}
                isOpen={isAddPaiementPopinOpen}
                setIsOpen={setIsAddPaiementPopinOpen}
                tunnelType="BOOKING"
                canUseAlias
            />

            <PageHeader
                title={headingTitle}
                goBack={path(LocationPaths.listingReservations)}
                customCls="resaColumn"
                hideGoBackDesktop
                button={getAppropriateButton(currentLocation)}
                ref={pageHeaderRef}
            />
            {(isLoading && currentLocation?.id) ? null : (
                <div className={"c-row justify-center location-layout"}>
                    <div className="c-col c-col--10 c-col--sm--12 no-padding stepContent">
                        <TabSwitcher tabs={tabs} />
                        <Outlet/>
                    </div>
                </div>
            )}

        </div>
    )
}
