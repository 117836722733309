import { array, func, string } from "prop-types"
import { useEffect, useState } from "react"

import "./customSelect.scss"

function CustomSelect({ items, defaultOption, clickCallBack }) {
    const [ displayList, setDisplayList ] = useState(false)
    const [ selectedOption, setSelectedOption ] = useState(defaultOption)

    const [ itemsDOM, setItemsDOM ] = useState([])

    const handleClickOutsideFunction = e => {
        let targetElement = e.target // clicked element
        do {
            if (targetElement.id === "productDashboardSelect") {
                // This is a click inside. Do nothing, just return.
                return
            }
            // Go up the DOM
            targetElement = targetElement.parentNode
        } while (targetElement)
        // This is a click outside.
        setDisplayList(false)
    }

    function handleSelectBtnClick() {
        setDisplayList(!displayList)
    }

    function selectOption(idx) {
        setSelectedOption(items[idx].label)
        setDisplayList(false)

        clickCallBack?.(items[idx])
    }

    useEffect(
        () => {
            if (items) {
                const itemsArr = []
                items.forEach((item, idx) => {
                    itemsArr.push(
                        <div key={idx} onClick={() => {selectOption(idx)}}>
                            {item.label}
                        </div>,
                    )
                })
                setItemsDOM(itemsArr)
            }

            document.addEventListener("click", handleClickOutsideFunction)
            return () =>  document.removeEventListener("click", handleClickOutsideFunction)
        }, [ items ],
    )

    return (
        <div id="productDashboardSelect" className="selectContainer">
            <div className="selectBtn" onClick={handleSelectBtnClick}>
                <span>{selectedOption}</span>
            </div>

            <div  className={displayList ? "list" : "list hide-list"}>
                {itemsDOM}
            </div>
        </div>
    )

}

CustomSelect.propTypes = {
    clickCallBack: func,
    defaultOption: string,
    items: array,
}

export default CustomSelect
