import getPreregistrationMethodFor from "./getPreregistrationMethodFor"
import { clearAllForms } from "../../../../store/forms/actions"

function preregister(
    tunnelType,
    setAjaxLoading,
    firstSubscription,
    dispatch,
    navigate,
    path,
) {
    const preregisterMethod = getPreregistrationMethodFor(tunnelType)

    setAjaxLoading(true)
    
    preregisterMethod(firstSubscription.id).then(
        (res) => {
            let limitDate
            let confirmedSub
            let title = "Tu y es presque&nbsp;!"
            const lastUniverse = localStorage.getItem("lastUniverse")
            const message = lastUniverse === "theme-padel" ? "Tu y es presque&nbsp;!" : "Vous y êtes presque&nbsp;!"
            switch (tunnelType) {
            case "SCHOOL":
                limitDate = res?.preregistrationLimitDate
                confirmedSub = res
                title = message
                break
            case "TRAINING":
                limitDate = res.data.data?.preregistrationLimitDate
                confirmedSub = res.data.data
                title = message
                break
            default:
                limitDate = res.data.data.preRegistrationLimitDate
                confirmedSub = res.data.data
                break
            }
            
            dispatch(
                clearAllForms(),
            )
            navigate(path("/confirmcommand"), {
                state: {
                    confirmedSubscription: confirmedSub,
                    firstSubscription: firstSubscription,
                    limitDate: limitDate,
                    message: "preregisterConfirm",
                    subtitle: "",
                    success: true,
                    title: title,
                    tunnelType: tunnelType,
                },
            })
        },
    )
}

export default preregister
