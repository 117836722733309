import PropTypes from "prop-types"
import { createContext, useCallback, useContext, useState } from "react"

const DidomiDataLayerContext = createContext({})

export const useDidomiDataLayerContext = () => {
    const context = useContext(DidomiDataLayerContext)
    if (!context) {
        throw new Error("useDidomiDataLayerContext must be used within a DidomiDataLayerProvider")
    }
    return context
}

export const DidomiDataLayerProvider = ({ children }) => {
    const [ events, setEvents ] = useState([])
    const [ isModalOpen, setIsModalOpen ] = useState(false)

    const addEvent = useCallback((eventData) => {
        setEvents(prevEvents => [ ...prevEvents, {
            data: eventData,
            timestamp: new Date().toISOString(),
        } ])
    }, [])

    const clearEvents = useCallback(() => {
        setEvents([])
    }, [])

    const toggleModal = useCallback(() => {
        setIsModalOpen(prev => !prev)
    }, [])

    const value = {
        addEvent,
        clearEvents,
        events,
        isModalOpen,
        toggleModal,
    }

    return (
        <DidomiDataLayerContext.Provider value={value}>
            {children}
        </DidomiDataLayerContext.Provider>
    )
}

DidomiDataLayerProvider.propTypes = {
    children: PropTypes.node.isRequired,
} 
