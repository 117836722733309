import PropTypes from "prop-types"
import { useState } from "react"

import { useDidomiDataLayerContext } from "../../contexts/DidomiDataLayerContext"
import ButtonCta from "../Buttons/button/button-cta"
import Modal from "../Modal/Modal"

import "./DidomiDataLayerModal.scss"

const getEventColor = (eventType) => {
    const colorMap = {
        "click.navigation": "#9C27B0",
        "dataLayer_ready": "#2196F3", 
        "default": "#ff9800",
        "product.add_to_cart": "#FF5722",
        "product.purchased": "#4caf50",
        "transaction.confirmation": "#003366",
    }
    
    return colorMap[eventType] || colorMap.default
}

// Helper function to stringify object while preserving undefined values
const stringifyWithUndefined = (obj) => {
    const replacer = (key, value) => (value === undefined ? "undefined" : value)
    return JSON.stringify(obj, replacer, 2)
}

const EventItem = ({ event }) => {
    const [ isExpanded, setIsExpanded ] = useState(false)
    const eventType = event.data.event || "default"
    const eventColor = getEventColor(eventType)

    return (
        <div className="event-item">
            <div className="timeline-dot" style={{ backgroundColor: eventColor }} />
            <div className="event-content">
                <div className="event-header" onClick={() => setIsExpanded(!isExpanded)}>
                    <div className="event-basic-info">
                        <span className="event-type" style={{ color: eventColor }}>
                            {eventType}
                        </span>
                        <span className="event-timestamp">
                            {new Date(event.timestamp).toLocaleString()}
                        </span>
                    </div>
                    <button className={`expand-button ${isExpanded ? "expanded" : ""}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24">
                            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
                        </svg>
                    </button>
                </div>
                {isExpanded && (
                    <div className="event-details">
                        <pre className="event-data">
                            {stringifyWithUndefined(event.data)}
                        </pre>
                    </div>
                )}
            </div>
        </div>
    )
}

EventItem.propTypes = {
    event: PropTypes.shape({
        data: PropTypes.shape({
            event: PropTypes.string,
        }).isRequired,
        timestamp: PropTypes.string.isRequired,
    }).isRequired,
}

export const DidomiDataLayerModal = () => {
    const { events, isModalOpen, toggleModal, clearEvents } = useDidomiDataLayerContext()

    const modalVariants = {
        closed: {
            left: "-100%",
            opacity: 0,
            transition: {
                duration: 0.3,
                ease: "easeInOut",
            },
        },
        open: {
            left: "0%",
            opacity: 1,
            transition: {
                duration: 0.3,
                ease: "easeInOut",
            },
        },
    }

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={toggleModal}
            className="didomi-datalayer-modal"
            modalVariantsOverride={modalVariants}
            withOverlay={false}
        >
            <div className="didomi-datalayer-modal__header">
                <h2>DataLayer Events</h2>
                <ButtonCta
                    text="Vider les évènements"
                    onClick={clearEvents}
                    className="clear-button"
                />
            </div>
            <div className="didomi-datalayer-modal__content">
                {events.length === 0 ? (
                    <p className="no-events">Aucun évènement pour l'instant</p>
                ) : (
                    <div className="events-timeline">
                        {events.map((event, index) => (
                            <EventItem key={index} event={event} />
                        ))}
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default DidomiDataLayerModal 
