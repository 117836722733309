import { func, string } from "prop-types"

import { useSchoolTrainingCurrent, useSchoolTrainingTunnel } from "../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import { EcoleDeFootProducts } from "../../../kids/ecoleDeFoot/components/EcoleDeFootProducts/EcoleDeFootProducts.jsx"
import { StageDeFootProducts } from "../../../kids/stageDeFoot/components/StageDeFootProducts/StageDeFootProducts.jsx"

export default function SchoolTrainingEquipement() {
    const { value: tunnelType } = useSchoolTrainingTunnel()
    const { value: { isAdult, selectedSlot } } = useSchoolTrainingCurrent()

    const target = slot => tunnelType === "TRAINING" ? slot : slot?.schoolDetails
    const haveOption = () => {
        return target(selectedSlot)?.optionProducts?.filter(option => !option.showAtPayment)?.length > 0
    }

    let stepNumber = 6
    if (!haveOption()) {
        stepNumber -= 1
    }
    if (isAdult) {
        stepNumber -= 1
    }
    if (!target(selectedSlot)?.catalogProducts?.length) {
        stepNumber = undefined
    } else {
        stepNumber += "/"
    }

    return (
        <>
            {tunnelType === "TRAINING" ? (
                <StageDeFootProducts numero={stepNumber}/>
            ) : (
                <EcoleDeFootProducts numero={stepNumber}/>
            )}
        </>
    )
}

SchoolTrainingEquipement.propTypes = {
    theme: string,
    updateSearchParams: func,
}
