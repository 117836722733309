/* eslint-disable */
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useTheme, useThemeInitialized } from '../components/ThemeContext/ThemeContext.jsx'
import { useDidomiDataLayerContext } from '../contexts/DidomiDataLayerContext'
import { getBookingFunnelType, getChapters, getDevice, getLang, getUserData } from '../services/didomiService.js'
import { isBusinessEnvironment } from '../utilities/helpers.js'
import useThemeFeatures from './useThemeFeatures.js'

export const useDidomiCustomEvent = () => {
    const {
        addEvent,
    } = useDidomiDataLayerContext()

    return (eventData) => {
        if (
            import.meta.env.DEV) {
            console.log("DidomiCustomEvent", eventData)
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(eventData)
        addEvent(eventData)
    }
}

export const useDidomiEcommerceEvent = () => {
    const pushDataLayer = useDidomiCustomEvent()

    return {
        trackEcommerceEvent: (event, eventData) => {
            // Check for duplicate transactions only for product.purchased events
            if (event === 'product.purchased') {
                const transactionId = eventData?.transaction_id
                if (transactionId) {
                    const storedTransactions = JSON.parse(localStorage.getItem('didomi_tracked_transactions') || '[]')

                    // If transaction already tracked, skip the event
                    if (storedTransactions.includes(transactionId)) {
                        if (
                            import.meta.env.DEV) {
                            console.log("Skipping duplicate transaction:", transactionId)
                        }
                        return
                    }

                    storedTransactions.push(transactionId)
                    localStorage.setItem('didomi_tracked_transactions', JSON.stringify(storedTransactions))
                }
            }

            pushDataLayer({
                data: {
                    ...eventData,
                },
                event,
            })
        },
    }
}

export const useLoginTracking = () => {
    const pushDataLayer = useDidomiCustomEvent()

    return {
        trackLoginAttempt: (success, loginMethod = "mail") => {
            pushDataLayer({
                event: "click.navigation",
                click: "account",
                click_chapter1: "connexion",
                click_chapter2: loginMethod,
                click_chapter3: success ? "success" : "fail",
            })
        },
    }
}

export const useDidomiDataLayer = (maxChapters = 3) => {
    const location = useLocation()
    const theme = useTheme()
    const themeFeatures = useThemeFeatures()
    const [themeInitialized] = useThemeInitialized()
    const userLoaded = useSelector(state => state.userLoaded)
    const userData = useMemo(() => getUserData(userLoaded), [userLoaded?.id])
    const startIndex = theme === "theme-padel" ? 1 : 0
    const {
        addEvent,
    } = useDidomiDataLayerContext()

    useEffect(() => {
        let eventData = {
            "event": "dataLayer_ready",
            "env_device": getDevice(),
            "env_language": getLang(),
            "env_template": "default",
            "env_work": window.location.hostname === "myurban.fr" ? "prod" : "preprod",
        }

        const chapters = getChapters(location.pathname)
        for (let index = 0; index < maxChapters; index++) {
            eventData[`page_chapter${index + 1}`] = chapters.length > index ? chapters[index + startIndex] : undefined
        }

        eventData["page_name"] = document.title
        eventData["booking_funnel_type"] = getBookingFunnelType(location, themeFeatures)

        eventData = {
            ...eventData,
            ...userData,
        }

        if (location.pathname === '/ordersummary' || location.pathname === '/padel/ordersummary' ||
            !(location.pathname.includes('signup') || location.pathname.includes('login')) && userData.user_status === "nonlogged" ||
            location.search.includes('?action=') || (location.pathname === "/" && isBusinessEnvironment() && !themeInitialized)
        ) {
            return
        } else {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(eventData)
            addEvent(eventData)
        }

        if (
            import.meta.env.DEV) {
            console.log("current datalayer context:", window.dataLayer)
        }

    }, [location.pathname, userLoaded?.id, theme])
}

export const DidomiDataLayerTracker = () => {
    useDidomiDataLayer()
    return null
}
