import classNames from "classnames"
import { bool, func, number, oneOfType, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import MesEnfants from "./MesEnfants"
import ButtonCta from "../../../components/Buttons/button/button-cta"
import CurrencyDisplay from "../../../components/currencies/CurrencyDisplay"
import { TextInput } from "../../../components/Inputs/TextInput/TextInput.jsx"
import Preloader from "../../../components/loaders/preloader/preloader"
import Modal from "../../../components/Modal/Modal"
import CbAliasError from "../../../components/popin/CbAliasError/CbAliasError"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import SummaryCartEquimentLine
    from "../../../components/reservation/Shop/SummaryCartEquimentLine/SummaryCartEquimentLine.jsx"
import Step from "../../../components/stepsManagement/Step"
import { useTheme } from "../../../components/ThemeContext/ThemeContext"
import HourDisplay from "../../../components/Time/HourDisplay"
import AvoirCheckbox from "../../../entries/main/contents/main/helpers/AvoirCheckbox"
import { addOptionToSchool, deleteCreditCard, getCreditCard } from "../../../globalAPI/api"
import { getInCache, saveInCache } from "../../../hooks/reservation/useCache.js"
import useMediaPath from "../../../hooks/useMediaPath"
import useResetScroll from "../../../hooks/useResetScroll"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import days from "../../../model/enums/dayValues"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import usePath from "../../../routes/services/usePath"
import { editUserSessionMeta } from "../../../store/actions/content"
import { clearAllForms, setFormEntry } from "../../../store/forms/actions"
import { goToStep } from "../../../store/stepsManagement/actions"
import {
    addSchoolDiscountCode,
    cancelSchoolRegistration,
    deleteSchoolDiscountCode,
    deleteSchoolOption,
    generatePayzenSchoolPaymentReqs,
    getKidsSchoolDetail,
    preregisterSchoolSubscription,
} from "../ecoleDeFoot/api"
import CreneauxDispo from "../ecoleDeFoot/steps/CreneauxDispo"

import "./OrderSummary.scss"

function OrderSummary(props) {
    useResetScroll()
    const themeFeatures = useThemeFeatures()

    let { title, isPreregistration, customCls, backToStep } = props
    const location = useLocation()
    if (location?.state) {
        title = location?.state.title
        isPreregistration = location?.state.isPreregistration
    }

    const cgvUrl = themeFeatures([ "https://www.urbansoccer.fr/cgv-urbansoccer/","https://www.urbanpadel.fr/mentions-legales/cgv-urbanpadel/ " ])

    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ codeError, setCodeError ] = useState(false)
    const [ optionsAtPayment, setOptionsAtPayment ] = useState([])
    const [ promoCode, setPromoCode ] = useState("")
    const [ availableDiscountCodes, setAvailableDiscountCodes ] = useState([])
    const [ cbCard, setCbCard ] = useState({})
    const [ isUsingUserCB, setIsUsingUserCB ] = useState(false)
    const [ paymentEnabled, setPaymentEnabled ] = useState(false)
    const [ representantLegalChecked, setRepresentantLegalChecked ] = useState(isPreregistration)
    const [ showAcceptCGV, setShowAcceptCGV ] = useState(false)

    const promoCodeInput = useRef(null)

    const forms = useSelector(state => state.forms)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = usePath()
    const mediaPath = useMediaPath()
    const theme = useTheme()

    useEffect(
        () => {

            const firstSubscription = forms.ozmoSubscription.firstSubscription
            setAvailableDiscountCodes(
                forms[CreneauxDispo.formName] !== undefined ?
                    forms[CreneauxDispo.formName].selectedTimeslot ?
                        forms[CreneauxDispo.formName].selectedTimeslot.schoolDetails.discountCodes
                        : []
                    : [],
            )
            firstSubscription.discountCodes.forEach(
                code => {
                    if (!code.secondRegistrationDiscount) {
                        setPromoCode(code.code)
                    }
                },
            )
            getKidsSchoolDetail(firstSubscription.school.id).then(
                (res) => {
                    // setOptionsProduct(res.data.optionProducts);
                    setAvailableDiscountCodes(res.data.discountCodes)
                },
            )

            setAjaxLoading(true)

            getCreditCard().then((result) => {
                if (result.data.data.tokenDetails) {
                    setCbCard(result.data.data)
                    setAjaxLoading(false)
                } else {
                    setAjaxLoading(false)
                }
                if (result && result.data && result.data.data && result.data.data.errorCode) {
                    deleteCreditCard()
                    ModalHandler.show(CbAliasError)
                }
            })
        }, [],
    )

    function sendPayzenForm(lyraPaymentUrl, data, returnUrl) {
        if (data) {
            if (data.ipn) {
                returnUrl += "&ipn=" + encodeURIComponent("/api/" + data.ipn.split("/US-API/myurban/")[1])
            }

            navigate(lyraPaymentUrl, { state: { lyraEmbedParams: { ...data, returnUrl }, prevLocation:location } })
        }
    }

    function addPromoCode() {
        setAjaxLoading(true)
        const lastSubscription = forms["ozmoSubscription"].firstSubscription

        const avoirFormData = forms.avoirFormData !== undefined ?
            forms.avoirFormData
            : {}

        const isResultOK = (res) => {
            return (res.data.data.subscription !== null && res.data !== null) && (res.data.data.message === "" || res.data.data.message === undefined)
        }
        const addPromocode = () => {
            if (!promoCode) {
                setCodeError(true)
                setAjaxLoading(false)
                return
            }
            availableDiscountCodes.forEach(
                discountCode => {
                    if (discountCode.secondRegistrationDiscount) {
                        if (promoCode === discountCode.code) {
                            setCodeError(true)
                            setPromoCode("")
                            setAjaxLoading(false)
                            return
                        }
                    }
                },
            )
            addSchoolDiscountCode(lastSubscription.id, promoCode).then(
                (res2) => {
                    if (isResultOK(res2)) {
                        dispatch(
                            setFormEntry(
                                "ozmoSubscription", {
                                    ...forms["ozmoSubscription"],
                                    firstSubscription: res2.data.data.subscription,
                                },
                            ),
                        )
                        dispatch(
                            setFormEntry(
                                "avoirFormData", {
                                    ...avoirFormData,
                                    recalcAvoir: true,
                                },
                            ),
                        )
                        setCodeError(false)
                    } else {
                        setCodeError(true)
                        setPromoCode("")
                    }

                    setAjaxLoading(false)
                },
            ).catch(() => {
                setCodeError(true)
                setPromoCode("")
                setAjaxLoading(false)
            })
        }
        /* check if there is already a discount code and if true, delete it before adding the new one */
        if (lastSubscription.discountCodes.length > 0) {
            const oldCodes = lastSubscription.discountCodes
            let hasNoOldCode = true
            oldCodes.forEach(
                code => {
                    if (!code.secondRegistrationDiscount) {
                        hasNoOldCode = false
                        deleteSchoolDiscountCode(lastSubscription.id, code.code).then(
                            (res) => {
                                if (isResultOK(res)) {
                                    dispatch(
                                        setFormEntry(
                                            "ozmoSubscription", {
                                                ...forms["ozmoSubscription"],
                                                firstSubscription: res.data.data,
                                            },
                                        ),
                                    )
                                    dispatch(
                                        setFormEntry(
                                            "avoirFormData", {
                                                ...avoirFormData,
                                                recalcAvoir: true,
                                            },
                                        ),
                                    )
                                }
                                addPromocode()
                            },
                        )
                    }
                },
            )
            if (hasNoOldCode) {
                addPromocode()
            }
        } else {
            addPromocode()
        }
    }

    function deleteDiscountCode() {
        const lastSubscription = forms["ozmoSubscription"].firstSubscription

        const avoirFormData = forms.avoirFormData !== undefined ?
            forms.avoirFormData
            : {}

        setAjaxLoading(true)
        deleteSchoolDiscountCode(lastSubscription.id, promoCode).then(
            (res) => {
                dispatch(
                    setFormEntry(
                        "ozmoSubscription", {
                            ...forms["ozmoSubscription"],
                            firstSubscription: res.data.data,
                        },
                    ),
                )
                dispatch(
                    setFormEntry(
                        "avoirFormData", {
                            ...avoirFormData,
                            recalcAvoir: true,
                        },
                    ),
                )
                setAjaxLoading(false)
            },
        )
    }

    function handleCGV(e) {
        setPaymentEnabled(e.target.checked)
    }

    function handleRepresentantLegal(e) {
        setRepresentantLegalChecked(e.target.checked)
    }

    function proceedCheckout(withMultiPayments) {
        setAjaxLoading(true)
        const subs = forms.ozmoSubscription
        const firstSubscriptionId = subs.firstSubscription !== undefined ? subs.firstSubscription.id : undefined
        const secondSubscriptionId = subs.secondSubscription !== undefined ? subs.secondSubscription.id : undefined

        const avoirFormData = forms.avoirFormData !== undefined ?
            forms.avoirFormData
            : {}

        let amountTotal = subs.firstSubscription !== undefined ? subs.firstSubscription.amountTotal : 0
        let secondAmountTotal = subs.secondSubscription !== undefined ? subs.secondSubscription.amountTotal : 0
        let total = amountTotal + secondAmountTotal

        let returnUrl = import.meta.env.VITE_URL_BASE + `/?action=payzenPayment&type=school&theme=${theme}`
        if (firstSubscriptionId !== undefined) {
            returnUrl += "&firstSub=" + firstSubscriptionId
        }
        if (secondSubscriptionId !== undefined) {
            returnUrl += "&secondSub=" + secondSubscriptionId
        }
        if (isPreregistration) {
            returnUrl += "&preregistration=true"
        }

        let data = {
            askRegisterPay: isUsingUserCB,
            returnUrl: returnUrl,
            subscriptionId: firstSubscriptionId,
        }
        if (withMultiPayments) {
            data.withMultiPayments = withMultiPayments
        }
        if (secondSubscriptionId !== undefined) {
            data.secondSessionId = secondSubscriptionId
        }

        if (isUsingUserCB) {
            data.withAlias = true
        }

        if (avoirFormData.checkAvoir) {
            data.allocateCredits = true
        }

        generatePayzenSchoolPaymentReqs(data).then(
            (res) => {
                if (avoirFormData.checkAvoir && avoirFormData.avoirAmount >= total) {
                    clearAllForms()
                    goToStep(0)
                    const lastUniverse = localStorage.getItem("lastUniverse")
                    const you = lastUniverse === "theme-padel" ? "te" : "vous"
                    const you2 = lastUniverse === "theme-padel" ? "t'" : "vous "
                    const your = lastUniverse === "theme-padel" ? "ton" : "votre"
                    navigate(path("/confirmcommand"), {
                        state: {
                            confirmedSubscription: subs.firstSubscription,
                            message: `Un email de confirmation ${you2}a été envoyé`,
                            secondSubscription: subs?.secondSubscription,
                            subtitle: `Nous ${you} confirmons l'inscription de ${your} enfant.`,
                            success: true,
                            title: "Inscription réussie !",
                            tunnelType: "SCHOOL",
                        },
                    })
                } else {
                    editUserSessionMeta("beforePayzenForm", true)

                    sendPayzenForm(
                        path(`/order/payment/school/${data.subscriptionId}`),
                        res,
                        returnUrl,
                    )
                }
                setAjaxLoading(false)
            },
        ).catch(() => setAjaxLoading(false))
    }

    function addSchoolOption(event) {
        const subs = forms.ozmoSubscription
        const firstSubscriptionId = subs.firstSubscription !== undefined ? subs.firstSubscription.id : undefined

        const avoirFormData = forms.avoirFormData !== undefined ?
            forms.avoirFormData
            : {}

        setAjaxLoading(true)

        const input = event.target
        const optionId = input.id.split("-")[1]
        if (input.checked) {
            addOptionToSchool(firstSubscriptionId, optionId).then(
                async (res) =>{
                    await dispatch(
                        setFormEntry(
                            "ozmoSubscription", {
                                ...forms["ozmoSubscription"],
                                firstSubscription: res,
                            },
                        ),
                    )
                    await dispatch(
                        setFormEntry(
                            "avoirFormData", {
                                ...avoirFormData,
                                recalcAvoir: true,
                            },
                        ),
                    )
                    setAjaxLoading(false)
                },
            )
        } else {
            deleteSchoolOption(firstSubscriptionId, optionId).then(
                async (res) =>{
                    await dispatch(
                        setFormEntry(
                            "ozmoSubscription", {
                                ...forms["ozmoSubscription"],
                                firstSubscription: res,
                            },
                        ),
                    )
                    await dispatch(
                        setFormEntry(
                            "avoirFormData", {
                                ...avoirFormData,
                                recalcAvoir: true,
                            },
                        ),
                    )
                    setAjaxLoading(false)
                },
            )
        }
    }

    function handlePromoCodeSubmit() {
        const ozmoSubscription = forms.ozmoSubscription
        const firstSubscription = ozmoSubscription ? ozmoSubscription.firstSubscription : undefined

        let firstSubHasDiscountCode = false
        let firstSubDiscount = 0
        firstSubscription.discountCodes.forEach(
            (code) => {
                if (code.secondRegistrationDiscount) {
                    firstSubDiscount = firstSubDiscount + code.amount
                } else {
                    firstSubHasDiscountCode = true
                }
            },
        )

        if (!isPreregistration) {
            if (firstSubHasDiscountCode) {
                deleteDiscountCode()
                if (promoCode !== "" || codeError !== false) {
                    setCodeError(false)
                    setPromoCode("")
                }
            } else {
                addPromoCode()
            }
        }
    }

    function preregister() {
        const ozmoSubscription = forms.ozmoSubscription
        let data = {
            subscriptionId: ozmoSubscription.firstSubscription.id,
        }
        if (ozmoSubscription.secondSubscription !== undefined) {
            data.secondSessionId = ozmoSubscription.secondSubscription.id
        }
        setAjaxLoading(true)

        preregisterSchoolSubscription(data).then(
            (res) => {
                clearAllForms()
                goToStep(0)
                navigate(path("/confirmcommand"), {
                    state: {
                        confirmedSubscription: res,
                        limitDate: res.preregistrationLimitDate,
                        message: "preregisterConfirm",
                        subtitle: "",
                        success: true,
                        title: themeFeatures([ "Vous y êtes presque&nbsp;!", "Tu y es presque&nbsp;!" ]),
                        tunnelType: "SCHOOL",
                    },
                })
            },
        )
    }

    function cancelRegistration() {
        const firstSubscription = forms.ozmoSubscription.firstSubscription
        setAjaxLoading(true)
        Promise.all([
            cancelSchoolRegistration(firstSubscription.id),
            ...(forms.ozmoSubscription?.secondSubscription ? [
                cancelSchoolRegistration(forms.ozmoSubscription.secondSubscription.id),
            ] : []),
        ]).then(
            () => {
                navigate(path("/confirmcommand"), {
                    state: {
                        confirmedSubscription: firstSubscription,
                        message: theme === "theme-padel" ? "Nous te confirmons avoir annulé ta pré-inscription." : "Nous vous confirmons avoir annulé votre pré-inscription.",
                        success: true,
                        title: "Pré-inscription annulée",
                        tunnelType: "SCHOOL",
                    },
                })
                setAjaxLoading(false)
            },
        )
    }

    function useUserCB(e) {
        setIsUsingUserCB(e.target.checked)
    }

    const ozmoSubscription = forms.ozmoSubscription
    const firstSubscription = ozmoSubscription ? ozmoSubscription.firstSubscription : undefined
    const secondSubscription = ozmoSubscription ? ozmoSubscription.secondSubscription : undefined
    const schoolSub = forms[CreneauxDispo.formName] !== undefined ?
        forms[CreneauxDispo.formName].selectedTimeslot
        :
        undefined

    const avoirFormData = forms.avoirFormData !== undefined ?
        forms.avoirFormData
        : {}

    if (!paymentEnabled && ((firstSubscription && firstSubscription.statusValue === 2) || isPreregistration)) {
        setPaymentEnabled(true)
    }

    if (!representantLegalChecked && (firstSubscription.statusValue === 2 || !firstSubscription?.kid)) {
        setRepresentantLegalChecked(true)
    }

    let amountTotal = firstSubscription !== undefined ? firstSubscription.amountTotal : 0
    let secondAmountTotal = secondSubscription !== undefined ? secondSubscription.amountTotal : 0
    let total = amountTotal + secondAmountTotal

    // handle total amount with avoir
    if (avoirFormData?.totalWithAvoir !== undefined) {
        total = avoirFormData.totalWithAvoir
    }

    const mesEnfants = forms[MesEnfants.formName]
    const child = mesEnfants ? mesEnfants.selectedKid : undefined
    let childName
    if (child !== undefined) {
        childName = `${child?.firstName} ${child.lastName}`
    } else {
        childName = `${firstSubscription?.kid?.firstName} ${firstSubscription?.kid?.lastName}`
    }

    let totalTva
    totalTva = firstSubscription.proforma ? (firstSubscription.proforma.amountTTC - firstSubscription.proforma.amountHT) : 0
    if (secondSubscription) {
        totalTva += secondSubscription.proforma.amountTTC - secondSubscription.proforma.amountHT
    }

    let totalTvaAvoirComponent = firstSubscription.proforma ? (firstSubscription.proforma.amountTTC - firstSubscription.proforma.amountHT) : 0
    if (secondSubscription) {
        totalTvaAvoirComponent += secondSubscription.proforma.amountTTC - secondSubscription.proforma.amountHT
    }

    // handle tva amount with avoir
    if (avoirFormData?.tvaWithAvoir !== undefined) {
        totalTva = avoirFormData.tvaWithAvoir
    }

    // handle avoir amount
    // let avoirAmount = 0;
    // if (avoirFormData && avoirFormData.avoirAmount !== undefined) {
    //     avoirAmount = avoirFormData.avoirAmount;
    // }

    const schoolDetails = firstSubscription.school
    const secondSchoolDetails = secondSubscription ? secondSubscription.school : undefined

    const firstCartOptions = firstSubscription?.optionProducts ?? []
    const secondCartOptions = secondSubscription?.optionProducts ?? []
    const cartOptions = firstCartOptions.concat(secondCartOptions)
    let optionsDOM = []

    cartOptions.forEach(
        (option) => {
            optionsDOM.push(
                <li key={option.category === null ? "Autres-" + option.id : option.category + "-" + option.id}>
                    <div className="info">{option.category === null ? "Option" : option.category} : {option.name}</div>
                    <div className="price">
                        <CurrencyDisplay price={option.price}/>
                    </div>
                </li>,
            )
        },
    )

    const firstEquipments = firstSubscription?.catalogArticles ?? []
    const secondEquipments = secondSubscription?.catalogArticles ?? []
    const equipments = firstEquipments.concat(secondEquipments)
    let cartLines = []
    let equipmentSubTotal = 0
    equipments.forEach(
        (equipment) => {
            equipmentSubTotal += Number.parseInt(equipment.quantity) * Number.parseFloat(equipment.product.price)
            cartLines.push(
                <li key={`product--${equipment.id}`}>
                    <SummaryCartEquimentLine
                        productOrder={equipment}
                        productInfos={equipment.product}
                        disableDeletion={isPreregistration}
                        tunnelType="SCHOOL"
                        blackEquipmentCross
                    />
                </li>,
            )
        },
    )

    let equipmentDiscountOnSubTotal = firstSubscription.catalogDiscountAmount

    const help = <PhoneCallHelp tunnelType="SCHOOL"/>

    let promoCodeDiscount = 0
    if (firstSubscription !== undefined) {
        firstSubscription?.discountCodes?.forEach(
            code => {
                if (!code.secondRegistrationDiscount) {
                    promoCodeDiscount += code.amount
                }
            },
        )
    }

    let firstSubOptionsIds = []
    firstSubscription?.optionProducts?.forEach(
        (el) => {
            firstSubOptionsIds.push(el.id)
        },
    )

    let firstSubHasDiscountCode = false
    let firstSubDiscount = 0
    firstSubscription.discountCodes.forEach(
        (code) => {
            if (code.secondRegistrationDiscount) {
                firstSubDiscount += code.amount
            } else {
                firstSubHasDiscountCode = true
            }
        },
    )

    useEffect(() => {
        setShowAcceptCGV(false)
    }, [ paymentEnabled, representantLegalChecked ])

    useEffect(() => {
        const optionProducts = schoolSub?.schoolDetails?.optionProducts ?? []
        let optionsAtPayment = []

        optionProducts.forEach(option => {
            if (option.showAtPayment) {
                optionsAtPayment.push(option)
            }
        })

        setOptionsAtPayment(optionsAtPayment)

        if (firstSubscription?.id) {
            if (optionsAtPayment.length) {
                saveInCache({
                    key: "optionsAtPayment" + firstSubscription.id,
                    overRide: true,
                    theme: "SCHOOL",
                    value: optionsAtPayment,
                })
            } else {
                const inCache = getInCache("optionsAtPayment" + firstSubscription.id, "SCHOOL")
                if (inCache.length) {
                    setOptionsAtPayment(inCache)
                }
            }
        }
    }, [ schoolSub?.id ])

    const buttonsCount = () => {
        let count = 1
        if (isPreregistration) {count += 1}
        if (!isPreregistration && schoolDetails.preregistrationEnabled) {count += 1}
        return count
    }

    return (
        <Step
            {...props}
            title={title ? title : "Ma commande"}
            help={ help }
            helpId={"OrderSummaryHelp"}
            cls={classNames("arrows-on-sides", {
                [customCls]: customCls,
                preregistration: isPreregistration,
            })}
            returnText={ isPreregistration || forms?.isAbandonned ? <>Voir<br/>mes {theme === "theme-soccer" ? "écoles" : "cours"}</> : undefined }
            onReturn={ isPreregistration || forms?.isAbandonned ? () => {
                navigate(themeFeatures([ "/kids/ecoleDeFoot", "/padel/cours" ]))
            } : undefined }
            goToPreviousStep={backToStep}
            otherId='last'
            hideNextBtn
        >
            {ajaxLoading &&
                <Preloader fixed />
            }
            <div className="formsHolder c-row c-mrg--btm--4 c-mrg--btm--sm--1 orderSummary kids layoutSmall c-col c-col--10">
                <div className="c-col c-col--content c-col--xm--12">
                    <div className="cartPriceDetails">
                        <div className="cartPriceDetails__heading">
                            {schoolDetails.pictureUrl && (
                                <div className="logo">
                                    <img src={schoolDetails.pictureUrl} />
                                </div>
                            )}

                            <div className="content">
                                <div className="name">{ child ? childName : `${schoolDetails.type} - ${schoolDetails.centerName}`}</div>
                                {child && <div className="center">{schoolDetails.type} - {schoolDetails.centerName}</div>}
                                <div className="time">{days[schoolDetails.day]} {schoolDetails.startDate} : <HourDisplay minutes={schoolDetails.hour}/> - <HourDisplay minutes={schoolDetails.hour + schoolDetails.rentalDuration}/> | {schoolDetails.resourceType === "Filmé indoor" ? "Intérieur" : schoolDetails.resourceType}  </div>
                                <div className="price show-sm">
                                    <CurrencyDisplay price={schoolDetails.amountTotal - firstSubDiscount}/>
                                </div>
                            </div>

                            <div className="price hide-sm">
                                <CurrencyDisplay price={schoolDetails.amountTotal - firstSubDiscount}/>
                            </div>
                        </div>

                        <ul className="cartPriceDetails__content">
                            {secondSubscription &&
                                (<li>
                                    <div className="info">
                                        Deuxième séance : {days[secondSchoolDetails.day]} <HourDisplay minutes={secondSchoolDetails.hour}/> - <HourDisplay minutes={(secondSchoolDetails.hour + secondSchoolDetails.rentalDuration)}/> | {secondSchoolDetails.resourceType === "Filmé indoor" ? "Intérieur" : secondSchoolDetails.resourceType}
                                    </div>
                                    <div className="price">
                                        <CurrencyDisplay price={secondAmountTotal}/>
                                    </div>
                                </li>)
                            }
                            {optionsDOM.length > 0 &&
                                optionsDOM
                            }
                        </ul>

                    </div>

                    {cartLines.length > 0 &&
                        (<div className="cartOrderDetails c-mrg--btm--sm--1">
                            <div className="h3">Mes équipements</div>
                            <ul className="cartOrderDetails__list">
                                { cartLines }
                            </ul>

                            <ul className="cartOrderDetails__price">
                                { equipmentDiscountOnSubTotal < 0 &&
                                    (<li className="discount">
                                        <div className="info">Remise équipement :</div>
                                        <div className="price"><CurrencyDisplay price={equipmentDiscountOnSubTotal}/></div>
                                    </li>)
                                }
                                <li className="subtotal">
                                    <div className="info">Total équipement :</div>
                                    <div className="price">
                                        <CurrencyDisplay price={(equipmentSubTotal + equipmentDiscountOnSubTotal)} formatZero={true}/>
                                    </div>
                                </li>
                            </ul>
                        </div>)
                    }
                </div>

                <div className="c-col c-col--sidebar c-col--xm--12">
                    <div className={isPreregistration ? "cartBox cartBoxPreRegistration" : "cartBox"}>
                        {optionsAtPayment.length > 0 &&
                            optionsAtPayment.map(
                                (el) => (
                                    <div className="cartBox__content bordered" key={"optAtPayment-" + el.id}>
                                        <div className="c-inputHolder--checkbox">
                                            <input
                                                type="checkbox"
                                                value=""
                                                disabled={isPreregistration}
                                                onChange={addSchoolOption}
                                                // checked={firstSubOptionsIds.includes(el.id)}
                                                defaultChecked={firstSubOptionsIds.includes(el.id)}
                                                id={"optAtPayment-" + el.id}
                                            />
                                            <label htmlFor={"optAtPayment-" + el.id}>{el.name === "Assur Annul EdF" ? "Assurance annulation" : el.name}</label>
                                            {el.description && (
                                                <img
                                                    className="cancelInsurance"
                                                    src={mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                    onMouseOver={e => e.currentTarget.src = mediaPath([ "/assets/icons/info-small-orange-hover.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                    onMouseOut={e => e.currentTarget.src = mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}                                                    onClick={
                                                        () => {
                                                            ModalHandler.show(InsurancePopIn, {
                                                                optionInfo: {
                                                                    description: el.description,
                                                                    title: el.name,
                                                                },
                                                            })
                                                        }
                                                    }
                                                />
                                            )}
                                        </div>

                                        <div className="h2-600 price-option">
                                            <CurrencyDisplay price={el.price}/>
                                        </div>
                                    </div>
                                ),
                            )
                        }

                        {
                            !isPreregistration &&
                                (<React.Fragment>
                                    <div className="cartBox__content bordered">
                                        <div className="h4">Sous total</div>
                                        <div className="h2-600">
                                            <CurrencyDisplay formatZero={true} price={(amountTotal + secondAmountTotal + promoCodeDiscount)}/>
                                        </div>
                                    </div>

                                    <div className="cartBox__content column" ref={promoCodeInput}>
                                        <div className={"discount-div" + (isPreregistration ? " disabled" : "")}>
                                            <TextInput
                                                label="Code promo"
                                                value={promoCode}
                                                customRootCls="codeInput"
                                                onChange={setPromoCode}
                                                disabled={firstSubHasDiscountCode || isPreregistration}
                                                actions={[ {
                                                    onClick: handlePromoCodeSubmit,
                                                    render: firstSubHasDiscountCode ? (
                                                        <img src={mediaPath([ "/assets/icons/cross-orange.svg", "/assets/images/padel/icons/cross-close.svg" ])}/>
                                                    ) : (
                                                        <ButtonCta
                                                            text="OK"
                                                            noSkew
                                                            noShadow
                                                        />),
                                                } ]}
                                            />
                                        </div>

                                        {codeError &&
                                            (<div className="rowPromo">
                                                <div className="info">Code incorrect</div>
                                            </div>)
                                        }
                                        {promoCodeDiscount > 0 &&
                                            (<div className="rowPromo">
                                                <div className="info">Remise :</div>
                                                <div className="price">- <CurrencyDisplay price={promoCodeDiscount}/></div>
                                            </div>)
                                        }
                                    </div>
                                </React.Fragment>)
                        }

                        <AvoirCheckbox
                            totalAmount={amountTotal + secondAmountTotal}
                            tva={totalTvaAvoirComponent}
                            formName="avoirFormData"
                            isPreregistration={isPreregistration}
                        >
                        </AvoirCheckbox>

                        <div className="cartBox__info column biggerPadding noMargin">
                            <div className="rowPrice big">
                                <div className="info">Total</div>
                                <div className="price">
                                    <CurrencyDisplay formatZero={true} price={(total)}/>
                                </div>
                            </div>

                            <div className="rowPrice">
                                <div className="info">Dont TVA :</div>
                                <div className="price">
                                    <CurrencyDisplay formatZero={true} price={totalTva}/>
                                </div>
                            </div>
                        </div>

                        <div className="cartBox__cta">
                            {firstSubscription?.kid && (
                                <div className="c-inputHolder--checkbox checkbox-representant">
                                    <input type="checkbox" id="cartCGV" value=""
                                        disabled={isPreregistration}
                                        checked={representantLegalChecked} onChange={handleRepresentantLegal}/>
                                    <label>
                                        Je certifie être le représentant légal de <span className="kidName">{childName}</span>
                                    </label>
                                </div>
                            )}

                            <div className="c-inputHolder--checkbox">
                                <input type="checkbox" id="cartCGV" value=""
                                    disabled={isPreregistration}
                                    checked={paymentEnabled} onChange={handleCGV}/>
                                <label>
                                    J’accepte les <a style={{ cursor: "pointer" }} onClick={() => {
                                        window.open(cgvUrl, "_blank")
                                    }}>Conditions Générales de Vente</a>
                                </label>
                            </div>

                            {
                                Object.keys(cbCard).length > 0  &&
                                    (<div className="c-inputHolder--checkbox cb-div">
                                        <input type="checkbox" id="cb"
                                            onChange={useUserCB}/>
                                        <label>
                                            <span>J'utilise ma carte enregistrée</span> <span>({cbCard.tokenDetails.pan})</span>
                                        </label>
                                    </div>)
                            }
                            {showAcceptCGV && !paymentEnabled && (
                                <div className="acceptCGV">
                                    <img
                                        src={mediaPath([ "/assets/icons/notifications.svg", "/assets/images/padel/icons/icons-notifications.svg" ])}
                                        className="alert"
                                    />
                                    Tu n'as pas accepté les CGV
                                </div>
                            )}
                            {(firstSubscription?.kid && !representantLegalChecked) && showAcceptCGV && (
                                <div className="acceptCGV">
                                    <img
                                        src={mediaPath([ "/assets/icons/notifications.svg", "/assets/images/padel/icons/icons-notifications.svg" ])}
                                        className="alert"
                                    />
                                    Tu dois être le représentant légal de {firstSubscription?.kid?.firstName}
                                </div>
                            )}
                            <div className={classNames("orderSummaryButtonsHolder", {
                                even: buttonsCount() % 2 === 0,
                                odd: buttonsCount() % 2 !== 0,
                            })}>
                                {isPreregistration ?
                                    (<>
                                        <ButtonCta
                                            className={classNames("buttonCta--big", {
                                                disabled: !paymentEnabled || !representantLegalChecked,
                                            })}
                                            text={
                                                total !== 0 ?
                                                    "Payer"
                                                    :
                                                    "Finaliser"
                                            }
                                            onClick={() => !paymentEnabled || !representantLegalChecked ? setShowAcceptCGV(true) : proceedCheckout(false)}
                                        />
                                        <ButtonCta
                                            className={classNames("buttonCta--big", {
                                                disabled: !paymentEnabled || !representantLegalChecked,
                                            })}
                                            isCta2
                                            text="Annuler"
                                            onClick={!paymentEnabled || !representantLegalChecked ? () => setShowAcceptCGV(true) : cancelRegistration}
                                        />
                                    </>)
                                    : (
                                        <>
                                            <ButtonCta
                                                onClick={() => !paymentEnabled || !representantLegalChecked ? setShowAcceptCGV(true) : proceedCheckout(false)}
                                                text = {
                                                    total !== 0 ?
                                                        "Payer"
                                                        :
                                                        "Finaliser"
                                                }
                                                className={classNames("buttonCta--big fontSizeMax", {
                                                    disabled: !paymentEnabled || !representantLegalChecked,
                                                })}
                                            />
                                            { schoolDetails.preregistrationEnabled && (
                                                <ButtonCta
                                                    className={classNames("buttonCta--big", {
                                                        disabled: !paymentEnabled || !representantLegalChecked,
                                                    })}
                                                    isCta2
                                                    text={(
                                                        <>
                                                            Paiement différé
                                                            <br />
                                                            <small>à régler sous 5 jours</small>
                                                        </>
                                                    )}
                                                    onClick={!paymentEnabled || !representantLegalChecked ? () => setShowAcceptCGV(true) : preregister}
                                                />
                                            )}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Step>
    )
}

export const InsurancePopIn = ModalHandler.create(({ visible, hide, optionInfo }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className="insuranceInfoPopin"
        >
            {optionInfo && (
                <>
                    <header>
                        <span>{optionInfo.title}</span>
                    </header>
                    <section>
                        <div
                            dangerouslySetInnerHTML={{ __html: optionInfo.description }}
                            className="dangerousHtml"
                        >
                        </div>
                    </section>
                </>
            )}
        </Modal>
    )
})

OrderSummary.propTypes = {
    backToStep: number,
    clearAllForms: func,
    customCls: oneOfType([ string, bool ]),
    editUserSessionMeta: func,
    goToStep: func,
    isPreregistration: bool,
    setFormInStore: func,
    title: string,
}

export default OrderSummary
