import BirthdayReservation from "../pages/kids/birthdayReservation/BirthdayReservation.jsx"
import ListingBirthday from "../pages/kids/birthdayReservation/listingBithdays.jsx"

const generateRoutesBirthday = (theme = "") => {
    const basePath = theme ? `/${theme}` : ""
    return [
        {
            element: (<ListingBirthday/>),
            path: `${basePath}/kids/anniversaire`,
        },
        {
            element: (<ListingBirthday/>),
            path: `${basePath}/kids/anniversaire/listing`,
        },
        {
            children: [
                {
                    element: (<BirthdayReservation />),
                    path: ":id",
                },
                {
                    element: (<BirthdayReservation />),
                    path: ":initialCenterId/:initialTypeId/:daywp/:monthwp/:yearwp",
                },
            ],
            element: (<BirthdayReservation />),
            path: `${basePath}/kids/anniversaire/new`,
        },
    ]
}

export default generateRoutesBirthday
