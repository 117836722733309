const getType = resourceType => {
    const typesNames = [
        {
            id: [ 9 ],
            name: "Badminton",
        },
        {
            id: [ 3 ],
            name: "Foot à 2",
        },
        {
            id: [ 2 ],
            name: "Foot à 3",
        },
        {
            id: [ 4 ],
            name: "Foot à 4",
        },
        {
            id: [ 0, 1, 8, 10 ],
            name: "Foot à 5",
        },
        {
            id: [ 13 ],
            name: "Foot à 7",
        },
        {
            id: [ 12, 14, 15 ],
            name: "Padel",
        },
        {
            id: [ 5 ],
            name: "Tennis",
        },
        {
            id: [ 11 ],
            name: "Squash",
        },
        {
            id: [ 19,20,21 ],
            name: "Padel Single",
        },
        {
            id: [ 16,17,18 ],
            name: "Pickleball",
        },
        {
            id: [ 24 ],
            name: "Padbol",
        },
    ]
    return typesNames.find(type => type.id.includes(resourceType))?.name
}

export default getType
