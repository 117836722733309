/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */

import soccerLanguage from "../../hooks/urbanLanguage/languages/SoccerLanguage.js"
import { LeaguePath } from "../../routes/ApplicationPaths/LeaguePaths.js"
import disconnectUser from "../disconnectUser"

function initializeMenu({ hasArdoises = false, nextBooking = false, hasUnpaidBills = false, userLoaded = {} }) {
    const userHasPalmares = userLoaded?.championshipPlayers?.find(x => x.league?.status === "Terminé") || false
    const userHasOnGoingLeague = userLoaded?.championshipPlayers?.find(x => x.league?.status === "En" +
        " cours" && x?.isActive === true && x.league?.typeId !== import.meta.env.VITE_US_BUSINESS_LEAGUE_TYPEID) || false

    const tu = keys => keys.split(".").reduce((obj, index) => (obj && index ? obj[index] : null), soccerLanguage)

    const menuslevel1 = {
        home: {
            appRoute: "/",
            desktopSection: "logo-part",
            img: "/assets/images/logo-white.svg",
            label: "Accueil",
            slug: "accueil",
        },
        reservations: {
            appRoute: "/reservations/locations",
            customCls: "locationMenu",
            desktopSection: "main-part",
            hasNotif: nextBooking,
            label: "locations",
            mobileSection: "menu",
            slug: "reservations",
        },
        urbanleague: {
            desktopSection: "main-part",
            label: tu("league.name"),
            mobileSection: "menu",
            slug: "urbanleague",
        },
        urbanCup: {
            appRoute: "/cup",
            desktopSection: "main-part",
            label: "cup",
            mobileSection: "menu",
            slug: "cup",
        },
        kids: {
            desktopSection: "main-part",
            label: "kids",
            mobileSection: "menu",
            slug: "kids",
        },
        supersub: {
            desktopSection: "main-part",
            label: "Supersub",
            mobileSection: "menu",
            slug: "supersub",
        },
        contact: {
            customCls: "specific-mobile-menu-item",
            externalLink: "https://www.urbansoccer.fr/contact/",
            label: "Contact",
            mobileSection: "menu",
            slug: "contact",
        },
        cookies: {
            customCls: "specific-mobile-menu-item",
            externalLink: "javascript:Didomi.preferences.show()",
            label: "Cookies",
            mobileSection: "menu",
            slug: "cookies",
        },

        informationsLegales: {
            customCls: "specific-mobile-menu-item",
            label: "Informations légales",
            mobileSection: "menu",
            slug: "informationsLegales",
        },
        profil: {
            desktopSection: "other-part",
            icon: "userProfile.svg",
            label: "Mon profil",
            mobileSection: "main",
            slug: "profil",
        },
        reserver: {
            appRoute: "/reserver",
            customCls: "reserver-menu-item",
            desktopSection: "other-part",
            icon: "/assets/icons/calendar.svg",
            label: "Réserver un terrain",
            mobileSection: "main",
            slug: "reserver",
        },
    }

    const menuslevel2 = {
        informationsLegales: [
            {
                externalLink: "https://www.urbansoccer.fr/mentions-legales/",
                label: "Mentions légales",
            },
            {
                externalLink: "https://www.urbansoccer.fr/politique-de-confidentialite/",
                label: "Politique de données personnelles",
            },
        ],
        kids: [
            { appRoute: "/kids/anniversaire", label: "Anniversaire" },
            { appRoute: "/kids/stagesDeFoot", label: "Stages de Foot" },
            { appRoute: "/kids/ecoleDeFoot", label: "École de Foot" },
        ],
        profil: [
            {
                appRoute: "/profil/coordonnees",
                icon: "/assets/icons/icons-graphics-coordonn-es.svg",
                isVisible: true,
                label: "Profil",
            },
            {
                appRoute: "/profil/coequipiers",
                icon: "/assets/icons/icons-graphics-coequipiers.svg",
                isVisible: true,
                label: "Joueurs",
            },
            {
                appRoute: "/profil/abonnements",
                icon: "/assets/icons/icons-graphics-abonnement.svg",
                isVisible: true,
                label: "Abonnements",
            },
            {
                appRoute: "/profil/factures",
                hasNotification: hasUnpaidBills,
                icon: "/assets/icons/icons-graphics-facture.svg",
                isVisible: true,
                label: "Factures",
            },
            {
                appRoute: "/profil/ardoises",
                hasNotification: hasArdoises,
                icon: "/assets/icons/icons-graphics-ardoise.svg",
                isVisible: hasArdoises,
                label: "Ardoises",
            },
            {
                appRoute: "/profil/creneauxFavoris",
                icon: "/assets/icons/icons-graphics-alertes-terrain.svg",
                isVisible: true,
                label: "Créneaux Favoris",
            },
            {
                appRoute: "/profil/moyensDePaiement",
                icon: "/assets/icons/icons-graphics-moyen-paiment.svg",
                isVisible: true,
                label: "Moyens de paiement",
            },
            {
                action: () => {
                    localStorage.setItem("isUserDisconnect", true)
                    disconnectUser()
                },
                customCls: "logoutCheckblock",
                icon: "/assets/icons/icons-graphics-deconnexion.svg",
                isVisible: true,
                label: "Déconnexion",
                parentClass: "disconnectButton",
            },
        ],
        supersub: [
            {
                appRoute: "/supersub/findMatch",
                isMenuClick: true,
                label: "Trouver un match",
                restrictAccessToSuperSubBanned: true,
            },
            {
                appRoute: "/supersub/mySupersubsRequests",
                label: "Mes demandes",
                restrictAccessToSuperSubBanned: true,
            },
            {
                appRoute: "/supersub/whatIsSuperSub",
                label: "Qu’est-ce qu’un SuperSub ?",
            },
        ],
        urbanleague: [
            {
                appRoute: LeaguePath.leagueInfosBase,
                label: "Ma league",
                isVisible: userHasOnGoingLeague,
            },
            {
                appRoute: LeaguePath.listingLeague,
                label: "Mes inscriptions",
            },
            {
                appRoute: LeaguePath.leaguePalmares,
                label: "Palmarès",
                isVisible: !!userHasPalmares,
            },
            {
                appRoute:LeaguePath.leagueRanking,
                label: "Autres leagues",
            },
        ],
    }

    return {
        menuslevel1,
        menuslevel2,
    }
}

export default initializeMenu
