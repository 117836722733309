import { bool, func, number, object, string } from "prop-types"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import usePayzenPaymentRequest from "./usePayzenPaymentRequest"
import { ErrorModal } from "../../components/Modal/ErrorModal/ErrorModal.jsx"
import { offerSubersubPlace } from "../../components/SuperSub/SelectSuperSub/api"
import { useTheme } from "../../components/ThemeContext/ThemeContext"
import { generateLocationPublicInvitDetails } from "../../globalAPI/api"
import { createSupersubMatchResponse } from "../../globalAPI/supersub/api"
import { useLocationStore } from "../../pages/reservation/store/store.js"
import { generateSubPaymentForm } from "../../pages/supersub/findAMatch/api"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import { SupersubPaths } from "../../routes/ApplicationPaths/supersubPaths.js"
import usePath from "../../routes/services/usePath"
import haveCompo from "../../services/haveCompo"
import { editUserSessionMeta } from "../../store/actions/content"
import { clearAllForms } from "../../store/forms/actions"
import { goToStep } from "../../store/stepsManagement/actions"
import useThemeFeatures from "../useThemeFeatures"
import useThemeHeader from "../useThemeHeader"

function useProceedCheckout(
    {
        lyraPaymentUrl,
        tunnelType,
        setPayFreeAmountPopInOpen,
        setAjaxLoading,
        firstSubscription,
        secondSubscription,
        isPreregistration,
        isUsingUserCB,
        redirectToListing,
        customData,
        responseId,
        askRegisterPay,
        fromListing,
    },
) {
    const path = usePath()
    const { setCurrentLocation } = useLocationStore()
    const theme = useTheme()
    const themeHeader = useThemeHeader()
    const themeFeatures = useThemeFeatures()
    const userLoaded = useSelector(state => state.userLoaded)

    const [ withMultiPayments, setWithMultiPayments ] = useState(undefined)
    const [ searchParams ] = useSearchParams()
    const location = useLocation()

    const generatePayzenPaymentRequest = usePayzenPaymentRequest(tunnelType, askRegisterPay)
    const dispatch = useDispatch()

    const avoirFormData = useSelector(state => state.forms.avoirFormData)

    const supersubForm = useSelector(state => state.forms["supersubForm"])

    const navigate = useNavigate()

    function sendPayzenForm(data, returnUrl) {
        if (data) {
            if (data.ipn) {
                returnUrl += "&ipn=" + encodeURIComponent("/api/" + data.ipn.split("/US-API/myurban/")[1])
            }

            navigate(lyraPaymentUrl, { replace: true, state: { lyraEmbedParams: { ...data, returnUrl }, prevLocation:location } })
        }
    }

    function displayConfirmCmd(title, subtitle, message, customLink, customContentIdentifier) {
        navigate(path("/confirmcommand"), {
            state: {
                confirmedSubscription: firstSubscription,
                customContentIdentifier: customContentIdentifier,
                customLink: customLink,
                message: message ? message : "Un email de confirmation t'a été envoyé.",
                subtitle: subtitle,
                success: true,
                title: title,
                tunnelType: tunnelType,
            },
        })
    }

    function proceedCheckout(withMultiPayments, freeAmount, notPayzen, askRegisterPay = false, saveCard = false, gymlibCode = false) {
        if (!freeAmount) {
            freeAmount = 0
        }
        if (tunnelType === "SUPERSUB") {
            const isSuperSub = userLoaded && (userLoaded.contactId !== firstSubscription.organizer.id)
            setAjaxLoading(true)
            const returnUrl = import.meta.env.VITE_URL_BASE + "/?action=payzenPayment&type=" + tunnelType.toUpperCase() + "&theme=" + theme
            if (firstSubscription.isFree || firstSubscription.reservation.amountDue === 0) {
                /*  /read/supersubs/{id}/offer-place */
                if (userLoaded && (userLoaded.contactId === firstSubscription.organizer.id)) { // is Organizer
                    if (supersubForm || responseId) {
                        offerSubersubPlace(
                            supersubForm ?
                                supersubForm.response.id
                                :
                                responseId,
                            returnUrl,
                            isUsingUserCB,
                        ).then(
                            (res) => {
                                setAjaxLoading(false)
                                sendPayzenForm(res, returnUrl)
                            },
                        )
                    }
                } else if (isSuperSub) {
                    const desc = customData?.supersubApplyMessage ? customData.supersubApplyMessage : ""
                    createSupersubMatchResponse(firstSubscription.id, {
                        description: desc,
                    }, themeHeader).then(
                        () => {
                            setAjaxLoading(false)
                            const subTitleTraining = "Ta demande pour rejoindre le match a bien été envoyée."
                            const msg = (<React.Fragment>
                                Surveille tes e-mails, l’organisateur va maintenant devoir valider
                                ta demande. <br/>
                                Si tu n’es plus disponible, tu peux annuler ta demande
                                tant que
                                l’organisateur ne l’a pas validée. <br/>
                                Si tu as effectué un paiement, tu ne seras pas débité en cas
                                d’annulation.
                                <br/>
                                <br/>
                                Bon Match !
                            </React.Fragment>)

                            displayConfirmCmd("Tu y es presque&nbsp;!", subTitleTraining, msg, "/supersub/findMatch?isMenuClick=true", "findMatch")
                        },
                    )
                }
            } else {
                if (isSuperSub) {
                    generateSubPaymentForm({
                        requestId: firstSubscription.id,
                        returnUrl: returnUrl,
                        saveCard: saveCard,
                        supersubApplyMessage: customData?.supersubApplyMessage ? customData.supersubApplyMessage : undefined,
                        withAlias: isUsingUserCB ? true : null,
                    }).then(
                        (res) => {
                            setAjaxLoading(false)
                            sendPayzenForm(res, returnUrl)
                        },
                    ).catch(r => {
                        const resp = r.response?.data
                        if (resp?.code === "SUPERSUB_REQUEST_FULL") {
                            ModalHandler.show(ErrorModal, {
                                message: resp?.error,
                                redirectionText: "Trouver un autre match",
                                redirectionUrl: SupersubPaths.findAMatch,
                                title: "Une erreur est survenue",
                            })
                        }
                    })
                }
            }
        } else {
            setWithMultiPayments(withMultiPayments)
            const proformaAmount = tunnelType !== "BOOKING" && firstSubscription?.proforma ? firstSubscription.proforma.amountTTC : null
            let total = tunnelType === "DEBT" || tunnelType === "BILL" ?
                customData.amount
                :
                proformaAmount

            if (secondSubscription && tunnelType !== "BOOKING") {
                total = total + secondSubscription.proforma.amountTTC
            }

            const isUsingAvoirSupTotal = avoirFormData?.checkAvoir &&
                avoirFormData.avoirAmount >= total

            if ([ "LEAGUE" ].includes(tunnelType) && freeAmount === 0 && !isUsingAvoirSupTotal && !notPayzen && !gymlibCode && !withMultiPayments) {
                setPayFreeAmountPopInOpen(true)
            } else {
                let returnUrl

                if (redirectToListing) {
                    returnUrl = import.meta.env.VITE_URL_BASE + "/?action=payzenPayment&isRedirectToListing=true&type=" + tunnelType.toLowerCase() + "&theme=" + theme + (fromListing ? "&fromListing=true" : "")
                } else {
                    returnUrl = import.meta.env.VITE_URL_BASE + "/?action=payzenPayment&type=" + tunnelType.toLowerCase() + "&theme=" + theme + (fromListing ? "&fromListing=true" : "")
                    if (firstSubscription) {

                        returnUrl += "&firstSub=" + firstSubscription.id
                    }
                    if (secondSubscription) {

                        returnUrl += "&secondSub=" + secondSubscription.id
                    }
                    if (isPreregistration) {

                        returnUrl += "&preregistration=true"
                    }
                }

                let data = {
                    askRegisterPay: askRegisterPay,
                    returnUrl: returnUrl,
                }

                if (withMultiPayments) {
                    data.withMultiPayments = withMultiPayments
                }

                if (saveCard) {
                    data.saveCard = saveCard
                }

                if (freeAmount !== null) {
                    data.freeAmount = freeAmount
                }
                if (firstSubscription) {
                    if (tunnelType === "TRAINING" || tunnelType === "LEAGUE") {
                        data.subscriptionId = firstSubscription.id
                    } else {
                        data.registrationId = firstSubscription.id
                    }
                }

                if (tunnelType === "LEAGUE") {
                    if (firstSubscription?.playerBillShareToken?.token) {
                        data.shareToken = firstSubscription.playerBillShareToken.token
                    }
                }

                if (isUsingUserCB) {
                    data.withAlias = true
                }

                if (secondSubscription) {
                    data.secondSessionId = secondSubscription.id
                }

                if (avoirFormData?.checkAvoir) {
                    data.allocateCredits = true
                }

                if (tunnelType === "BOOKING") {
                    const params = {
                        activity: searchParams.get("activity"),
                        centerId: searchParams.get("centerId"),
                        date: searchParams.get("date"),
                        discountId: searchParams.get("discountId"),
                        discountLabel: searchParams.get("discountLabel"),
                        duration: searchParams.get("duration"),
                        hour: searchParams.get("hour"),
                        mn: searchParams.get("mn"),
                        part: searchParams.get("part"),
                        players: searchParams.get("players"),
                        pricePerPart: searchParams.get("pricePerPart"),
                        resourceType: searchParams.get("resourceType"),
                        typeName: searchParams.get("typeName"),
                    }                    

                    let bookingReturnUrl = import.meta.env.VITE_URL_BASE + "/?action=payzenPayment&type=BOOKING" + "&theme=" + theme
                    if (firstSubscription?.resourceType || firstSubscription?.resourceType === 0) {
                        bookingReturnUrl += "&bookingResourceType=" + firstSubscription.resourceType + "&activity=" + params.activity
                    }
                    if (firstSubscription?.start) {
                        bookingReturnUrl += "&bookingDate=" + firstSubscription.start.split("T")[0] + "&hour=" + params.hour + "&mn=" + params.mn
                    }
                    if (firstSubscription?.centerId) {
                        bookingReturnUrl += "&bookingCenterId=" + firstSubscription.centerId
                    }
                    if (params?.players && params?.part && params?.pricePerPart) {
                        bookingReturnUrl += "&players=" + params.players + "&part=" + params.part + "&pricePerPart=" + params.pricePerPart
                    }
                    if (firstSubscription?.discount) {bookingReturnUrl += "&discountId=" + params.discountId + "&discountLabel=" + encodeURI(params.discountLabel)}
                    if (firstSubscription?.duration) {bookingReturnUrl += "&duration=" + params.duration}
                    if (customData.haveDiscountFromPreviousStep) {bookingReturnUrl += "&haveDiscountFromPreviousStep=" + customData.haveDiscountFromPreviousStep}

                    if (gymlibCode) {
                        data.gymlibCode = gymlibCode
                        delete data.allocateCredits
                    }

                    data.vads_return_url = bookingReturnUrl + (customData?.fromGestion ? "&fromGestion=" + customData.fromGestion : "")
                    data.vads_order_info = "Paiement réservation - via payer -"
                    data.serviceType = "BOOKING" //this.state.paymentType.toUpperCase(),
                    data.startDate = customData.start
                    data.centerId = customData.centerId
                    data.pricePerPart = params.pricePerPart
                    data.part = params.part
                    data.players = params.players

                    if (customData.fromGestion) {
                        data.reservationId = customData.id
                        data.pricePerPart = customData.pricePerPart
                        data.part = Math.round(freeAmount / customData.pricePerPart)
                        data.players = customData.players
                    } else {
                        data.preReservationId = customData.id
                    }

                    if (customData?.fromGestion) {
                        returnUrl = returnUrl + "&fromGestion=true"
                    }

                    if (customData.haveDiscountFromPreviousStep) {
                        data.haveDiscountFromPreviousStep = customData.haveDiscountFromPreviousStep
                    }
                    data.amount = freeAmount
                }
                if (setAjaxLoading) {
                    setAjaxLoading(true)
                }

                let handlePayzenRes = (res) => {
                    if ((avoirFormData?.checkAvoir && total !== null &&
                        avoirFormData.avoirAmount >= total) || notPayzen) {
                        switch (tunnelType) {
                        case "BIRTHDAY":
                            // not used now
                            // displayConfirmCmd()
                            break
                        case "SCHOOL":
                            // not used now
                            // displayConfirmCmd()
                            break
                        case "CUP":
                            displayConfirmCmd("Inscription réussie&nbsp;!", "cupConfirm")
                            break
                        case "TRAINING":
                            displayConfirmCmd("Inscription réussie&nbsp;!", "trainingConfirm")
                            break
                        case "LEAGUE":
                            displayConfirmCmd("Inscription réussie&nbsp;!", "leagueConfirm")
                            break
                        case "BOOKING":
                            // handle the case where avoir is equal or supérior to total, if so need to create a card payment at 0€
                            break
                        default:
                            break
                        }

                        dispatch(
                            clearAllForms(),
                        )

                        dispatch(
                            goToStep(0),
                        )
                        if (setAjaxLoading) {
                            setAjaxLoading(false)
                        }
                    } else {
                        let data = tunnelType === "BOOKING" || tunnelType === "DEBT" || tunnelType === "BILL" ?
                            res :
                            res.data.data
                        dispatch(
                            editUserSessionMeta("beforePayzenForm", true),
                        )
                        if (setAjaxLoading) {
                            setAjaxLoading(false)
                        }

                        sendPayzenForm({ ...data, onlyAlma: withMultiPayments }, returnUrl)
                    }
                }

                if (tunnelType === "DEBT" || tunnelType === "BILL") {
                    generatePayzenPaymentRequest(customData, themeHeader).then(handlePayzenRes)
                } else {
                    generatePayzenPaymentRequest(data, themeHeader).then((res) => {
                        if (res.skipLyra === true) {
                            generateLocationPublicInvitDetails(res.bookingData?.id).then(() => {
                                setCurrentLocation({})
                                const regex = /bookingResourceType=([^&]+)/
                                const match = regex.exec(res.vads_url_return)?.[1]
                                const compo = haveCompo(match ? parseInt(match) : undefined)
                                navigate(
                                    themeFeatures([ "/confirmcommand", "/padel/confirmcommand" ]), {
                                        state: data.reservationId ? {
                                            fromGestion: true,
                                            locationId: data.reservationId,
                                            message: "Bon match.",
                                            subtitle: "Ton paiement a bien été enregistré.",
                                            success: true,
                                            title: "Paiement effectué !",
                                            tunnelType: "BOOKING",
                                        } : {
                                            bookingId: res.bookingData?.id,
                                            customContentIdentifier: "locations",
                                            customLink: "/reservations/locations",
                                            message: compo ? "bookingConfirmCompo" : "bookingConfirm",
                                            success: true,
                                            title: "Réservation réussie !",
                                            tunnelType: "BOOKING",
                                        },
                                    },
                                )
                            })
                        } else {
                            handlePayzenRes(res)
                        }
                    })
                }
            }
        }
    }

    return {
        payzenForm: null,
        proceedCheckout: proceedCheckout,
        withMultiPayments: withMultiPayments,
    }
}

useProceedCheckout.propTypes = {
    askRegisterPay: bool,
    customData: object,
    firstSubscription: object,
    fromListing: true,
    isPreregistration: bool,
    isUsingUserCB: bool,
    redirectToListing: bool,
    responseId: number,
    secondSubscription: object,
    setAjaxLoading: func,
    setPayFreeAmountPopInOpen: func,
    tunnelType: string,
}

export default useProceedCheckout
