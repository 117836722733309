import { bool, func, object } from "prop-types"

import useMediaPath from "../../../../../hooks/useMediaPath.js"
import CurrencyDisplay from "../../../../currencies/CurrencyDisplay.jsx"

import "./ShopCartEquipmentLine.scss"

export const ShopCartEquipmentLine = ({ blackEquipmentCross, disableDeletion, productOrder, removeProduct }) => {
    const { product = {} } = productOrder
    const mediaPath = useMediaPath()

    const formattedSizes = product.sizes?.reduce((acc, { Key, Value }) => {
        acc[Key] = Value
        return acc
    }, {}) || {}

    return (
        <li className="productInfo-row">
            <div className="productInfo-thumb">
                <img src={product?.pictureUrl} alt={product?.name}/>
            </div>

            <div className="productInfo-content">
                <div>
                    <div className="productInfo-name">{product?.name}</div>
                    <div className="productInfo-desc">
                        Taille {
                            productOrder?.size && (formattedSizes[productOrder.size] ?
                                formattedSizes[productOrder.size] + " - " : productOrder.size + " - ")
                        }
                        Qté {productOrder?.quantity}
                    </div>
                </div>

                <div className="productInfo-price">
                    <CurrencyDisplay price={product?.price}/>
                </div>
            </div>

            { !disableDeletion &&
                (<div className="productInfo-action">
                    <button onClick={() => removeProduct(productOrder)} data-testid="remove">
                        <img src={mediaPath([ "/assets/icons/cross-orange.svg", `/assets/images/padel/icons/icon-cross-${blackEquipmentCross ? "black" : "red"}.svg` ])} alt=""/>
                    </button>
                </div>)
            }
        </li>
    )
}

ShopCartEquipmentLine.propTypes = {
    blackEquipmentCross: bool,
    disableDeletion: bool,
    productOrder: object,
    removeProduct: func,
}
